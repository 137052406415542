import React, { useEffect, useState, useContext } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Grid,
  Paper,
  TextField,
  Button,
  Box,
  Snackbar,
  LinearProgress,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Link } from "react-router-dom";
import { imageValidation, noImageSrc } from "../core/validationFunctions";
import { Alert } from "@mui/material";
import { ApiPaths, AppRoutes } from "../core/PathConstants";
import AlertMessage from "../core/AlertMessage";
import { fetchApiData, postApiData } from "../core/FetchApiData";
import { PictureBox } from "../core/PictureBox";
import { TextFieldValidation } from "../core/TextFieldValidation";
import { Loading } from "../core/Loading";
import { CurrentUserContext } from "../core/hooks/CurrentUserContext";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  cardroot: {
    minHeight: 200,
  },
  paper: {
    padding: theme.spacing(4),
    //textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  media: {
    height: 140,
  },
}));

export default function Profile({ onUpdateUserData }) {
  const { user } = useContext(CurrentUserContext);

  const classes = useStyles();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [alertState, setAlertState] = useState({ show: false });
  const [isValidForm, setIsValidForm] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  // const [isValidPic, setIsValidPic] = useState(true);

  const handleChange = (e) => {
    const name = e.target.name;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    // Set values
    setData({
      ...data,
      [name]: value,
    });
  };

  const validateForm = () => {
    for (let item in isValidForm) {
      if (!isValidForm[item]) return false;
    }

    return true;
  };

  const onSaveError = (status, msg) => {
    setAlertState({ show: true, message: msg }); //'Error saving user profile'
  };

  const onComplete = () => {
    setIsSaving(false);
    setAlertState({
      show: true,
      message: "User Profile successfully saved",
      type: "success",
    });
    //change user context
    if (
      (data?.firstName && data?.firstName !== user.firstName) ||
      (data?.lastName && data?.lastName !== user.lastName)
    )
      onUpdateUserData();

    //(data.firstName, data.lastName, data.picture !== noImageSrc ? data.picture: null);
  };

  const imageFormatTooltipText =
    "Upload an image .jpg, .gif, .png format. Recommended size is 300px x 300px";

  const onSave = () => {
    if (validateForm()) {
      setIsSaving(true);

      postApiData(
        ApiPaths.UserProfile,
        "PUT",
        data,
        onComplete,
        onSaveError,
        user.token
      );
    } else
      setAlertState({
        show: true,
        message: "Please correct the field values before saving",
      });
  };

  const onError = (status, msg) => {
    setAlertState({ show: true, message: "Error retrieving data" });
  };

  useEffect(() => {
    fetchApiData(
      ApiPaths.UserProfile,
      setData,
      setIsLoading,
      onError,
      user.token
    );
  }, []);

  return (
    <div className={classes.root}>
      <Loading isLoading={isSaving} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">
            <strong>My Profile</strong>
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 6 }}>
        <Paper variant="outlined" className={classes.paper}>
          {isLoading ? (
            <LinearProgress color="secondary" />
          ) : (
            <Grid container spacing={1} style={{ marginLeft: "10px" }}>
              <Grid container direction="column" item lg={8} xs={12}>
                <TextField
                  style={{ margin: "10px 10px 10px 5px" }}
                  variant="outlined"
                  fullWidth
                  size="small"
                  label="First Name"
                  name="firstName"
                  onChange={handleChange}
                  placeholder="First Name"
                  value={data?.firstName || ""}
                />

                <TextField
                  style={{ margin: "20px 10px 10px 5px" }}
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Last Name"
                  onChange={handleChange}
                  name="lastName"
                  placeholder="Last Name"
                  value={data?.lastName || ""}
                />

                <TextFieldValidation
                  style={{ margin: "20px 10px 10px 5px" }}
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Phone"
                  onChange={handleChange}
                  name="phoneNumber"
                  type="phone"
                  placeholder=""
                  setFormValues={setData}
                  formValues={data}
                  isValidForm={isValidForm}
                  setIsValidForm={setIsValidForm}
                  validate="phone"
                />
              </Grid>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                direction="column"
                item
                style={{ marginLeft: "30px", padding: "15px" }}
                xs={12}
                lg={3}
              >
                <PictureBox
                  boxHeight={200}
                  boxWidth={200}
                  setData={setData}
                  data={data}
                  deleteOption={true}
                />
                <Grid item container>
                  <Typography
                    align="center"
                    variant={"caption"}
                    style={{ marginTop: "15px" }}
                  >
                    {imageFormatTooltipText}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} container alignItems="flex-start">
                <Button
                  style={{ marginTop: 10 }}
                  variant="contained"
                  color="primary"
                  onClick={onSave}
                >
                  Save
                </Button>
                <Button
                  style={{ marginTop: 10, marginLeft: 10 }}
                  variant="contained"
                  component={Link}
                  to={AppRoutes.UserChangePassword}
                  color="primary"
                >
                  Change Password
                </Button>
              </Grid>
            </Grid>
          )}
        </Paper>
      </Grid>
      <AlertMessage alert={alertState} />
    </div>
  );
}
