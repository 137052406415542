import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Button,
  Grid,
  TextField,
  Typography,
  Paper,
  Tooltip,
  TextareaAutosize,
} from "@mui/material";
import { fetchApiData, postApiData } from "../../core/FetchApiData";
import { formValidation } from "../../core/validationFunctions";
import { Alert } from "@mui/material";
import SunEditor, { buttonList } from "suneditor-react";

import "suneditor/dist/css/suneditor.min.css";
import makeStyles from "@mui/styles/makeStyles";
import { ApiPaths, AppRoutes } from "../../core/PathConstants";
import AlertMessage from "../../core/AlertMessage";
import { Link } from "react-router-dom";
import { PictureBox } from "../../core/PictureBox";
import { Row } from "reactstrap";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Loading } from "../../core/Loading";
import { CurrentUserContext } from "../../core/hooks/CurrentUserContext";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  cardroot: {
    minHeight: 200,
  },
  paper: {
    padding: theme.spacing(2),
    //textAlign: 'start',
    color: theme.palette.text.secondary,
    marginLeft: "2rem",
  },
  media: {
    height: 140,
  },
  saveButton: {
    textAlign: "end",
  },
  texfieldsDiv: {
    marginBottom: "1rem",
    marginTop: "1rem",
  },
  texfield: {
    width: "90%",
  },
}));

var initialValues = {
  mlOriginatorId: "",
  canMloChangeMobilePhone: false,
  canMloChangeOfficePhone: false,
  canMloChangeTitle: false,
  canMloChangePersonalWebsite: false,
  firstName: "",
  lastName: "",
  businessEmail: "",
  jobTitle: "",
  nmls: "",
  mobilePhone: "",
  officePhone: "",
  profile: "",
  aboutMe: "",
  companyName: "",
  letterSignature: "",
  picture: "",
  companyLogo: "",
};

const fieldsValidation = {
  firstName: {
    error: "",
    validate: "text",
    minLength: 2,
    maxLength: 150,
  },
  lastName: {
    error: "",
    validate: "text",
    minLength: 2,
    maxLength: 150,
  },
  businessEmail: {
    error: "",
    validate: "",
    minLength: 0,
    maxLength: 150,
  },
  jobTitle: {
    error: "",
    validate: "",
    minLength: 0,
    maxLength: 150,
  },
  nmls: {
    error: "",
    validate: "number",
    minLength: 0,
    maxLength: 15,
  },
  mobilePhone: {
    error: "",
    validate: "phone",
    minLength: 0,
    maxLength: 20,
  },
  officePhone: {
    error: "",
    validate: "phone",
    minLength: 0,
    maxLength: 20,
  },
};

const MLODetails = ({ onUpdateUserData }) => {
  const { user } = useContext(CurrentUserContext);

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [errorLoading, setErrorLoading] = useState(false);
  const classes = useStyles();
  const [alertState, setAlertState] = useState({ show: false });
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    //Get MLONotifications data
    fetchApiData(
      ApiPaths.MLOriginatorDetails,
      setFormValues,
      setIsLoading,
      onErrorLoading,
      user.token
    );
  }, []);

  const onErrorSaving = (status) => {
    setAlertState({
      show: true,
      message: "Error retrieving saving Loan Originator details data",
    });
  };

  const onErrorLoading = (status) => {
    setAlertState({
      show: true,
      message: "Error retrieving retrieving Loan Originator details data",
    });
  };

  const onSaved = () => {
    setIsSaving(false);
    setAlertState({
      show: true,
      message: "Changes successfully saved!",
      type: "success",
    });
    onUpdateUserData();
  };

  async function handleSubmit() {
    setIsSaving(true);
    postApiData(
      ApiPaths.MLOriginatorDetails,
      "PUT",
      formValues,
      onSaved,
      onErrorSaving,
      user.token
    );
  }

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    // Set values
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name in fieldsValidation) {
      // Set errors
      const error = formValidation(name, value, fieldsValidation) || "";

      setFormErrors((prev) => ({
        ...prev,
        [name]: error,
      }));
    }
  };

  const imageFormatTooltipText =
    "Upload an image .jpg, .gif, .png format. Recommended size is 300px x 300px";

  const editor = useRef();

  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
  };

  const handleOnEditorChange = (content) => {
    setFormValues({ ...formValues, letterSignature: content });
  };

  const renderMLODetailsSetup = () => {
    return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5">My Profile</Typography>
          </Grid>

          <Paper
            variant="outlined"
            square
            className={classes.paper}
            style={{ marginTop: "20px" }}
          >
            <Grid
              container
              spacing={3}
              item
              xs={12}
              style={{ padding: "0 0 0 30px" }}
            >
              <Grid container item md={12} lg={12} direction={"row"}>
                <Grid
                  className={classes.texfieldsDiv}
                  item
                  xs={12}
                  md={12}
                  lg={6}
                >
                  <TextField
                    className={classes.texfield}
                    variant="outlined"
                    size="small"
                    label="First Name"
                    placeholder="FirstName"
                    name="firstName"
                    value={formValues.firstName || ""}
                    onChange={handleChange}
                    error={!!formErrors.firstName}
                    helperText={formErrors.firstName}
                  />
                </Grid>
                <Grid
                  className={classes.texfieldsDiv}
                  item
                  xs={12}
                  md={12}
                  lg={6}
                >
                  <TextField
                    className={classes.texfield}
                    variant="outlined"
                    size="small"
                    label="Last Name"
                    name="lastName"
                    placeholder="Last Name"
                    value={formValues.lastName || ""}
                    error={!!formErrors.lastName}
                    helperText={formErrors.lastName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid
                  className={classes.texfieldsDiv}
                  item
                  xs={12}
                  md={12}
                  lg={6}
                >
                  <TextField
                    className={classes.texfield}
                    variant="outlined"
                    size="small"
                    label="Business Email"
                    name="businessEmail"
                    placeholder="Business Email"
                    value={formValues.businessEmail || ""}
                    onChange={handleChange}
                    error={!!formErrors.businessEmail}
                    helperText={formErrors.businessEmail}
                  />
                </Grid>
                <Grid
                  className={classes.texfieldsDiv}
                  item
                  xs={12}
                  md={12}
                  lg={6}
                >
                  <TextField
                    className={classes.texfield}
                    variant="outlined"
                    size="small"
                    label="Company Name"
                    name="copanyName"
                    placeholder="Company Name"
                    value={formValues.companyName || ""}
                    onChange={handleChange}
                    disabled
                  />
                </Grid>
                <Grid
                  className={classes.texfieldsDiv}
                  item
                  xs={12}
                  md={12}
                  lg={6}
                >
                  <TextField
                    className={classes.texfield}
                    variant="outlined"
                    size="small"
                    label="Job Title"
                    name="jobTitle"
                    placeholder="Job Title"
                    value={formValues.jobTitle || ""}
                    onChange={handleChange}
                    disabled={!formValues.canMloChangeTitle}
                    error={!!formErrors.jobTitle}
                    helperText={formErrors.jobTitle}
                  />
                </Grid>
                <Grid
                  className={classes.texfieldsDiv}
                  item
                  xs={12}
                  md={12}
                  lg={6}
                >
                  <TextField
                    className={classes.texfield}
                    variant="outlined"
                    size="small"
                    label="NMLS #"
                    name="nmls"
                    placeholder="NMLS #"
                    value={formValues.nmls || ""}
                    onChange={handleChange}
                    error={!!formErrors.nmls}
                    helperText={formErrors.nmls}
                  />
                </Grid>
                <Grid
                  className={classes.texfieldsDiv}
                  item
                  xs={12}
                  md={12}
                  lg={6}
                >
                  <TextField
                    className={classes.texfield}
                    variant="outlined"
                    size="small"
                    label="Mobile Phone"
                    name="mobilePhone"
                    placeholder="Mobile Phone"
                    value={formValues.mobilePhone || ""}
                    onChange={handleChange}
                    disabled={!formValues.canMloChangeMobilePhone}
                    error={!!formErrors.mobilePhone}
                    helperText={formErrors.mobilePhone}
                  />
                </Grid>
                <Grid
                  className={classes.texfieldsDiv}
                  item
                  xs={12}
                  md={12}
                  lg={6}
                >
                  <TextField
                    className={classes.texfield}
                    variant="outlined"
                    size="small"
                    label="Office Phone"
                    name="officePhone"
                    placeholder="OfficePhone"
                    value={formValues.officePhone || ""}
                    onChange={handleChange}
                    disabled={!formValues.canMloChangeOfficePhone}
                    error={!!formErrors.officePhone}
                    helperText={formErrors.officePhone}
                  />
                </Grid>
              </Grid>

              <Paper
                variant="outlined"
                square
                className={classes.paper}
                style={{ marginTop: "20px", marginLeft: "20px" }}
              >
                <Grid
                  container
                  spacing={3}
                  item
                  xs={12}
                  style={{ padding: "0" }}
                  alignContent="center"
                >
                  <Grid
                    container
                    alignItems="center"
                    direction="column"
                    item
                    xs={12}
                    lg={6}
                  >
                    <Grid
                      justifyContent={"center"}
                      container
                      item
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <Typography>Profile</Typography>
                      <Tooltip
                        style={{ marginLeft: "10px" }}
                        title="Profile Picture"
                        color="primary"
                      >
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Grid>
                    <PictureBox
                      boxHeight={200}
                      boxWidth={200}
                      setData={setFormValues}
                      data={formValues}
                      deleteOption={true}
                      fieldName={"picture"}
                      originalSize={true}
                    />
                    <span style={{ textAlign: "center", padding: "4% 20%" }}>
                      <Typography
                        align="center"
                        variant={"caption"}
                        style={{ marginTop: "15px" }}
                      >
                        {imageFormatTooltipText}
                      </Typography>
                    </span>
                  </Grid>

                  <Grid
                    container
                    alignItems="center"
                    direction="column"
                    item
                    xs={12}
                    md={6}
                  >
                    <Grid
                      justifyContent={"center"}
                      container
                      item
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <Typography>Logo</Typography>
                      <Tooltip
                        style={{ marginLeft: "10px" }}
                        title="Logo - Company Logo to use in emails and letters, if none
                    selected default company logo will be used."
                        color="primary"
                      >
                        <HelpOutlineIcon />
                      </Tooltip>
                    </Grid>
                    <Grid item container direction={"column"}>
                      <PictureBox
                        fieldName={"companyLogo"}
                        boxHeight={200}
                        boxWidth={200}
                        deleteOption={true}
                        setData={setFormValues}
                        data={formValues}
                        originalSize={true}
                      />
                      <span style={{ textAlign: "center", padding: "4% 20%" }}>
                        <Typography variant={"caption"}>
                          {imageFormatTooltipText}
                        </Typography>
                      </span>
                    </Grid>
                    {/*<Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={6000} >*/}
                    {/*    <Alert severity='error'>The logo you are trying to upload does not match the supported file types (.gif, .png,. jpg, .jpeg, .svg and be smaller than 3 MB). Please try again.</Alert>*/}
                    {/*</Snackbar>*/}
                  </Grid>
                </Grid>
              </Paper>
              <Grid item xs={12}>
                <Grid
                  container
                  item
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Typography
                    style={{
                      padding: "0",
                      margin: "0 0 10px 5px",
                      color: "rgb(170, 170, 170)",
                    }}
                  >
                    Signature Block
                  </Typography>{" "}
                  <Tooltip
                    style={{ marginLeft: "10px" }}
                    title={`To add email link use in the link URL "mailto:" before the email. Example: mailto:mloUser@company.com`}
                  >
                    <HelpOutlineIcon />
                  </Tooltip>
                </Grid>

                <SunEditor
                  defaultValue={formValues.letterSignature || ""}
                  lang="en"
                  onChange={handleOnEditorChange}
                  setDefaultStyle="font-family: cursive; font-size: 14px;"
                  height="300px"
                  width="70%"
                  setOptions={{
                    height: 200,
                    buttonList: [
                      ["undo", "redo"],
                      ["font", "fontSize", "formatBlock"],
                      ["fontColor", "hiliteColor"],
                      ["bold", "underline", "italic"],
                      ["table", "link", "image"],
                      ["removeFormat"],
                      [
                        "outdent",
                        "indent",
                        "align",
                        "horizontalRule",
                        "list",
                        "lineHeight",
                      ],
                      ["codeView"],
                      ["preview"],
                    ],
                  }}
                  getSunEditorInstance={getSunEditorInstance}
                />
              </Grid>

              <Grid item xs={12} style={{ margin: "10px 0 0 0" }}>
                <TextareaAutosize
                  style={{
                    width: "70%",
                    height: "70px",
                    background: "none",
                    borderColor: "#c1c1c1",
                  }}
                  label="Profile"
                  placeholder="Profile"
                  value={formValues.profile || ""}
                  onChange={handleChange}
                  name="profile"
                />
              </Grid>

              <Grid item xs={12} style={{ margin: "10px 0 0 0" }}>
                <TextareaAutosize
                  style={{
                    width: "70%",
                    height: "70px",
                    background: "none",
                    borderColor: "#c1c1c1",
                  }}
                  label="About Me"
                  placeholder="About Me"
                  value={formValues.aboutMe || ""}
                  onChange={handleChange}
                  name="aboutMe"
                />
              </Grid>

              <Grid item xs={12} className={classes.saveButton}>
                <Button
                  style={{ margin: "0 0 10px 10px" }}
                  variant="contained"
                  component={Link}
                  to={AppRoutes.UserChangePassword}
                  color="primary"
                >
                  Change Password
                </Button>
                <Button
                  style={{ margin: "0 0 10px 10px" }}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Paper>
          <Loading isLoading={isSaving} />
          <AlertMessage alert={alertState} />
        </Grid>
      </div>
    );
  };

  if (isLoading)
    return (
      <h4>
        <em>Loading...</em>
      </h4>
    );
  else if (errorLoading)
    return (
      <h4>
        <em>
          There was a data error or you don't have permission to view this page
        </em>
      </h4>
    );
  else return renderMLODetailsSetup();
};

export default MLODetails;
