const floodPremiumBaseFactor = 0.75;
const floodFactor = 0.0042;

export const Depth = {
    Backdrop: 900700,
    Popup: 900600,
    Menu: 900500,
    App: 900000,
};

async function paymentCalculator(
    prop,
    loan,
    down = null,
    offer = null,
    seller = null,
    floodZone = "",
    hoaMo = 0,
    taxYearly = 0
) {
    let loanType = await getLoanType(loan.loanType);

    let downPaymentPercent = +loan.downPaymentPerc / 100;

    let loanAmount = +loan.loanAmount;

    console.log(downPaymentPercent);

    if (down !== null) {
        downPaymentPercent = down / 100;
    }

    let offerPrice = prop.price;
    console.log(offerPrice);

    if (offer !== null) {
        offerPrice = offer;
    }

    let sellerRebate = 0;
    if (seller !== null) {
        sellerRebate = seller;
    }
    //calculate base conventional loan
    let downPayment = +(offerPrice * downPaymentPercent);
    let baseLoanAmount = offerPrice - downPayment;

    console.log("-------PAYMENT CALCULATION---------");
    console.log(
        "Down Payment:",
        downPaymentPercent,
        "% of ",
        offerPrice,
        " is ",
        downPayment
    );
    console.log(
        "Conventional Loan:",
        offerPrice,
        "-",
        downPayment,
        "=",
        baseLoanAmount
    );

    console.log("Loan Type:" + loanType);

    let finalLoanAmount = baseLoanAmount;
    let govLoanAmount = 0;
    //MIP variation for goverment programs
    if (loanType === "FHA" || loanType === "VA" || loanType === "USDA-RHS") {
        let mip = +loan.mipOrVAFee / 100;
        let mipAmt = baseLoanAmount * mip;
        finalLoanAmount = baseLoanAmount + mipAmt;
        govLoanAmount = finalLoanAmount;
        console.log(
            "MIP:",
            baseLoanAmount,
            "* (",
            loan.mipOrVAFee,
            "/100)=",
            mipAmt
        );
        console.log(
            "Gov. Loan:",
            baseLoanAmount,
            "+ ",
            mipAmt,
            "=",
            finalLoanAmount
        );
    }
    //rate, term, pmiFactor
    let rate = +loan.noteRate / 100;
    let term = +loan.term;
    let pmiFactor = loan.insMtgInsPeriodFactor1 ?? 0;
    pmiFactor = downPaymentPercent >= 0.2 ? 0 : pmiFactor;
    console.log(
        "PMI Factor:",
        pmiFactor,
        " for Down Payment ",
        downPaymentPercent
    );

    //taxes, insurance and HOA from property
    let taxes = +taxYearly / 12;
    let homeIns = loan.homeownerInsMo ?? 0;
    let hoa = hoaMo;
    let principalAndInterest = await PMT(rate / 12, term, -finalLoanAmount);
    let approvedMonthlyPayment = +loan.totalHousingExpenses;

    //get home ins factor
    let insFactor = homeIns / loanAmount;
    homeIns = insFactor * offerPrice;

    console.log("----------------");
    console.log(
        "P&I PMT:",
        "PMT((rate:",
        rate,
        "/12),term:",
        term,
        ", -loan Amount:",
        finalLoanAmount,
        ") =",
        principalAndInterest
    );
    console.log("Taxes Monthly:", taxes);
    console.log("HO Insurance Monthly:", homeIns);

    //flood insurance
    let floodIns = loan.floodInsMo ?? 0;
    if (floodZone && floodZone !== "X") {
        if (floodIns === 0) {
            //no value from emcompass, use the calculation
            floodIns = (prop.price * floodPremiumBaseFactor * floodFactor) / 12;
            console.log(
                "Using Flood Calc: ((price:",
                prop.price,
                "* 0.75)*0.0042)/12 =",
                floodIns
            );
        } else {
            //get flood ins factor
            let floodInsFactor = floodIns / loanAmount;
            floodIns = floodInsFactor * offerPrice;
        }
    } else {
        floodIns = 0;
    }

    console.log(
        "Flood Insurance Monthly:",
        floodIns,
        " for FloodZone ",
        floodZone
    );

    //PMI
    let hasOneTimePmi = +loan.oneTimePMI;
    let monthlyPMI =
        pmiFactor == 0 ? 0 : (finalLoanAmount * (pmiFactor / 100)) / 12;
    //console.log('Monthly PMI:', finalLoanAmount, '*(', pmiFactor, '/100)/12', '=', monthlyPMI);
    //PMI pay up front
    if (hasOneTimePmi && loan.loanType === "Conventional") {
        monthlyPMI = 0;
    }

    //Subtotal & HOA Fees
    let subtotal = Math.round(
        principalAndInterest + taxes + homeIns + floodIns + monthlyPMI
    );
    console.log(
        "Subtotal: ROUND(",
        principalAndInterest,
        "+",
        taxes,
        "+",
        homeIns,
        "+",
        floodIns,
        "+",
        monthlyPMI,
        ")=",
        subtotal
    );

    let total = subtotal + hoa;
    console.log("Total Payment: ", subtotal, "+ HOA:", hoa, "=", total);
    console.log(
        "---------------------------------------------------------------"
    );

    //Total payment
    let payment = {
        total: total,
        pipmt: principalAndInterest,
        taxes: taxes,
        insurance: homeIns,
        flood: floodIns,
        pmi: monthlyPMI,
        hoa: hoa,
        convLoanAmount: baseLoanAmount,
        govLoanAmount: govLoanAmount,
        baseLoanAmount: baseLoanAmount,
        finalLoanAmount: finalLoanAmount,
        rate: rate,
        offer: offerPrice,
        down: downPaymentPercent,
        seller: sellerRebate,
        qualified: approvedMonthlyPayment >= total,
    };

    return payment;
}

async function getLoanType($type) {
    switch ($type) {
        case "Conventional":
            return "Conventional";
            break;

        case "VA":
            return "VA";
            break;

        case "FHA":
            return "FHA";
            break;

        case "Other":
            return "Other";
            break;

        case "FarmersHomeAdministration":
            return "USDA-RHS";
            break;

        case "HELOC":
            return "HELOC";
            break;
    }
    return false;
}

async function investmentCalculator(loan, payCal) {
    let total = 0;
    let totalBankDeposit = +loan.totalCashInBank;

    let loanAmount = payCal.finalLoanAmount;
    let salesPrice = payCal.offer;

    console.log("-------INVESTMENT CALCULATION---------");

    // SEC 800
    console.log("--Section 800--");

    //origination fees

    let originationFeePerc = +loan.originationFeePerc;
    let originationFeeAmt = +loan.originationFeeAmt;

    //broker fees
    let brokerFeeAmt = +loan.brokerFeeAmt;
    let brokerFeePerc = +loan.brokerFeePerc;

    //origination points
    let discountPointsPerc = +loan.discountPointsPerc;
    let discountPointsAmountFixed = +loan.discountPointsAmt; // REVISAR COMO USAR CAMPO NUEVO
    let discountPointsAmt = (+loan.loanAmount * discountPointsPerc) / 100;

    //PREGUNTAR A SAM Y BERT
    let totalVarFeesAmt =
        brokerFeeAmt +
        discountPointsAmt +
        originationFeeAmt +
        discountPointsAmountFixed;
    let totalVarFeesPerc =
        brokerFeePerc + discountPointsPerc + originationFeePerc;

    //origination credit
    let originationCreditAmt = +loan.lenderCreditAmt;
    let originationCreditPerc = +loan.lenderCreditPerc;
    let originationCredit = +(
        loan.loanAmount * originationCreditPerc +
        originationCreditAmt
    );

    console.log(
        `Origination Credit ${originationCreditPerc}%, $${originationCreditAmt} `
    );
    console.log(
        "Total Variable fees: $",
        totalVarFeesAmt,
        ",",
        totalVarFeesPerc,
        "%, ",
        brokerFeeAmt,
        discountPointsAmt,
        originationFeeAmt,
        discountPointsAmountFixed,
        ", dic %: ",
        discountPointsPerc
    );

    //fixed fees
    let totalSection800Paid = +loan.totalSection800Paid;
    let fixedFees = totalSection800Paid - totalVarFeesAmt;
    console.log("Fixed Fees section 800: $", fixedFees);

    //calc total fees section 800
    let varFees = (loanAmount * totalVarFeesPerc) / 100;
    let section800Total = varFees + fixedFees;

    console.log("Section 800 total: ", section800Total);

    // sec 900
    //******do payment calculation changes for PMI

    console.log("--Section 900--");

    let daysOfInt = +loan.prepaidInterestDays;
    let rate = +loan.loanRate;
    let percPmi = +loan.MipOrVAFee;
    let hasOneTimePmi = +loan.oneTimePMI;

    if (loan.overridePrepaidIntDays) {
        //use days count from admin panel
        daysOfInt = +loan.prepaidIntDays;
        console.log("Using days of interest from Lender Panel: " + daysOfInt);
    }

    let prepaidIntCharge = ((loanAmount * rate) / 100 / 360) * daysOfInt;

    console.log("Prepaid Interest Charge: ", prepaidIntCharge);

    let section900Total = prepaidIntCharge;

    if (hasOneTimePmi && loan.loanType === "Conventional") {
        section900Total += (loanAmount * percPmi) / 100;
    }

    console.log("Section 900 total: ", section900Total);

    // sec 900 - 1000
    console.log("--Section 900-1000--");

    let purchasePrice = +loan.purchasePrice;

    let hoiReserveMths = +loan.hoiReserveMths;

    let hoi =
        (+loan.homeownerInsMo / purchasePrice) * salesPrice * (hoiReserveMths + 12);
    let floodIns = 0;

    //**home owner insurance reserve months is been used for the flood ins
    if (payCal.floodIns !== 0) {
        floodIns =
            (+loan.floodInsMo / purchasePrice) *
            salesPrice *
            (+loan.floodInsReserveMths + 12);
    }

    //calc sup ins
    let supIns = [];

    if (loan.line907SupInsLabel)
        supIns[loan.line907SupInsLabel] =
            ((+loan.otherInsMo / purchasePrice) * salesPrice) / 12;
    if (loan.line908SupInsLabel)
        supIns[loan.line908SupInsLabel] =
            ((+loan.line908SupInsAmt / purchasePrice) * salesPrice) / 12;
    if (loan.line909SupInsLabel)
        supIns[loan.line909SupInsLabel] =
            ((+loan.line909SupInsAmt / purchasePrice) * salesPrice) / 12;
    if (loan.line910SupInsLabel)
        supIns[loan.line910SupInsLabel] =
            ((+loan.line910SupInsAmt / purchasePrice) * salesPrice) / 12;
    if (loan.line911SupInsLabel)
        supIns[loan.line911SupInsLabel] =
            ((+loan.line911SupInsAmt / purchasePrice) * salesPrice) / 12;
    if (loan.line912SupInsLabel)
        supIns[loan.line912SupInsLabel] =
            ((+loan.line912SupInsAmt / purchasePrice) * salesPrice) / 12;

    console.log("Supplemental Ins:", supIns);

    let supInsTotal = 0;
    if (loan.line1007Label)
        supInsTotal += supIns[loan.line1007Label] * +loan.otherInsReserveMths;

    if (loan.line1008Label)
        supInsTotal += supIns[loan.line1008Label] * +loan.line1008Mths;

    if (loan.line1009Label)
        supInsTotal += supIns[loan.line1009Label] * +loan.line1009Mths;

    let cityPropTax =
        (+loan.propTaxMo / purchasePrice) *
        salesPrice *
        +loan.cityPropTaxReserveMths;

    //find reserve months for each line
    let mtgInsReserveMths =
        hasOneTimePmi && loan.loanType === "Conventional"
            ? 0
            : +loan.mtgInsReserveMths;

    let mtgInsReserve =
        (+loan.mgtInsMonthly / +loan.loanAmount) * loanAmount * mtgInsReserveMths;
    let propTaxReserve = +loan.propTaxReserveMths * payCal.taxes;

    console.log(
        "hoi:",
        hoi,
        ", flood ins:",
        floodIns,
        ", propTaxReserve:",
        propTaxReserve
    );

    //add all lines
    let section1000Total =
        hoi + floodIns + propTaxReserve + cityPropTax + mtgInsReserve + supInsTotal;

    console.log("Section 900-1000 total: ", section1000Total);

    //Section 1100
    console.log("--Section 1100--");

    let section1100 = +loan.totalSection1100Title;
    let ownerTitleIns = +loan.ownerTitleIns;
    let lenderTitleIns = +loan.lenderTitleIns;

    let higherVarFee =
        ownerTitleIns > lenderTitleIns ? ownerTitleIns : lenderTitleIns;

    let fixFees1100 = section1100 - higherVarFee;

    let section1100Total =
        (higherVarFee / +loan.loanAmount) * loanAmount + fixFees1100;

    console.log("Section 1100 total: ", section1100Total);

    //Section 1200
    console.log("--Section 1200--");
    let section1200 = +loan.totalSection1200Costs;
    //390
    let recordingFees = +loan.recordingFees;
    let factor1200 = (section1200 - recordingFees) / +loan.loanAmount;
    let section1200Total = salesPrice * factor1200 + recordingFees;

    console.log("Section 1200 total: ", section1200Total);

    //Section 1300
    console.log("--Section 1300--");
    let section1300Total = +loan.totalSection1300Costs;

    console.log("Section 1300 total: ", section1300Total);

    //Closing Credits
    console.log("--Closing Credits--");
    let closingCredits = +(
        loan.originationCreditPerc * loanAmount +
        loan.otherCreditsAmt
    );
    closingCredits = +originationCredit;

    //Buydown
    console.log("--Buydown Feature--");
    //let buydown = +(loan.otherCreditsAmt);

    //console.log("Buydowns: ", buydown);

    //totals
    let sectionAllTotal =
        section800Total +
        section900Total +
        section1000Total +
        section1100Total +
        section1200Total +
        section1300Total -
        closingCredits;

    let downPaymentAmount = payCal.offer * payCal.down;

    let sellerRebate = payCal.seller;

    total = sectionAllTotal + downPaymentAmount - sellerRebate;
    console.log("Total: " + total);
    // console.log(sectionAllSubTotal,
    //     downPaymentAmount,
    //     sellerRebate, payCal.down);

    //if (totalBankDeposit >= total) {
    //    await set({
    //        qualifyInvestment: {
    //            status: true,
    //            msg: `You're Qualified!`,
    //            msg_with_name: `You're Qualified, ${firstName}`,
    //            color: 'green',
    //        }
    //    });
    //} else {
    //    await set({
    //        qualifyInvestment: {
    //            status: false,
    //            msg: `You May Qualify!`,
    //            msg_with_name: `You May Qualify, ${firstName}`,
    //            color: 'red',
    //        }
    //    });
    //}
    //// console.log(total);
    //await set({
    //    investment: {
    //        total: total
    //    }
    //});
    return { total, qualified: totalBankDeposit >= total };
}

async function round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}

async function PMT(ir, np, pv, fv, type) {
    /*
     * ir   - interest rate per month
     * np   - number of periods (months)
     * pv   - present value
     * fv   - future value
     * type - when the payments are due:
     *        0: end of the period, e.g. end of month (default)
     *        1: beginning of period
     */
    var pmt, pvif;

    fv || (fv = 0);
    type || (type = 0);

    if (ir === 0) return -(pv + fv) / np;

    pvif = Math.pow(1 + ir, np);
    pmt = (-ir * pv * (pvif + fv)) / (pvif - 1);

    if (type === 1) pmt /= 1 + ir;

    return pmt;
}

export function currencyFormatter(amount, fix = 0) {
    if (isNaN(amount)) return "0";
    else
        return amount.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: fix,
            maximumFractionDigits: fix,
        });
}

export function numberToString(amount, fix = 0) {
    if (isNaN(amount)) return "0";
    else
        return amount.toLocaleString("en-US", {
            minimumFractionDigits: fix,
            maximumFractionDigits: fix,
        });
}

export function stringToNumber(amount) {
    if (!amount) return 0;

    let amt = amount.replace(/[kK]/gm, "000"); // Replace K -> 1000 (Used sometimes in Zillow)
    amt = amt.replace(/[^0-9.]+/g, "");

    let amtnum = Number(amt);
    if (isNaN(amtnum)) return 0;
    else return amtnum;
}

export { paymentCalculator, investmentCalculator };