import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Typography,
  Tooltip,
  TextField,
  Dialog,
  Table,
  DialogActions,
  TableBody,
  TableRow,
  TableCell,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import DialogContentText from "@mui/material/DialogContentText";

const useStyles = makeStyles((theme) => ({
  custom: {
    backgroundColor: theme.palette.action.disabledBackground,
  },
  default: {
    fontWeight: 700,
    fontSize: "14px",
    color: theme.palette.grey[700],
  },
}));

const CountyTaxRulesSetup = ({
  lendingStates,
  values,
  updateStateTaxRules,
}) => {
  const [selectedState, setSelectedState] = useState({});
  const [taxesSelectedState, setTaxesSelectedState] = useState([]);

  const [open, setOpen] = React.useState(false);
  const [defaultMinReportedTax, setDefaultMinReportedTax] = useState(
    values.reportedTaxPercent
  );
  const [defaultOfferPricePercent, setDefaultOfferPricePercent] = useState(
    values.offerPricePercent
  );
  const [defaultReportedTaxes, setDefaultReportedTaxes] = useState(
    values.actualReportedTaxes
  );
  const [defaultCustomTaxes, setDefaultCustomTaxes] = useState(
    !values.actualReportedTaxes
  );
  const [openWarning, setOpenWarning] = useState(false);

  const classes = useStyles();

  const handleClickOpen = (state) => {
    setSelectedState(state);
    if (
      state != null &&
      values.taxRules != null &&
      values.taxRules.length > 0
    ) {
      const taxRulesAfterDelete = values.taxRules.filter(
        (s) => s.stateCode == state.stateCode
      );
      setTaxesSelectedState(taxRulesAfterDelete);
    }
    setOpen(true);
  };

  const handleCloseWarning = () => {
    setOpenWarning(false);
  };

  const handleOpenWarning = () => {
    setOpenWarning(true);
  };

  const handleClose = () => {
    setSelectedState({});
    setTaxesSelectedState([]);
    setOpen(false);
  };

  const handleSaveChanges = () => {
    updateStateTaxRules(taxesSelectedState, selectedState);
    handleClose();
  };

  //function handleChangeMinTax(e, row) {
  //    const tempTaxes = taxesSelectedState.slice();
  //    let index = tempTaxes.findIndex(el => el.countyId === row.countyId);
  //    tempTaxes[index].reportedTaxPercent = e.target.value;
  //    setTaxesSelectedState(tempTaxes)
  //}

  function handleChangeMinTax(e, row) {
    const tempTaxes = [...taxesSelectedState];
    const value = Number(e.target.value);
    let index = tempTaxes.findIndex((el) => el.countyId === row.countyId);
    tempTaxes[index] = { ...tempTaxes[index], reportedTaxPercent: value };
    setTaxesSelectedState(tempTaxes);
  }

  //function handleChangePricePercent(e, row) {
  //    const tempTaxes = taxesSelectedState.slice();
  //    let index = tempTaxes.findIndex(el => el.countyId == row.countyId);
  //    tempTaxes[index].offerPricePercent = e.target.value;
  //    setTaxesSelectedState(tempTaxes)
  //}

  function handleChangePricePercent(e, row) {
    const tempTaxes = [...taxesSelectedState];
    const value = Number(e.target.value);
    let index = tempTaxes.findIndex((el) => el.countyId === row.countyId);
    tempTaxes[index] = { ...tempTaxes[index], offerPricePercent: value };
    setTaxesSelectedState(tempTaxes);
  }

  function handleOnClick(row) {
    const tempTaxes = taxesSelectedState.slice();
    let index = tempTaxes.findIndex((el) => el.countyId == row.countyId);
    if (row.actualReportedTaxes) {
      tempTaxes[index].actualReportedTaxes = false;
      tempTaxes[index].offerPricePercent = defaultOfferPricePercent;
      tempTaxes[index].reportedTaxPercent = defaultMinReportedTax;
    } else {
      tempTaxes[index].actualReportedTaxes = true;
      tempTaxes[index].offerPricePercent = 0;
      tempTaxes[index].reportedTaxPercent = 0;
    }
    setTaxesSelectedState(tempTaxes);
  }

  const handleChange = (e) => {
    // missing cleaning up this logic
    const name = e.target.name;
    const value = e.target.type === "radio" ? e.target.checked : e.target.value;

    if (name === "defaultReportedTaxes") {
      setDefaultReportedTaxes(value);
      setDefaultCustomTaxes(!value);
      updateAllStateTaxRules(true, 0, 0, selectedState);
    } else if (name === "defaultCustomTaxes") {
      setDefaultReportedTaxes(!value);
      setDefaultCustomTaxes(value);
      updateAllStateTaxRules(
        false,
        defaultMinReportedTax,
        defaultOfferPricePercent,
        selectedState
      );
    } else if (name === "defaultMinReportedTax")
      setDefaultMinReportedTax(value);
    else if (name === "defaultOfferPricePercent")
      setDefaultOfferPricePercent(value);
  };

  const handleOpenDefaultCustom = () => {
    setOpenWarning(true);
    setDefaultReportedTaxes(false);
    setDefaultCustomTaxes(true);
  };

  const handleDefaultReportedTaxes = () => {
    setOpenWarning(true);
    setDefaultReportedTaxes(true);
    setDefaultCustomTaxes(false);
  };

  const handleOverwrite = () => {
    setOpenWarning(false);
    defaultCustomTaxes
      ? updateAllStateTaxRules(
          false,
          defaultMinReportedTax,
          defaultOfferPricePercent,
          selectedState
        )
      : updateAllStateTaxRules(true, 0, 0, selectedState);
  };

  const isCustomCountySelection = (countyId) => {
    var taxesByCounty = taxesSelectedState.filter(
      (s) => s.countyId == countyId
    );
    var actualReportedTaxesCounty = taxesByCounty[0].actualReportedTaxes;
    var offerPricePercentCounty = taxesByCounty[0].offerPricePercent;
    var reportedTaxPercentCounty = taxesByCounty[0].reportedTaxPercent;
    return (
      actualReportedTaxesCounty !== values.actualReportedTaxes ||
      (actualReportedTaxesCounty === false &&
        (offerPricePercentCounty !== values.offerPricePercent ||
          reportedTaxPercentCounty !== values.reportedTaxPercent))
    );
  };

  const isCustomStateSelection = (stateCode) => {
    var taxesBySate = values.taxRules.filter((s) => s.stateCode == stateCode);
    return taxesBySate.find(
      (e) =>
        e.actualReportedTaxes !== values.actualReportedTaxes ||
        (e.actualReportedTaxes === false &&
          (e.offerPricePercent !== values.offerPricePercent ||
            e.reportedTaxPercent !== values.reportedTaxPercent))
    );
  };

  const updateAllStateTaxRules = (
    value,
    minRepTax,
    offerPricePercent,
    state
  ) => {
    const tempArray = [];
    if (state.counties != null) {
      for (var j = 0; j < state.counties.length; j++) {
        var county = state.counties[j];
        var tax = {
          companyId: values.companyId,
          countyId: county.countyId,
          county: county,
          actualReportedTaxes: value,
          reportedTaxPercent: !value ? parseFloat(minRepTax) : 0,
          offerPricePercent: !value ? parseInt(offerPricePercent) : 0,
          stateCode: county.stateCode,
        };
        tempArray.push(tax);
      }
    }
    setTaxesSelectedState(tempArray);
  };

  return (
    <div>
      <Grid container spacing={1} style={{ margin: "10px 0 0 10px" }}>
        {lendingStates.length > 0 ? (
          lendingStates.map((state) => (
            <Grid
              xs={6}
              item
              key={state.stateCode}
              style={{ padding: "0px", margin: "0 0 0 0px" }}
            >
              <Tooltip
                title={`${state.stateName}${
                  isCustomStateSelection(state.stateCode)
                    ? ": CUSTOM"
                    : ": DEFAULT"
                }`}
              >
                <Button
                  className={
                    isCustomStateSelection(state.stateCode)
                      ? classes.custom
                      : "btn-default"
                  }
                  style={{
                    height: "25px",
                    width: "240px",
                    margin: "10px 15px 5px 10px",
                    justifyContent: "left",
                  }}
                  variant="outlined"
                  name={state.stateId}
                  onClick={() => handleClickOpen(state)}
                >
                  <span
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {state.stateName}
                    {isCustomStateSelection(state.stateCode)
                      ? ":  CUSTOM"
                      : ":  DEFAULT"}
                  </span>
                </Button>
              </Tooltip>
            </Grid>
          ))
        ) : (
          <p></p>
        )}
      </Grid>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {selectedState != null ? selectedState.stateName : <p></p>} Tax Rules
          by County
        </DialogTitle>
        <DialogContent dividers>
          <div style={{ padding: "5px 44px" }}>
            <div style={{ backgroundColor: "#ececec", padding: "20px" }}>
              <h3>Apply following default to all Counties</h3>
              <Grid variant="subtitle1" container item xs={12}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "left",
                  }}
                >
                  <Button
                    style={{
                      height: "25px",
                      width: "176px",
                      margin: "0 5px 5px 5px",
                    }}
                    variant="outlined"
                    onClick={handleOpenDefaultCustom}
                  >
                    {"APPLY TO ALL"}
                  </Button>
                  <h4 style={{ margin: "6px 4px 0" }}>
                    If the reported tax is less than{" "}
                  </h4>
                  <TextField
                    style={{ margin: "0 3px 0 3px", width: "60px" }}
                    inputProps={{
                      style: {
                        padding: 5,
                      },
                      min: 0.0,
                      max: 100,
                      step: 0.01,
                    }}
                    onChange={handleChange}
                    padding="2px"
                    type="number"
                    size="small"
                    name="defaultMinReportedTax"
                    value={defaultMinReportedTax}
                    onKeyPress={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                  />
                  <h4 style={{ margin: "6px 4px 0" }}>
                    % of the listed price, use{" "}
                  </h4>
                  <TextField
                    style={{ margin: "0 3px 0 3px", width: "60px" }}
                    inputProps={{
                      style: {
                        padding: 5,
                      },
                      min: 0.0,
                      max: 100,
                      step: 0.01,
                    }}
                    onKeyPress={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onChange={handleChange}
                    size="small"
                    type="number"
                    name="defaultOfferPricePercent"
                    value={defaultOfferPricePercent}
                  />
                  <h4 style={{ margin: "0" }}>
                    % of the offer price instead of
                  </h4>
                  <h4 style={{ margin: "0" }}>
                    {" "}
                    the actual reported property tax.{" "}
                  </h4>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "center",
                    marginTop: "5px",
                  }}
                >
                  <Button
                    style={{
                      height: "25px",
                      width: "176px",
                      margin: "0 5px 5px 5px",
                    }}
                    variant="outlined"
                    onClick={handleDefaultReportedTaxes}
                  >
                    {"APPLY TO ALL"}
                  </Button>
                  <h4 style={{ margin: "0 4px 0" }}>
                    Use actual reported taxes to calculate payment.
                  </h4>
                </div>
              </Grid>
            </div>
          </div>
          <Table aria-label="simple table">
            <TableBody>
              {selectedState != null &&
              taxesSelectedState != null &&
              taxesSelectedState.length > 0 ? (
                taxesSelectedState.map((row) => (
                  <TableRow key={row.countyId}>
                    <TableCell component="th" scope="row"></TableCell>
                    <TableCell align="left">
                      <Button
                        className={
                          isCustomCountySelection(row.countyId)
                            ? classes.custom
                            : "btn-default"
                        }
                        style={{
                          height: "25px",
                          width: "315px",
                          justifyContent: "left",
                        }}
                        variant="outlined"
                        color="primary"
                        onClick={() => handleOnClick(row)}
                      >
                        <span style={{ textTransform: "capitalize" }}>
                          {
                            lendingStates
                              .filter(
                                (t) => t.stateCode == selectedState.stateCode
                              )[0]
                              .counties.filter(
                                (t) => t.countyId == row.countyId
                              )[0].countyName
                          }
                        </span>
                        {isCustomCountySelection(row.countyId)
                          ? ":  CUSTOM"
                          : ":  DEFAULT"}
                      </Button>
                    </TableCell>
                    <TableCell align="left">
                      {row.actualReportedTaxes ? (
                        <p>
                          {" "}
                          <h4>
                            Use actual reported taxes to calculate payment.
                          </h4>
                        </p>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            alignItems: "center",
                          }}
                        >
                          <h4 style={{ margin: "0" }}>
                            If the reported tax is less than{" "}
                          </h4>
                          <TextField
                            style={{ margin: "0 3px 0 3px", width: "60px" }}
                            inputProps={{
                              style: {
                                padding: 3,
                              },
                              min: 0.0,
                              max: 100,
                              step: 0.01,
                            }}
                            type="number"
                            onKeyPress={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                            onChange={(e) => {
                              handleChangeMinTax(e, row);
                            }}
                            padding="2px"
                            size="small"
                            name={"defaultMinReportedTax" + row.countyId}
                            value={row.reportedTaxPercent}
                          />
                          <h4 style={{ margin: "0" }}>
                            % of the listed price, use{" "}
                          </h4>
                          <TextField
                            style={{ margin: "0 3px 0 3px", width: "60px" }}
                            inputProps={{
                              style: {
                                padding: 3,
                              },
                              min: 0.0,
                              max: 100,
                              step: 0.01,
                            }}
                            type="number"
                            onKeyPress={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                            onChange={(e) => {
                              handleChangePricePercent(e, row);
                            }}
                            size="small"
                            name={"defaultOfferPricePercent" + row.countyId}
                            value={row.offerPricePercent}
                          />
                          <h4 style={{ margin: "0" }}>
                            % of the offer price instead of
                          </h4>
                          <h4 style={{ margin: "0" }}>
                            {" "}
                            the actual reported property tax.{" "}
                          </h4>
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow></TableRow>
              )}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Grid style={{ margin: "10px 60px 5px" }}>
            <Grid item xs={12}>
              <div>
                <Button
                  style={{ margin: "0 10px 10px 10px" }}
                  variant="contained"
                  color="primary"
                  onClick={handleClose}
                >
                  Back
                </Button>
                <Button
                  style={{ margin: "0 0 10px 10px" }}
                  variant="contained"
                  color="primary"
                  onClick={handleSaveChanges}
                >
                  Save
                </Button>
              </div>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openWarning}
        onClose={handleCloseWarning}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"WARNING"}</DialogTitle>
        <DialogContent style={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}>
          <DialogContentText id="alert-dialog-description">
            <div style={{ padding: "7px", fonSize: "18px" }}>
              Changing This System Wide Default Will Overwrite All Current
              Custom Tax Calculation Settings!
            </div>

            <div style={{ padding: "7px", fontWeight: 700 }}>
              Are You Sure You Want to proceed And Overwrite All state tax
              setting ? This Cannot Be Undone
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid style={{ margin: "10px 10px 5px 10px" }}>
            <Button
              style={{ margin: "0 10px 10px 10px" }}
              onClick={handleCloseWarning}
              variant="contained"
              autoFocus
            >
              GO BACK
            </Button>
            <Button
              style={{ margin: "0 10px 10px 10px" }}
              color="primary"
              onClick={handleOverwrite}
              variant="contained"
            >
              OVERWRITE
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default CountyTaxRulesSetup;
