import { useEffect, useState } from "react";

export const Patterns = {
  YEAR: "{{YEAR}}",
  DATE_YEAR: "{{DATE_YEAR}",
  DATE_TIME: "{{DATE-TIME}}",
  DATE: "{{Date}}",
  BROKERAGE: "{{BROKERAGE}}",
};

export const replacePatterns = (text) => {
  const currentYear = String(new Date().getFullYear());
  const currentDateTime = new Date().toLocaleString();
  const currentDate = new Date().toLocaleDateString();

  var result = text
    .replaceAll(Patterns.YEAR, currentYear)
    .replaceAll(Patterns.DATE_YEAR, currentYear)
    .replaceAll(Patterns.DATE, currentDate)
    .replaceAll(Patterns.DATE_TIME, currentDateTime);

  return result;
};
