import {
  Box,
  Button,
  Backdrop,
  CircularProgress,
  Typography,
  TextField,
  FormHelperText,
  Grid,
  Stack,
} from "@mui/material";
import React, { useEffect, useContext } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { postApiData } from "../core/FetchApiData";
import { ApiPaths, AppRoutes } from "../core/PathConstants";
import "../Layout.css";
import { CurrentUserContext } from "../core/hooks/CurrentUserContext";

export default function VerifyCode({ isForChangePassword }) {
  const { user, onChangeUser } = useContext(CurrentUserContext);

  const [code, setCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [countdown, setCountdown] = useState(180);
  const [expiredTime, setExpiredTime] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [resent, setResent] = useState(false);

  const history = useHistory();
  const userId = new URLSearchParams(location.search).get("id");

  useEffect(() => {
    const currentDate = new Date();
    const currentDateTimestamp = Math.floor(currentDate.getTime() / 1000);
    const paramDateTimestamp = new URLSearchParams(location.search).get("date");
    const differenceInSeconds = Math.floor(
      paramDateTimestamp - currentDateTimestamp
    );
    setExpiredTime(differenceInSeconds);
  }, []);

  const onCodeChange = (evt) => {
    let value = evt.target.value;
    setCode(value);
  };

  const onError = (status, msg) => {
    setIsLoading(false);
    if (status !== 500) setErrorMessage(msg);
    else setErrorMessage("Error verifying code! Please try again later.");
  };

  const onErrorResend = (status, msg) => {
    setIsLoading(false);
    if (status !== 500) setErrorMessage(msg);
    else setErrorMessage("Error resending code! Please try again later.");
  };

  const onVerifyCode = (evt) => {
    var res = evt.currentTarget.form.reportValidity();
    if (res) {
      setErrorMessage("");
      let data = {
        userId: userId,
        otp: code,
      };
      setIsLoading(true);
      postApiData(
        ApiPaths.VerifyOTP,
        "post",
        data,
        onSuccessVerifyCode,
        onError
      );
      setCode("");
    }
  };

  const onSuccessVerifyCode = (u) => {
    setIsLoading(false);
    if (isForChangePassword) {
      history.push(AppRoutes.ChangeResetPassword);
    } else {
      onChangeUser(u);
      history.push(AppRoutes.Home);
    }
  };

  const onSuccessResend = () => {
    setIsLoading(false);
    setResent(true);
    let currentDate = new Date();
    currentDate.setMinutes(currentDate.getMinutes() + 15);
    const currentDateTimestamp = Math.floor(currentDate.getTime() / 1000);
    if (!isForChangePassword) {
      history.push(
        `/auth/verify-code?id=${userId}&date=${currentDateTimestamp}`
      );
    } else {
      history.push(
        `/auth/verify-code-to-reset-password?id=${userId}&date=${currentDateTimestamp}`
      );
    }
    setExpiredTime(900);
  };

  const onResendCode = (event) => {
    event.preventDefault();
    setCode("");
    setDisabled(true);
    setResent(false);
    startCountdown();
    let restoreData = {
      userId: userId,
    };
    setIsLoading(true);
    postApiData(
      ApiPaths.ResendOTP,
      "post",
      restoreData,
      onSuccessResend,
      onErrorResend
    );
  };

  const startCountdown = () => {
    setCountdown(180);
  };

  useEffect(() => {
    if (countdown < 1) {
      setDisabled(false);
      return;
    }
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);

      if (countdown < 1) {
        setDisabled(false); // Enable the button after the countdown is finished
        clearInterval(timer);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [countdown]);

  useEffect(() => {
    if (expiredTime < 1) return;
    const timer = setInterval(() => {
      setExpiredTime((prevExpiredTime) => prevExpiredTime - 1);

      if (expiredTime < 1) {
        clearInterval(timer);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [expiredTime]);

  return (
    <Box
      component="form"
      sx={{
        mt: 5,
        mx: "auto",
        "& .MuiTextField-root": { m: 1, width: "35ch" },
        "& .MuiButton-root": { m: 1 },
      }}
      autoComplete="off"
    >
      <Grid
        container
        display="flex"
        direction="column"
        m={2}
        justifyContent="center"
      >
        <Stack sx={{ alignItems: "center" }}>
          <Typography variant="h6" component="div">
            <img
              alt="Padzilly"
              src="images/logo-navy.svg"
              width="150"
              height="50"
            />
          </Typography>
          <Typography
            sx={{
              fontSize: "26",
              fontFamily: "American Captain Regular",
              color: "#283C8F",
            }}
            variant="h4"
            textAlign="center"
            component="div"
          >
            For Partners
          </Typography>
        </Stack>
        <Stack
          sx={{
            alignItems: "center",
            marginTop: "2rem",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
            }}
            variant="h5"
            textAlign="center"
            component="div"
          >
            Please enter the verification code we sent to your email address
          </Typography>
          <Typography
            sx={{
              fontWeight: "bold",
            }}
            variant="body2"
            textAlign="center"
            component="div"
          >
            Check your spam or junk folder if it's not in your inbox. This take
            2-3 minutes.
          </Typography>
          <Grid
            style={{
              backgroundColor: "#f5f4f4",
              marginTop: "1rem",
              padding: "0.5rem",
              color: "#e55b18",
            }}
          >
            <Typography
              borderRadius="md"
              p="2"
              alignSelf="center"
              textAlign="center"
              fontSize="lg"
              variant="h6"
              style={{ fontWeight: "bold" }}
            >
              {`Expires in ${Math.floor(expiredTime / 60)}:${
                expiredTime % 60 < 10 ? "0" : ""
              }${expiredTime % 60}`}{" "}
            </Typography>
          </Grid>
        </Stack>
      </Grid>

      <Grid
        container
        m={2}
        justifyContent="center"
        style={{ marginTop: "1.8rem" }}
      >
        <Grid>
          <Grid item xs={12}>
            <TextField
              id="code"
              size="small"
              value={code}
              type={"text"}
              label="Code"
              variant="outlined"
              inputProps={{ maxLength: 6 }}
              onChange={onCodeChange}
              required
            />
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-end"
            alignItems="center"
            direction="row"
            style={{ marginTop: "1.5rem" }}
          >
            <Link
              onClick={onResendCode}
              style={{
                marginRight: "1rem",
                pointerEvents: !disabled ? "" : "none",
                textDecoration: disabled && "none",
              }}
              href="#"
              disabled={disabled}
              to={""}
            >
              <Typography
                variant={"caption"}
                style={{
                  color: disabled && "gray",
                }}
              >
                Resend
              </Typography>
            </Link>
            <Button variant="contained" onClick={onVerifyCode}>
              VERIFY
            </Button>
          </Grid>
        </Grid>
        {resent && (
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            alignItems="center"
            direction="column"
            style={{ marginTop: "2rem", borderRadius: "6px" }}
          >
            <Grid style={{ backgroundColor: "#b7c8e5cc" }}>
              <FormHelperText
                sx={{
                  m: 2,
                  marginBottom: 0,
                  color: "black",
                  fontSize: "16px",
                }}
              >
                We have sent you a new code to your email.
              </FormHelperText>
              <FormHelperText
                sx={{
                  m: 2,
                  marginTop: 0,
                  color: "black",
                  fontSize: "16px",
                }}
              >
                Please check your inbox and spam folder.
              </FormHelperText>
            </Grid>
          </Grid>
        )}
        {errorMessage && (
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            alignItems="center"
            direction="row"
            style={{ marginTop: "2rem", borderRadius: "6px" }}
          >
            <Grid style={{ backgroundColor: "#f97b7bcc" }}>
              <FormHelperText sx={{ color: "black", m: 2, fontSize: "16px" }}>
                {errorMessage}
              </FormHelperText>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
