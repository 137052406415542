import React, {
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
  useMemo,
} from "react";
import {
  Typography,
  Grid,
  Paper,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import { NumericFormat } from "react-number-format";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "0.5rem 1.5rem",
    margin: "0.5rem 0 0.5rem 0",
    backgroundColor: "#ffffff",
    borderColor: "#a5a4a4",
  },
  loanPercentTextfields: {
    width: 90,
    marginRight: "0.2rem",
    height: "25px",
    border: "1px solid rgb(0 0 0 / 23%)",
  },
  loanPercentTextfieldsError: {
    width: 90,
    marginRight: "0.2rem",
    height: "25px",
    border: "3px solid rgb(205 18 18 / 57%)",
  },
  loanBigTextfields: {
    width: 130,
    height: "25px",
    border: "1px solid rgb(0 0 0 / 23%)",
  },
  loanTextfieldsInput: {
    padding: "8px 10px",
    fontSize: "12px",
    height: "0.5rem",
    backgroundColor: "white",
  },
  loanLabels: {
    fontSize: "14px",
  },
  loanGrid: {
    margin: "0.5rem 0 0.5rem 0",
    justifyContent: "space-between",
  },
  loanGridItem: {
    justifyContent: "flex-start",
  },
}));

export default function LoanItemizationDataTab({
  loanValues,
  handleNumericValues,
  handleCheckLoanType,
  handleCheckHasSinglePMI,
  handleCheckHasUsedVABefore,
  isValidType,
  formErrors,
  areValidValues,
}) {
  const classes = useStyles();

  const loanType = () => {
    return (
      <Grid
        container
        direction={"row"}
        style={{ margin: 0, color: isValidType ? "#000000" : "#ff0c0c" }}
        alignItems={"center"}
      >
        <Grid
          style={{
            margin: "0.5rem",
          }}
        >
          <span
            style={{
              padding: "0.3rem 0.5rem",
              backgroundColor: "#282887",
              color: "white",
              borderRadius: "50%",
              marginRight: "0.5rem",
            }}
          >
            1
          </span>
          <strong>
            <span
              style={{ fontSize: "16px", color: "#33469d" }}
              className={classes.loanLabels}
            >
              Please select loan type
            </span>
          </strong>
        </Grid>

        <FormControlLabel
          style={{
            margin: "0 20px 0 0px",
          }}
          control={
            <Checkbox
              value={"Conventional"}
              onClick={handleCheckLoanType}
              color="primary"
              checked={loanValues.loanType === "Conventional"}
            />
          }
          label="Conventional"
        />
        <FormControlLabel
          style={{ margin: "0 20px 0 0px" }}
          control={
            <Checkbox
              value={"FHA"}
              onClick={handleCheckLoanType}
              color="primary"
              checked={loanValues.loanType === "FHA"}
            />
          }
          label="FHA"
        />
        <FormControlLabel
          style={{ margin: "0 20px 0 0px" }}
          control={
            <Checkbox
              value={"VA"}
              onClick={handleCheckLoanType}
              color="primary"
              checked={loanValues.loanType === "VA"}
            />
          }
          label="VA"
        />
        <FormControlLabel
          style={{
            margin: "0 0 0 3rem",
            display:
              loanValues.loanType === "Conventional" &&
              parseFloat(
                (loanValues.loanAmount / loanValues.purchasePrice) * 100
              ) > 80.0
                ? "inline-flex"
                : "none",
          }}
          control={
            <Checkbox
              onClick={handleCheckHasSinglePMI}
              color="primary"
              name="hasOneTimePMI"
              checked={loanValues.oneTimePMI === 1}
            />
          }
          label="Has single premium PMI"
        />
        <FormControlLabel
          style={{
            margin: "0 0 0 3rem",
            display: loanValues.loanType === "VA" ? "inline-flex" : "none",
          }}
          control={
            <Checkbox
              value={-1}
              onClick={handleCheckHasUsedVABefore}
              color="primary"
              name="vaFirstUse"
              checked={!loanValues.vaFirstUse}
            />
          }
          label="Borrower HAS USED VA BEFORE"
        />
        <Grid
          style={{
            margin: "0.5rem",
          }}
          item
          xs={12}
        >
          <span
            style={{
              padding: "0.3rem 0.5rem",
              backgroundColor: "#282887",
              color: "white",
              borderRadius: "50%",
              marginRight: "0.5rem",
            }}
          >
            2
          </span>
          <strong>
            <span
              style={{ fontSize: "16px", color: "#33469d" }}
              className={classes.loanLabels}
            >
              Review loan parameters
            </span>
          </strong>
        </Grid>
      </Grid>
    );
  };

  const interestRateAndLtv = () => {
    return (
      <Grid container>
        <Grid item lg={7} container className={classes.loanGrid}>
          <Grid item lg={5} md={6}>
            <span className={classes.loanLabels}>Interest Rate</span>
          </Grid>
          <Grid item container lg={7} md={6} className={classes.loanGridItem}>
            <NumericFormat
              className={
                !!formErrors.noteRate && !areValidValues
                  ? classes.loanPercentTextfieldsError
                  : classes.loanPercentTextfields
              }
              value={loanValues.noteRate}
              onValueChange={(values) =>
                handleNumericValues(values, "noteRate")
              }
              decimalScale={3}
              fixedDecimalScale
              thousandsGroupStyle="thousand"
              thousandSeparator=","
              suffix={"%"}
              required
            />
          </Grid>
          <Grid item lg={3}></Grid>{" "}
        </Grid>
        <Grid item lg={5} container className={classes.loanGrid}>
          <Grid item lg={5} md={6}>
            <span className={classes.loanLabels}>LTV</span>
          </Grid>
          <Grid item container lg={7} md={6} className={classes.loanGridItem}>
            <NumericFormat
              className={
                loanValues.purchasePrice <= 0 ||
                ((loanValues.loanType == "FHA" ||
                  loanValues.loanType == "VA") &&
                  ((loanValues.loanAmount - loanValues.oneTimePMI) /
                    loanValues.purchasePrice) *
                    100 >
                    96.5) ||
                (loanValues.loanType == "Conventional" &&
                  (loanValues.loanAmount / loanValues.purchasePrice) * 100 > 97)
                  ? classes.loanPercentTextfieldsError
                  : classes.loanPercentTextfields
              }
              readOnly={true}
              value={
                loanValues.loanType == "FHA" || loanValues.loanType == "VA"
                  ? ((loanValues.loanAmount - loanValues.oneTimePMI) /
                      loanValues.purchasePrice) *
                    100
                  : (loanValues.loanAmount / loanValues.purchasePrice) * 100
              }
              style={{ backgroundColor: "#ebebeb" }}
              decimalScale={1}
              fixedDecimalScale
              thousandsGroupStyle="thousand"
              thousandSeparator=","
              suffix={"%"}
              required
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const loanAmount = () => {
    return (
      <Grid container>
        <Grid item lg={7} container className={classes.loanGrid}>
          <Grid item lg={5} md={6}>
            <span className={classes.loanLabels}>Loan Amount</span>
          </Grid>
          <Grid item container lg={7} md={6} className={classes.loanGridItem}>
            <NumericFormat
              className={
                !!formErrors.loanAmount && !areValidValues
                  ? classes.loanPercentTextfieldsError
                  : classes.loanPercentTextfields
              }
              value={loanValues.loanAmount}
              onValueChange={(values) =>
                handleNumericValues(values, "loanAmount")
              }
              decimalScale={2}
              fixedDecimalScale
              thousandsGroupStyle="thousand"
              thousandSeparator=","
              prefix={"$"}
              required
            />
          </Grid>
          <Grid item lg={3}></Grid>{" "}
        </Grid>
        <Grid item lg={5} container className={classes.loanGrid}>
          <Grid
            item
            lg={5}
            md={6}
            style={{
              display: loanValues.loanType == "FHA" ? "flex" : "none",
            }}
          >
            <span className={classes.loanLabels}>Base Loan</span>
          </Grid>
          <Grid
            item
            container
            lg={7}
            md={6}
            className={classes.loanGridItem}
            style={{
              display: loanValues.loanType == "FHA" ? "flex" : "none",
            }}
          >
            <NumericFormat
              className={classes.loanPercentTextfields}
              value={loanValues.loanAmount - loanValues.oneTimePMI}
              style={{ backgroundColor: "#ebebeb" }}
              decimalScale={2}
              readOnly={true}
              fixedDecimalScale
              thousandsGroupStyle="thousand"
              thousandSeparator=","
              prefix={"$"}
              required
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const loanOriginatorFee = () => {
    return (
      <Grid container className={classes.loanGrid}>
        <Grid item lg={4} md={6}>
          <span className={classes.loanLabels}>Loan Origination Fee</span>
        </Grid>
        <Grid item container lg={5} md={3} className={classes.loanGridItem}>
          <NumericFormat
            className={classes.loanPercentTextfields}
            value={loanValues.originationFeePerc}
            onValueChange={(values) =>
              handleNumericValues(values, "originationFeePerc")
            }
            decimalScale={3}
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            suffix={"%"}
          />
          <span className={classes.loanLabels}>or +</span>
          <NumericFormat
            className={classes.loanPercentTextfields}
            value={loanValues.originationFeeAmt}
            onValueChange={(values) =>
              handleNumericValues(values, "originationFeeAmt")
            }
            decimalScale={2}
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            prefix={"$"}
          />
        </Grid>
        <Grid item container lg={3} md={3} className={classes.loanGridItem}>
          <NumericFormat
            className={classes.loanBigTextfields}
            value={
              loanValues.originationFeeAmt === 0
                ? loanValues.loanAmount * (loanValues.originationFeePerc / 100)
                : loanValues.loanAmount *
                    (loanValues.originationFeePerc / 100) +
                  loanValues.originationFeeAmt
            }
            decimalScale={2}
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            prefix={"$"}
          />
        </Grid>
      </Grid>
    );
  };

  const brokerFee = () => {
    return (
      <Grid container className={classes.loanGrid}>
        <Grid item lg={4} md={6}>
          <span className={classes.loanLabels}>Broker Fee</span>
        </Grid>
        <Grid item container lg={5} md={3} className={classes.loanGridItem}>
          <NumericFormat
            className={classes.loanPercentTextfields}
            value={loanValues.brokerFeePerc}
            onValueChange={(values) =>
              handleNumericValues(values, "brokerFeePerc")
            }
            decimalScale={3}
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            suffix={"%"}
          />
          <span className={classes.loanLabels}>or +</span>
          <NumericFormat
            className={classes.loanPercentTextfields}
            value={loanValues.brokerFeeAmt}
            onValueChange={(values) =>
              handleNumericValues(values, "brokerFeeAmt")
            }
            decimalScale={2}
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            prefix={"$"}
          />
        </Grid>
        <Grid item container lg={3} md={3} className={classes.loanGridItem}>
          <NumericFormat
            className={classes.loanBigTextfields}
            value={
              loanValues.brokerFeeAmt === 0
                ? (loanValues.loanAmount * loanValues.brokerFeePerc) / 100
                : (loanValues.loanAmount * loanValues.brokerFeePerc +
                    loanValues.brokerFeeAmt) /
                  100
            }
            decimalScale={2}
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            prefix={"$"}
          />
        </Grid>
      </Grid>
    );
  };

  const originationCredit = () => {
    return (
      <Grid container className={classes.loanGrid}>
        <Grid item lg={4} md={6}>
          <span className={classes.loanLabels}>Origination Credit</span>
        </Grid>
        <Grid item container lg={5} md={3} className={classes.loanGridItem}>
          <NumericFormat
            className={classes.loanPercentTextfields}
            value={loanValues.lenderCreditPerc}
            onValueChange={(values) =>
              handleNumericValues(values, "lenderCreditPerc")
            }
            decimalScale={3}
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            suffix={"%"}
          />
          <span className={classes.loanLabels}>or +</span>
          <NumericFormat
            className={classes.loanPercentTextfields}
            value={loanValues.lenderCreditAmt}
            onValueChange={(values) =>
              handleNumericValues(values, "lenderCreditAmt")
            }
            decimalScale={2}
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            prefix={"$"}
          />
        </Grid>
        <Grid item container lg={3} md={3} className={classes.loanGridItem}>
          <NumericFormat
            className={classes.loanBigTextfields}
            value={
              loanValues.lenderCreditAmt === 0
                ? (loanValues.lenderCreditPerc / 100) * loanValues.loanAmount
                : (loanValues.lenderCreditPerc / 100) * loanValues.loanAmount +
                  loanValues.lenderCreditAmt
            }
            decimalScale={2}
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            prefix={"$"}
          />
        </Grid>
      </Grid>
    );
  };

  const loanDiscountPoints = () => {
    return (
      <Grid container className={classes.loanGrid}>
        <Grid item lg={4} md={6}>
          <span className={classes.loanLabels}>Loan Discount Points</span>
        </Grid>
        <Grid item container lg={5} md={3} className={classes.loanGridItem}>
          <NumericFormat
            className={classes.loanPercentTextfields}
            value={loanValues.discountPointsPerc}
            onValueChange={(values) =>
              handleNumericValues(values, "discountPointsPerc")
            }
            decimalScale={3}
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            suffix={"%"}
          />
          <span className={classes.loanLabels}>or +</span>
          <NumericFormat
            className={classes.loanPercentTextfields}
            value={loanValues.discountPointsAmt}
            onValueChange={(values) =>
              handleNumericValues(values, "discountPointsAmt")
            }
            decimalScale={2}
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            prefix={"$"}
          />
        </Grid>
        <Grid item container lg={3} md={3} className={classes.loanGridItem}>
          <NumericFormat
            className={classes.loanBigTextfields}
            value={
              loanValues.discountPointsAmt === 0
                ? (loanValues.discountPointsPerc / 100) * loanValues.loanAmount
                : (loanValues.discountPointsPerc / 100) *
                    loanValues.loanAmount +
                  loanValues.discountPointsAmt
            }
            decimalScale={2}
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            prefix={"$"}
          />
        </Grid>
      </Grid>
    );
  };

  const interestToFirstPayment = () => {
    return (
      <Grid container className={classes.loanGrid}>
        <Grid item lg={4} md={6}>
          <span className={classes.loanLabels}>INTEREST TO FIRST PAYMENT</span>
        </Grid>
        <Grid item container lg={5} md={3} className={classes.loanGridItem}>
          <NumericFormat
            className={classes.loanPercentTextfields}
            value={loanValues.prepaidInterestDays}
            onValueChange={(values) =>
              handleNumericValues(values, "prepaidInterestDays")
            }
            decimalScale={0}
            suffix={" days"}
          />
        </Grid>
        <Grid item container lg={3} md={3} className={classes.loanGridItem}>
          <NumericFormat
            className={classes.loanBigTextfields}
            value={
              (((loanValues.loanAmount * loanValues.noteRate) / 365) *
                loanValues.prepaidInterestDays) /
              100
            }
            decimalScale={2}
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            prefix={"$"}
          />
        </Grid>
      </Grid>
    );
  };

  const conventionalSinglePremiumPMI = () => {
    return (
      <Grid
        container
        className={classes.loanGrid}
        style={{
          display:
            loanValues.loanType === "Conventional" &&
            loanValues.oneTimePMI === 1
              ? "flex"
              : "none",
        }}
      >
        <Grid item lg={4} md={6}>
          <span className={classes.loanLabels}>
            Conventional Single Premium PMI
          </span>
        </Grid>
        <Grid item container lg={5} md={3} className={classes.loanGridItem}>
          <TextField
            className={classes.loanPercentTextfields}
            variant="outlined"
            size="small"
            value={loanValues.oneTimePMI}
            style={{ backgroundColor: "#ebebeb" }}
            InputProps={{
              classes: { input: classes.loanTextfieldsInput },
              readOnly: true,
            }}
          />
        </Grid>
        <Grid
          item
          container
          lg={3}
          md={3}
          className={classes.loanGridItem}
        ></Grid>
      </Grid>
    );
  };

  const fhaUpMipMortgageInsurancePremium = () => {
    return (
      <Grid
        container
        className={classes.loanGrid}
        style={{
          display: loanValues.loanType === "FHA" ? "flex" : "none",
        }}
      >
        <Grid item lg={4} md={6}>
          <span className={classes.loanLabels}>
            FHA UFMIP Mortgage Insurance Premium
          </span>
        </Grid>
        <Grid item container lg={5} md={3} className={classes.loanGridItem}>
          <NumericFormat
            className={classes.loanPercentTextfields}
            value={loanValues.oneTimePMI}
            onValueChange={(values) =>
              handleNumericValues(values, "oneTimePMI")
            }
            decimalScale={2}
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            prefix={"$"}
          />
        </Grid>
        <Grid
          item
          container
          lg={3}
          md={3}
          className={classes.loanGridItem}
        ></Grid>
      </Grid>
    );
  };

  const vaFundingFee = () => {
    return (
      <Grid
        container
        className={classes.loanGrid}
        style={{
          display: loanValues.loanType === "VA" ? "flex" : "none",
        }}
      >
        <Grid item lg={4} md={6}>
          {" "}
          <span className={classes.loanLabels}>VA Funding Fee</span>
        </Grid>
        <Grid item container lg={5} md={3} className={classes.loanGridItem}>
          <NumericFormat
            className={classes.loanPercentTextfields}
            value={loanValues.oneTimePMI}
            onValueChange={(values) =>
              handleNumericValues(values, "oneTimePMI")
            }
            decimalScale={2}
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            prefix={"$"}
          />
        </Grid>

        <Grid
          item
          container
          lg={3}
          md={3}
          className={classes.loanGridItem}
        ></Grid>
      </Grid>
    );
  };

  const mortgageInsuranceReserves = () => {
    return (
      <Grid
        container
        className={classes.loanGrid}
        style={{
          //display: loanValues.loanType === "FHA" ? "flex" : "none",
          display: "none",
        }}
      >
        <Grid item lg={4} md={6}>
          {" "}
          <span className={classes.loanLabels}>
            Mortgage Insurance Reserves
          </span>
        </Grid>
        <Grid item container lg={5} md={3} className={classes.loanGridItem}>
          <NumericFormat
            className={classes.loanPercentTextfields}
            value={loanValues.mtgInsReserveMths}
            onValueChange={(values) =>
              handleNumericValues(values, "mtgInsReserveMths")
            }
            decimalScale={0}
            suffix={" mths"}
          />
        </Grid>
        <Grid item container lg={3} md={3} className={classes.loanGridItem}>
          <NumericFormat
            className={classes.loanBigTextfields}
            value={loanValues.mtgInsReserveMths * loanValues.mgtInsMonthly}
            decimalScale={2}
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            prefix={"$"}
          />
        </Grid>
      </Grid>
    );
  };

  const hazardInsurancePremiumMonthly = () => {
    return (
      <Grid container className={classes.loanGrid}>
        <Grid item lg={4} md={6}>
          {" "}
          <span className={classes.loanLabels}>
            Hazard Insurance Premium - monthly
          </span>
        </Grid>
        <Grid item container lg={5} md={3} className={classes.loanGridItem}>
          <NumericFormat
            className={classes.loanPercentTextfields}
            value={loanValues.homeownerInsMo}
            onValueChange={(values) =>
              handleNumericValues(values, "homeownerInsMo")
            }
            decimalScale={2}
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            prefix={"$"}
          />
        </Grid>
        <Grid item container lg={3} md={3} className={classes.loanGridItem}>
          <NumericFormat
            className={classes.loanBigTextfields}
            value={loanValues.homeownerInsMo * 12}
            decimalScale={2}
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            prefix={"$"}
          />
        </Grid>
      </Grid>
    );
  };

  const hazardInsuranceReserves = () => {
    return (
      <Grid container className={classes.loanGrid}>
        <Grid item lg={4} md={6}>
          <span className={classes.loanLabels}>Hazard Insurance Reserves</span>
        </Grid>
        <Grid item container lg={5} md={3} className={classes.loanGridItem}>
          <NumericFormat
            className={classes.loanPercentTextfields}
            value={loanValues.hoiReserveMths}
            onValueChange={(values) =>
              handleNumericValues(values, "hoiReserveMths")
            }
            decimalScale={0}
            suffix={" mths"}
          />
        </Grid>
        <Grid item container lg={3} md={3} className={classes.loanGridItem}>
          <NumericFormat
            className={classes.loanBigTextfields}
            value={loanValues.hoiReserveMths * loanValues.homeownerInsMo}
            decimalScale={2}
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            prefix={"$"}
          />
        </Grid>
      </Grid>
    );
  };

  const countyPropTaxMonthly = () => {
    return (
      <Grid container className={classes.loanGrid}>
        <Grid item lg={4} md={6}>
          {" "}
          <span className={classes.loanLabels}>County Prop Tax - monthly</span>
        </Grid>
        <Grid item container lg={5} md={3} className={classes.loanGridItem}>
          <NumericFormat
            className={classes.loanPercentTextfields}
            value={loanValues.propTaxMo}
            onValueChange={(values) => handleNumericValues(values, "propTaxMo")}
            decimalScale={2}
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            prefix={"$"}
          />
        </Grid>
        <Grid
          item
          container
          lg={3}
          md={3}
          className={classes.loanGridItem}
        ></Grid>
      </Grid>
    );
  };

  const countyPropTaxReserves = () => {
    return (
      <Grid container className={classes.loanGrid}>
        <Grid item lg={4} md={6}>
          {" "}
          <span className={classes.loanLabels}>County Prop Tax Reserves</span>
        </Grid>
        <Grid item container lg={5} md={3} className={classes.loanGridItem}>
          <NumericFormat
            className={classes.loanPercentTextfields}
            value={loanValues.propTaxReserveMths}
            onValueChange={(values) =>
              handleNumericValues(values, "propTaxReserveMths")
            }
            decimalScale={0}
            suffix={" mths"}
          />
        </Grid>
        <Grid item container lg={3} md={3} className={classes.loanGridItem}>
          <NumericFormat
            className={classes.loanBigTextfields}
            value={loanValues.propTaxReserveMths * loanValues.propTaxMo}
            decimalScale={2}
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            prefix={"$"}
          />
        </Grid>
      </Grid>
    );
  };

  const floodInsurancePremium = () => {
    return (
      <Grid container className={classes.loanGrid}>
        <Grid item lg={4} md={6}>
          {" "}
          <span className={classes.loanLabels}>Flood Insurance Premium</span>
        </Grid>
        <Grid item container lg={5} md={3} className={classes.loanGridItem}>
          <NumericFormat
            className={classes.loanPercentTextfields}
            value={loanValues.floodInsMo}
            onValueChange={(values) =>
              handleNumericValues(values, "floodInsMo")
            }
            decimalScale={2}
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            prefix={"$"}
          />
        </Grid>
        <Grid item container lg={3} md={3} className={classes.loanGridItem}>
          <NumericFormat
            className={classes.loanBigTextfields}
            value={loanValues.floodInsMo * 12}
            decimalScale={2}
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            prefix={"$"}
          />
        </Grid>
      </Grid>
    );
  };

  const floodInsuranceReserves = () => {
    return (
      <Grid container className={classes.loanGrid}>
        <Grid item lg={4} md={6}>
          {" "}
          <span className={classes.loanLabels}>Flood Insurance Reserve</span>
        </Grid>
        <Grid item container lg={5} md={3} className={classes.loanGridItem}>
          <NumericFormat
            className={classes.loanPercentTextfields}
            value={loanValues.floodInsReserveMths}
            onValueChange={(values) =>
              handleNumericValues(values, "floodInsReserveMths")
            }
            decimalScale={0}
            suffix={" mths"}
          />
        </Grid>
        <Grid item container lg={3} md={3} className={classes.loanGridItem}>
          <NumericFormat
            className={classes.loanBigTextfields}
            value={loanValues.floodInsReserveMths * loanValues.floodInsMo}
            decimalScale={2}
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            prefix={"$"}
          />
        </Grid>
      </Grid>
    );
  };

  const otherInsurancePremium = () => {
    return (
      <Grid container className={classes.loanGrid}>
        <Grid item lg={4} md={6}>
          {" "}
          <span className={classes.loanLabels}>Other Insurance Premium</span>
        </Grid>
        <Grid item container lg={5} md={3} className={classes.loanGridItem}>
          <NumericFormat
            className={classes.loanPercentTextfields}
            value={loanValues.otherInsMo}
            onValueChange={(values) =>
              handleNumericValues(values, "otherInsMo")
            }
            decimalScale={2}
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            prefix={"$"}
          />
        </Grid>
        <Grid item container lg={3} md={3} className={classes.loanGridItem}>
          <NumericFormat
            className={classes.loanBigTextfields}
            value={loanValues.otherInsMo * 12}
            decimalScale={2}
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            prefix={"$"}
          />
        </Grid>
      </Grid>
    );
  };

  const otherInsuranceReserves = () => {
    return (
      <Grid container className={classes.loanGrid}>
        <Grid item lg={4} md={6}>
          {" "}
          <span className={classes.loanLabels}>Other Insurance Reserve</span>
        </Grid>
        <Grid item container lg={5} md={3} className={classes.loanGridItem}>
          <NumericFormat
            className={classes.loanPercentTextfields}
            value={loanValues.otherInsReserveMths}
            onValueChange={(values) =>
              handleNumericValues(values, "otherInsReserveMths")
            }
            decimalScale={0}
            suffix={" mths"}
          />
        </Grid>
        <Grid item container lg={3} md={3} className={classes.loanGridItem}>
          <NumericFormat
            className={classes.loanBigTextfields}
            value={loanValues.otherInsReserveMths * loanValues.otherInsMo}
            decimalScale={2}
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            prefix={"$"}
          />
        </Grid>
      </Grid>
    );
  };

  const lenderTitleInsurance = () => {
    return (
      <Grid
        container
        className={classes.loanGrid}
        style={{ marginBottom: "2rem" }}
      >
        <Grid item container lg={8} md={6}>
          {" "}
          <span className={classes.loanLabels}>Lender Title Insurrance</span>
        </Grid>
        <Grid item container lg={4} md={6} className={classes.loanGridItem}>
          <NumericFormat
            className={classes.loanBigTextfields}
            value={loanValues.lenderTitleIns}
            onValueChange={(values) =>
              handleNumericValues(values, "lenderTitleIns")
            }
            decimalScale={2}
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            prefix={"$"}
          />
        </Grid>
      </Grid>
    );
  };

  const cityCountyStampsMortgage = () => {
    return (
      <Grid container className={classes.loanGrid}>
        <Grid item container lg={8} md={6}>
          {" "}
          <span className={classes.loanLabels}>
            City/ County/ Stamps on Mortgage
          </span>
        </Grid>
        <Grid item container lg={4} md={6} className={classes.loanGridItem}>
          <NumericFormat
            className={classes.loanBigTextfields}
            value={loanValues.mtgstamps}
            onValueChange={(values) => handleNumericValues(values, "mtgstamps")}
            decimalScale={2}
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            prefix={"$"}
          />
        </Grid>
      </Grid>
    );
  };

  const stateTaxStampsOnDeed = () => {
    return (
      <Grid container className={classes.loanGrid}>
        <Grid item container lg={8} md={6}>
          {" "}
          <span className={classes.loanLabels}>State Tax/ Stamps on Deed</span>
        </Grid>
        <Grid item container lg={4} md={6} className={classes.loanGridItem}>
          <NumericFormat
            className={classes.loanBigTextfields}
            value={loanValues.deedstamps}
            onValueChange={(values) =>
              handleNumericValues(values, "deedstamps")
            }
            decimalScale={2}
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            prefix={"$"}
          />
        </Grid>
      </Grid>
    );
  };

  const totalAmtPaid = () => {
    return (
      <Grid container className={classes.loanGrid}>
        <Grid item container lg={8} md={6}>
          <strong>TOTAL AMT PAID TO OTHERS ON YOUR BEHALF</strong>
        </Grid>
        <Grid item container lg={4} md={6} className={classes.loanGridItem}>
          <NumericFormat
            className={classes.loanBigTextfields}
            value={loanValues.totalAMTPaidToOthers}
            onValueChange={(values) =>
              handleNumericValues(values, "totalAMTPaidToOthers")
            }
            decimalScale={2}
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            prefix={"$"}
          />
        </Grid>
      </Grid>
    );
  };

  const totalPrepaid = () => {
    return (
      <Grid container className={classes.loanGrid}>
        <Grid item container lg={8} md={6}>
          <strong>TOTAL PREPAID FINANCE CHARGE</strong>
        </Grid>
        <Grid item container lg={4} md={6} className={classes.loanGridItem}>
          <NumericFormat
            className={classes.loanBigTextfields}
            value={loanValues.prepaidFinanceCharges}
            onValueChange={(values) =>
              handleNumericValues(values, "prepaidFinanceCharges")
            }
            decimalScale={2}
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            prefix={"$"}
          />
        </Grid>
      </Grid>
    );
  };

  const loanTerm = () => {
    return (
      <Grid container className={classes.loanGrid}>
        <Grid item lg={8} md={6}>
          {" "}
          <span className={classes.loanLabels}>Loan Term</span>
        </Grid>
        <Grid item container lg={4} md={6} className={classes.loanGridItem}>
          <NumericFormat
            className={
              !!formErrors.term && !areValidValues
                ? classes.loanPercentTextfieldsError
                : classes.loanPercentTextfields
            }
            value={loanValues.term}
            onValueChange={(values) => handleNumericValues(values, "term")}
            decimalScale={0}
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            required
          />
        </Grid>
      </Grid>
    );
  };

  const monthlyPmiPremium = () => {
    return (
      <Grid
        container
        className={classes.loanGrid}
        style={{
          display: loanValues.loanType === "Conventional" ? "flex" : "none",
        }}
      >
        <Grid item container lg={8} md={6}>
          {" "}
          <span className={classes.loanLabels}>Monthly PMI Premium</span>
        </Grid>
        <Grid item container lg={4} md={6} className={classes.loanGridItem}>
          <NumericFormat
            className={classes.loanBigTextfields}
            value={loanValues.mgtInsMonthly}
            onValueChange={(values) =>
              handleNumericValues(values, "mgtInsMonthly")
            }
            decimalScale={2}
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            prefix={"$"}
          />
        </Grid>
      </Grid>
    );
  };

  const totalProposedPayment = () => {
    return (
      <Grid container className={classes.loanGrid}>
        <Grid item lg={8} md={6}>
          {" "}
          <span className={classes.loanLabels}>
            Total Proposed Monthly Payment
          </span>
        </Grid>
        <Grid item container lg={4} md={6} className={classes.loanGridItem}>
          <NumericFormat
            className={
              !!formErrors.totalHousingExpenses && !areValidValues
                ? classes.loanPercentTextfieldsError
                : classes.loanPercentTextfields
            }
            value={loanValues.totalHousingExpenses}
            onValueChange={(values) =>
              handleNumericValues(values, "totalHousingExpenses")
            }
            decimalScale={2}
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            prefix={"$"}
            required
          />
        </Grid>
      </Grid>
    );
  };

  const salesContractPrice = () => {
    return (
      <Grid
        container
        className={classes.loanGrid}
        style={{ marginTop: "2rem" }}
      >
        <Grid item lg={8} md={6}>
          {" "}
          <span className={classes.loanLabels}>SALES CONTRACT PRICE</span>
        </Grid>
        <Grid item container lg={4} md={6} className={classes.loanGridItem}>
          <NumericFormat
            className={
              !!formErrors.purchasePrice && !areValidValues
                ? classes.loanPercentTextfieldsError
                : classes.loanPercentTextfields
            }
            value={loanValues.purchasePrice}
            onValueChange={(values) =>
              handleNumericValues(values, "purchasePrice")
            }
            decimalScale={2}
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            prefix={"$"}
            required
          />
        </Grid>
      </Grid>
    );
  };

  const otherCredits = () => {
    return (
      <Grid container className={classes.loanGrid}>
        <Grid
          style={{
            margin: "0.5rem",
          }}
        >
          <span
            style={{
              padding: "0.3rem 0.5rem",
              backgroundColor: "#282887",
              color: "white",
              borderRadius: "50%",
              marginRight: "0.5rem",
            }}
          >
            3
          </span>
          <strong>
            <span
              className={classes.loanLabels}
              style={{ fontSize: "16px", color: "#33469d" }}
            >
              Add third party credits to buyer
            </span>
          </strong>
        </Grid>
        <div style={{ marginBottom: "1rem" }}>
          <strong style={{ color: "#33469d" }}>
            <span className={classes.loanLabels}>
              This section should include realtor credits or DPA funds for
              programs WITHOUT a monthly payment. Seller credit should NOT be
              included.
            </span>
          </strong>
        </div>
        <Grid item lg={3} md={6}>
          <span className={classes.loanLabels}>M. Other Credits*</span>
        </Grid>
        <Grid item container lg={5} md={3} className={classes.loanGridItem}>
          <NumericFormat
            className={classes.loanPercentTextfields}
            value={loanValues.otherCreditsPerc}
            onValueChange={(values) =>
              handleNumericValues(values, "otherCreditsPerc")
            }
            decimalScale={3}
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            suffix={"%"}
          />

          <span className={classes.loanLabels}>or +</span>
          <NumericFormat
            className={classes.loanPercentTextfields}
            value={loanValues.otherCreditsAmt}
            onValueChange={(values) =>
              handleNumericValues(values, "otherCreditsAmt")
            }
            decimalScale={2}
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            prefix={"$"}
          />
        </Grid>
        <Grid item container lg={4} md={3} className={classes.loanGridItem}>
          <NumericFormat
            className={classes.loanBigTextfields}
            value={
              loanValues.otherCreditsAmt === 0
                ? (loanValues.loanAmount * loanValues.otherCreditsPerc) / 100
                : (loanValues.loanAmount * loanValues.otherCreditsPerc) / 100 +
                  loanValues.otherCreditsAmt
            }
            decimalScale={2}
            fixedDecimalScale
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            prefix={"$"}
          />
        </Grid>
      </Grid>
    );
  };

  const loanInformation = () => {
    return (
      <Paper variant="outlined" className={classes.paper}>
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          Loan Qualification Parameters
        </Typography>
        {loanType()}

        <Grid
          container
          direction={"row"}
          alignItems={"flex-start"}
          justifyContent={"space-evenly"}
        >
          <Grid
            style={{
              margin: "0 0 1rem 0",
            }}
            item
            lg={6}
            md={12}
          >
            <div
              style={{
                backgroundColor: "white",
                border: "1px solid",
                borderRadius: "10px",
                padding: "1rem",
                height: "100%",
                margin: "0 0.5rem",
              }}
            >
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                Complete the following fields from the Itemization of Amount
                Financed Document
              </Typography>
              {interestRateAndLtv()}
              {loanAmount()}
              <strong>OUR ORIGINATION SALES INCLUDE</strong>
              {loanOriginatorFee()}
              {brokerFee()}
              <strong>YOUR CREDIT OR CHARGE (POINTS)</strong>
              {originationCredit()}
              {loanDiscountPoints()}
              <strong>PREPAIDS</strong>
              {interestToFirstPayment()}
              {conventionalSinglePremiumPMI()}
              {fhaUpMipMortgageInsurancePremium()}
              {vaFundingFee()}
              {mortgageInsuranceReserves()}
              {hazardInsurancePremiumMonthly()}
              {hazardInsuranceReserves()}
              {countyPropTaxMonthly()}
              {countyPropTaxReserves()}
              {floodInsurancePremium()}
              {floodInsuranceReserves()}
              {otherInsurancePremium()}
              {otherInsuranceReserves()}
            </div>
          </Grid>

          <Grid
            style={{
              margin: "0 0 1rem 0",
            }}
            item
            lg={6}
            md={12}
          >
            <div
              style={{
                backgroundColor: "white",
                border: "1px solid",
                borderRadius: "10px",
                padding: "1rem",
                height: "100%",
                margin: "0 0.5rem",
              }}
            >
              <strong>SETTLEMENT OR CLOSING COSTS</strong>
              {lenderTitleInsurance()}
              <strong>TRANSFER TAXES INCLUDES</strong>
              {cityCountyStampsMortgage()}
              {stateTaxStampsOnDeed()}
              <hr style={{ marginTop: "1.5rem" }} />
              {totalAmtPaid()}
              <hr />
              {totalPrepaid()}
              <hr style={{ marginBottom: "1.5rem" }} />
              {loanTerm()}
              {monthlyPmiPremium()}
              {totalProposedPayment()}
              {salesContractPrice()}
              {otherCredits()}
            </div>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  return <div>{loanInformation()}</div>;
}
