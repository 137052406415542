import React, { useState, useEffect, useContext } from "react";
import CompanySetup from "./CompanySetup";
import MloSetup from "./MloSetup";
import TaxRulesSetup from "./TaxRulesSetup";
import OfferLetterSetup from "./OfferLetterSetup";
import CommunicationsSetup from "./CommunicationsSetup";
import { formValidation } from "../../core/validationFunctions";
import makeStyles from "@mui/styles/makeStyles";
import {
  Grid,
  Stepper,
  StepButton,
  Step,
  Backdrop,
  Paper,
  Typography,
  Divider,
  CircularProgress,
  MobileStepper,
  Hidden,
  Button,
} from "@mui/material";
import { fetchApiData, postApiData, postApiPdf } from "../../core/FetchApiData";
import { ApiPaths } from "../../core/PathConstants";
import AlertMessage from "../../core/AlertMessage";
import _ from "lodash";
import CalculationRulesSetup from "./CalculationRulesSetup";
import CondosSetup from "./Condos/CondosSetup";
import { Loading } from "../../core/Loading";
import { CurrentUserContext } from "../../core/hooks/CurrentUserContext";
import LoadingButton from "@mui/lab/LoadingButton";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  cardroot: {
    minHeight: 200,
  },
  paper: {
    padding: theme.spacing(2),
    //textAlign: 'start',
    color: theme.palette.text.secondary,
  },
  media: {
    height: 140,
  },
}));

// Steps labels
const labels = [
  "Company",
  "MLO Settings",
  "Borrower Settings",
  "Offer/ Qualification Letter",
  "Property Tax Rules",
  //  "Calculation Rules",
  // "Condos",
];

const totalSteps = labels.length;

// Initial values
const initialValues = {
  //Company
  companyId: "",
  companyName: "",
  street1: "",
  street2: "",
  city: "",
  state: "",
  zipCode: "",
  phoneNumber: "",
  faxNumber: "",
  nmls: "",
  webURL: "",
  letterContent: "",

  //MLO
  canMloUploadImage: true,
  canMloChangeMobilePhone: true,
  canMloChangeOfficePhone: true,
  canMloChangeTitle: true,
  canMloChangePersonalWebsite: true,
  canMloChangeSocialMediaLinks: true,
  allowBuydownOption: true,
  allowSMSCommuncation: true,
  canEditDefaultApprovalLetter: true,
  //Communications
  allowEmailOffers: false,
  allowShareApplication: false,
  allowInviteOthersToProfile: false,

  //Calculation
  overridePrepaidIntDays: false,
  prepaidIntDays: 0,
  allowBuydown: false,
  buydownReductionRate: 0.0,
  buydownDiscPoints: 0,
  hasBuydownLimit: false,
  buydownLimitPerc: 0.0,

  states: [],
  taxRules: [],
};

// Validations of some fields
const fieldsValidation = {
  companyName: {
    error: "",
    validate: "",
    minLength: 2,
    maxLength: 150,
  },
  street1: {
    error: "",
    validate: "",
    minLength: 0,
    maxLength: 150,
  },
  street2: {
    error: "",
    validate: "",
    minLength: 0,
    maxLength: 150,
  },
  city: {
    error: "",
    validate: "text",
    minLength: 0,
    maxLength: 150,
  },
  state: {
    error: "",
    validate: "text",
    minLength: 0,
    maxLength: 2,
  },
  zipCode: {
    error: "",
    validate: "zip",
    minLength: 0,
    maxLength: 5,
  },
  phoneNumber: {
    error: "",
    validate: "phone",
    minLength: 0,
    maxLength: 20,
  },
  nmls: {
    error: "",
    validate: "number",
    minLength: 0,
    maxLength: 12,
  },
  webURL: {
    error: "",
    validate: "website",
    minLength: 0,
    maxLength: 150,
  },
  prepaidIntDays: {
    error: "",
    validate: "number",
  },
  buydownReductionRate: {
    error: "",
    validate: "number",
  },
  buydownDiscPoints: {
    error: "",
    validate: "number",
  },
  buydownLimitPerc: {
    error: "",
    validate: "number",
  },
};

const Company = () => {
  const { user } = useContext(CurrentUserContext);

  const [currentStep, setActiveStep] = useState(0);

  const [statesData, setStatesData] = useState([]);
  const [lendingStates, setLendingStates] = useState([]);

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [errorLoading, setErrorLoading] = useState(false);
  const classes = useStyles();
  const [formValuesLoaded, setFormValuesLoaded] = useState(false);
  const [offerLetterPdf, setOfferLetterPdf] = useState();
  const [openAlertOnStatusPending, setOpenAlertOnStatusPending] =
    useState(false);
  const [alertState, setAlertState] = useState({ show: false });

  const [defaultData, setDefaultData] = useState({});
  const [isModified, setIsModified] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  // Handle input fields changes, updating formValues properties values
  const handleChange = (e) => {
    const name = e.target.name;
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    if (
      name === "prepaidIntDays" ||
      name === "buydownReductionRate" ||
      name === "buydownDiscPoints" ||
      name === "buydownLimitPerc"
    ) {
      value = Number(value);
    }

    // Set values
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name in fieldsValidation) {
      // Set errors
      const error = formValidation(name, value, fieldsValidation) || "";

      setFormErrors((prev) => ({
        ...prev,
        [name]: error,
      }));
    }
  };

  // Update input in Letter Qualification Editor into Letter Content property of FormValues
  const handleOnEditorChange = (content) => {
    setFormValues({ ...formValues, letterContent: content });
  };

  // Clear Letter Qualification Editor and Letter Content property of FormValues
  const clearEditor = () => {
    setFormValues({ ...formValues, letterContent: "" });
  };

  // Handle states checkboxes check uncheck events, updating states array property in FormValues state ,
  // case -1 for select all, case -2 uncheck all, default for managing individual checkboxes check uncheck

  const handleStatesCheckBox = async (item) => {
    switch (item) {
      case "-1": {
        const arrayS = [];
        const statesArray = [];
        for (let i = 0; i < statesData.length; i++) {
          const id = statesData[i].stateCode.toString();
          statesArray.push(statesData[i]);
          arrayS.push(id);
        }
        setLendingStates(statesArray);
        setFormValues({ ...formValues, states: arrayS });
        break;
      }
      case "-2": {
        setLendingStates([]);
        setFormValues({ ...formValues, states: [] });
        break;
      }
      default: {
        if (formValues.states.indexOf(item) !== -1) {
          const afterDelete = formValues.states.filter((s) => s != item);
          const lending = lendingStates.filter((s) => s.stateCode != item);
          setLendingStates(lending);
          setFormValues({
            ...formValues,
            states: afterDelete,
          });
        } else {
          const tempState = statesData.filter((o) => o.stateCode == item);
          setLendingStates([...lendingStates.concat(tempState)]);
          setFormValues({
            ...formValues,
            states: [...formValues.states.concat(item)],
          });
        }
        break;
      }
    }
  };

  const updateAllTaxRules = (value, minRepTax, offerPricePercent) => {
    const tempArray = [];
    for (let i = 0; i < lendingStates.length; i++) {
      const state = lendingStates[i];
      if (state.counties != null) {
        for (let j = 0; j < state.counties.length; j++) {
          const county = state.counties[j];
          const tax = {
            companyId: formValues.companyId,
            countyId: county.countyId,
            actualReportedTaxes: value,
            reportedTaxPercent: !value ? minRepTax : 0,
            offerPricePercent: !value ? offerPricePercent : 0,
            stateCode: county.stateCode,
          };
          tempArray.push(tax);
        }
      }
    }
    setFormValues({
      ...formValues,
      actualReportedTaxes: value,
      reportedTaxPercent: !value ? minRepTax : formValues.reportedTaxPercent,
      offerPricePercent: !value
        ? offerPricePercent
        : formValues.offerPricePercent,
      taxRules: tempArray,
    });
  };

  const updateStateTaxRules = (taxRulesState, state) => {
    const taxRulesTemp = formValues.taxRules.filter(
      (t) => t.stateCode != state.stateCode
    );

    setFormValues({
      ...formValues,
      taxRules: [...taxRulesTemp, ...taxRulesState],
    });
  };

  const isValid = () => {
    const allEmpty = Object.entries(formErrors).every(
      ([key, value]) => value === ""
    );
    return allEmpty;
  };

  const onFormValues = (data) => {
    setFormValues(data);
    setFormValuesLoaded(true);
  };

  useEffect(() => {
    // Populates states list
    fetchApiData(ApiPaths.States, setStatesData, null, null, user.token);
    //Get Company data
    fetchApiData(
      ApiPaths.Company,
      onFormValues,
      setIsLoading,
      onError,
      user.token
    );
  }, []);

  useEffect(() => {
    if (formValuesLoaded === true) {
      if (formValues.status === 2) {
        setOpenAlertOnStatusPending(true);
      }

      setDefaultData(formValues);

      if (statesData.length > 0) {
        let addLendingStates = [];
        formValues.states.forEach((item) => {
          const tempState = statesData.filter((o) => o.stateCode == item);
          addLendingStates = [...addLendingStates.concat(tempState)];
        });
        setLendingStates(addLendingStates);
      }
    }
  }, [formValuesLoaded, statesData]);

  useEffect(() => {
    if (formValuesLoaded === true) {
      if (
        formValues.states.length !== defaultData.states.length &&
        formValues.taxRules.length !== defaultData.taxRules.length
      ) {
        setIsModified(true);
      } else {
        if (
          _.isEqualWith(defaultData, formValues, (value1, value2, key) => {
            return key === "states" || key === "taxRules" ? true : undefined;
          })
        ) {
          const statesInCommon = _.intersection(
            defaultData.states,
            formValues.states
          );

          if (statesInCommon.length !== defaultData.states.length) {
            setIsModified(true);
          } else {
            const taxRulesInCommon = _.intersectionWith(
              defaultData.taxRules,
              formValues.taxRules,
              _.isEqual
            );
            if (taxRulesInCommon.length !== defaultData.taxRules.length) {
              setIsModified(true);
            } else {
              setIsModified(false);
            }
          }
        } else {
          setIsModified(true);
        }
      }
    }
  }, [formValues]);

  const onSaved = () => {
    //TODO:disable all buttons, show saving status.
    setAlertState({
      show: true,
      message: "Changes successfully saved!",
      type: "success",
    });
    setIsSaving(false);
    setDefaultData(formValues);
    setIsModified(false);
  };

  const onError = (status, msg) => {
    setErrorLoading(true);
    setAlertState({
      show: true,
      message: "Error retrieving or saving company",
    });
  };

  const onValidationError = (status, msg) => {
    setAlertState({
      show: true,
      message: "Please fix the fields with errors",
    });
  };

  async function handleSubmit() {
    const tempRules = formValues.taxRules.filter((x) => x.countyId != "");
    /* for (var i = 0; i < tempRules.length; i++) {
             tempRules[i].county = null;
         }*/
    setIsSaving(true);
    setFormValues({
      ...formValues,
      taxRules: tempRules,
    });
    postApiData(
      ApiPaths.Company,
      "PUT",
      formValues,
      onSaved,
      onError,
      user.token
    );
  }

  async function fetchPdf(content) {
    await postApiPdf(
      ApiPaths.PreviewPdf,
      "POST",
      content,
      null,
      null,
      user.token
    );
  }

  // Returns component depending of step
  function showStep(step) {
    switch (step) {
      // Company Setup
      case 0:
        return (
          <CompanySetup
            handleChange={handleChange}
            statesList={statesData}
            values={formValues}
            formErrors={formErrors}
            handleCheckBox={handleStatesCheckBox}
          />
        );
      // MLO Setup
      case 1:
        return <MloSetup handleChange={handleChange} values={formValues} />;
      // Communication Setup
      case 2:
        return (
          <CommunicationsSetup
            handleChange={handleChange}
            values={formValues}
          />
        );
      // Offer Letter Setup
      case 3:
        return (
          <OfferLetterSetup
            clearEditor={clearEditor}
            handleOnEditorChange={handleOnEditorChange}
            values={formValues}
            fetchPdf={fetchPdf}
          />
        );
      // Tax Rules Setup
      case 4:
        return (
          <TaxRulesSetup
            values={formValues}
            lendingStates={lendingStates}
            updateStateTaxRules={updateStateTaxRules}
            updateAllTaxRules={updateAllTaxRules}
          />
        );
      // Calculation Setup
      case 5:
        return (
          <CalculationRulesSetup
            values={formValues}
            handleChange={handleChange}
          />
        );
      case 6:
        return <CondosSetup values={formValues} handleChange={handleChange} />;
      default:
        return <></>;
    }
  }

  // Change step position value
  const handleNext = () => setActiveStep((prev) => prev + 1);
  const handleBack = () => setActiveStep((prev) => prev - 1);

  const handleStep = (step) => () => {
    if (step === 4) {
      setTaxRulesForSelected();
    }
    setActiveStep(step);
  };

  const setTaxRulesForSelected = () => {
    if (lendingStates.length > 0) {
      let statesCodes = lendingStates.map((s) => s.stateCode);

      let taxRulesStatesCodes = formValues.taxRules.map((x) => x.stateCode);
      let uniqueTaxRulesStatesCodes = Array.from(new Set(taxRulesStatesCodes));

      const statesTaxRulesToDelete = uniqueTaxRulesStatesCodes.filter(
        (s) => !statesCodes.includes(s)
      );
      const statesTaxRulesToCreate = statesCodes.filter(
        (s) => !uniqueTaxRulesStatesCodes.includes(s)
      );
      const newStatesChecked = statesData.filter((x) =>
        statesTaxRulesToCreate.includes(x.stateCode)
      );
      let tempArray = [];
      for (let i = 0; i < newStatesChecked.length; i++) {
        const state = newStatesChecked[i];
        if (state.counties != null) {
          for (let j = 0; j < state.counties.length; j++) {
            const county = state.counties[j];
            const tax = {
              companyId: formValues.companyId,
              countyId: county.countyId,
              stateCode: county.stateCode,
              actualReportedTaxes: formValues.actualReportedTaxes,
              reportedTaxPercent: formValues.reportedTaxPercent,
              offerPricePercent: formValues.offerPricePercent,
            };
            tempArray.push(tax);
          }
        }
      }

      let updatedTaxRules = [...formValues.taxRules, ...tempArray];
      let filteredUpdatedTaxRules = updatedTaxRules.filter(
        (t) => !statesTaxRulesToDelete.includes(t.stateCode)
      );
      setFormValues({ ...formValues, taxRules: filteredUpdatedTaxRules });
    } else {
      const emptyArray = [];
      setFormValues({ ...formValues, taxRules: emptyArray });
    }
  };

  const renderStepper = () => {
    return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          <AlertMessage alert={alertState} />
          <Loading isLoading={isSaving} />
          <Grid item xs={12}>
            <Typography variant="h5">Company Settings</Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper variant="outlined" square className={classes.paper}>
              <Hidden smDown>
                <Stepper
                  my={5}
                  activeStep={currentStep}
                  orientation="horizontal"
                  nonLinear
                  alternativeLabel
                >
                  {labels.map((label, index) => (
                    <Step key={label}>
                      <StepButton onClick={handleStep(index)}>
                        {label}
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
              </Hidden>
              <Hidden smUp>
                <MobileStepper
                  style={{ flexGrow: 1, maxWidth: 600 }}
                  steps={labels.length}
                  position="static"
                  variant="dots"
                  activeStep={currentStep}
                  nextButton={<div></div>}
                  backButton={<div></div>}
                />
              </Hidden>
              <Divider variant="middle" style={{ marginTop: "10px" }} />
              <form>{showStep(currentStep)}</form>
              <Grid container style={{ margin: "10px 10px 5px 10px" }}>
                <Grid container item xs={6}>
                  <Button
                    style={{ margin: "0 10px 10px 10px" }}
                    variant="contained"
                    color="primary"
                    disabled={currentStep - 1 < 0}
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                  <Button
                    style={{ margin: "0 0 10px 10px" }}
                    variant="contained"
                    color="primary"
                    disabled={currentStep + 1 >= totalSteps}
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                </Grid>
                <Grid container item xs={6} justifyContent="flex-end">
                  <LoadingButton
                    style={{ margin: "0 20px 10px 10px" }}
                    variant="contained"
                    color="primary"
                    disabled={!isValid}
                    onClick={() =>
                      isValid() ? handleSubmit() : onValidationError()
                    }
                    loading={isSaving}
                  >
                    Save
                  </LoadingButton>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        {/*<Hidden xsDown>*/}
        {/*    <AlertMessage openAlert={openAlertOnStatusPending} alertMessage='Company Pending Approval' autoHideDuration={null} alertType='warning' anchorOrigin={{ vertical: 'top', horizontal: 'center' }} style={{ width: '100%', position: 'fixed', display: 'block', top: '59px', zIndex: 200 }} styleMessage={{ paddingLeft: '260px' }}></AlertMessage>*/}
        {/*</Hidden>*/}
        {/*<Hidden smUp>*/}
        {/*    <AlertMessage openAlert={openAlertOnStatusPending} alertMessage='Company Pending Approval' autoHideDuration={null} alertType='warning' anchorOrigin={{ vertical: 'top', horizontal: 'center' }} style={{ width: '100%', position: 'fixed', display: 'block', top: '53px', zIndex: 200, left: 0 }}></AlertMessage>*/}
        {/*</Hidden>*/}
      </div>
    );
  };

  if (isLoading)
    return (
      <h4>
        <em>Loading...</em>
      </h4>
    );
  else if (errorLoading)
    return (
      <h4>
        <em>
          There was a data error or you don't have permission to view this page
        </em>
      </h4>
    );
  else return renderStepper();
};

export default Company;
