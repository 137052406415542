import {
  Box,
  Button,
  Backdrop,
  CircularProgress,
  Typography,
  TextField,
  FormHelperText,
  Grid,
  Stack,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import React, { useEffect, useContext } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { fetchApiData, postApiData } from "../core/FetchApiData";
import { ApiPaths, AppRoutes } from "../core/PathConstants";
import "../Layout.css";
import { useLocation } from "react-router-dom";
import { CurrentUserContext } from "../core/hooks/CurrentUserContext";

export default function Login() {
  const { onChangeUser } = useContext(CurrentUserContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const history = useHistory();
  let source = new URLSearchParams(location.search).get("source");

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSuccessLogin = (u) => {
    setIsLoading(false);
    if (u.hasConfirmedOTP) {
      onChangeUser(u);
      if (u?.mustChangePassword) history.push(AppRoutes.ChangeFirstPassword);
      else history.push(AppRoutes.Home);
    } else {
      history.push(
        `${AppRoutes.VerifyOTP}?id=${u.userId}&date=${u.otpExpirationDate}`
      );
    }
  };

  const onError = (status, msg) => {
    setIsLoading(false);
    if (status !== 500) setErrorMessage(msg);
    else setErrorMessage("Error loging in! Please try again later.");
  };

  const onChangePass = (evt) => {
    let value = evt.target.value;
    setPassword(value);
  };

  const onLogin = (evt) => {
    var res = evt.currentTarget.form.reportValidity();
    if (res) {
      setErrorMessage("");
      let loginData = {
        email,
        password,
        role: "Partner",
      };
      setIsLoading(true);
      postApiData(ApiPaths.Login, "post", loginData, onSuccessLogin, onError);
    }
  };

  const onChangeEmail = (evt) => {
    let value = evt.target.value;
    setEmail(value);
  };

  useEffect(() => {}, []);

  return (
    <Grid container style={{ position: "relative" }}>
      <Grid
        item
        component="form"
        sx={{
          mt: 5,
          mx: "auto",
          height: "full",
          "& .MuiTextField-root": { m: 1, width: "35ch" },
          "& .MuiButton-root": { m: 1 },
          flexGrow: 1,
        }}
        autoComplete="off"
      >
        <Grid
          container
          display="flex"
          direction="column"
          m={2}
          justifyContent="center"
        >
          <Stack sx={{ alignItems: "center" }}>
            <Typography variant="h6" component="div">
              <img
                alt="Padzilly"
                src="images/logo-navy.svg"
                width="150"
                height="50"
              />
            </Typography>
            <Typography
              sx={{
                fontSize: "26",
                fontFamily: "American Captain Regular",
                color: "#283C8F",
              }}
              variant="h4"
              textAlign="center"
              component="div"
            >
              For Partners
            </Typography>
          </Stack>

          <Stack
            sx={{
              alignItems: "center",
              marginTop: "2rem",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
              }}
              variant="h5"
              textAlign="center"
              component="div"
            >
              Sign in to your account
            </Typography>
          </Stack>
        </Grid>

        <Grid
          container
          m={2}
          justifyContent="center"
          style={{ marginTop: "1.8rem" }}
        >
          <Grid>
            <Grid item xs={12}>
              <TextField
                id="email"
                size="small"
                required
                type="email"
                label="Email"
                variant="outlined"
                value={email}
                onChange={onChangeEmail}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="pwd"
                size="small"
                value={password}
                required
                type={showPassword ? "text" : "password"}
                label="Password"
                variant="outlined"
                onChange={onChangePass}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="flex-start"
              alignItems="center"
              direction="row"
            >
              <Link
                to={`${AppRoutes.ForgotPasswprd}`}
                style={{ marginLeft: "1rem" }}
              >
                <Typography variant={"caption"} sx={{ fontSize: "0.85rem" }}>
                  Forgot Password?
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={12} container direction="row">
              <Button
                variant="contained"
                onClick={onLogin}
                style={{ width: "100%" }}
              >
                SIGN IN
              </Button>
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
            >
              <FormHelperText
                sx={{ color: "black", m: 2, fontSize: "12px", margin: "8px" }}
              >
                By logging in, I accept&nbsp;
                <a
                  href="https://padzilly.com/terms.html"
                  style={{ marginLeft: "0.1rem" }}
                >
                  Padzilly Terms of Use.
                </a>
              </FormHelperText>
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
            >
              <FormHelperText
                sx={{
                  color: "black",
                  m: 2,
                  fontSize: "1.1rem",
                  margin: "8px",
                }}
              >
                Don't have an account?
                <Link
                  to={`${AppRoutes.SignUp}`}
                  style={{ marginLeft: "0.5rem" }}
                >
                  <Typography variant={"caption"} sx={{ fontSize: "1.1rem" }}>
                    Register now
                  </Typography>
                </Link>
              </FormHelperText>
            </Grid>
          </Grid>
          {source != null && (
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              direction="column"
              style={{ marginTop: "2rem", borderRadius: "6px" }}
            >
              <Grid style={{ backgroundColor: "#b7c8e5cc" }}>
                <FormHelperText
                  sx={{
                    color: "black",
                    m: 2,
                    marginBottom: 0,
                    fontSize: "16px",
                  }}
                >
                  We have sent you a Password Reset Email.
                </FormHelperText>
                <FormHelperText
                  sx={{ color: "black", m: 2, marginTop: 0, fontSize: "16px" }}
                >
                  Please check your inbox and spam folder.
                </FormHelperText>
              </Grid>
            </Grid>
          )}
          {errorMessage && (
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
              direction="row"
              style={{ marginTop: "2rem", borderRadius: "6px" }}
            >
              <Grid style={{ backgroundColor: "#f97b7bcc" }}>
                <FormHelperText sx={{ color: "black", m: 2, fontSize: "16px" }}>
                  {errorMessage}
                </FormHelperText>
              </Grid>
            </Grid>
          )}
        </Grid>

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
      <Grid container item xs={12} justifyContent="center" alignItems="center">
        {" "}
        <FormHelperText
          sx={{ color: "black", m: 2, fontSize: "12px", margin: "8px" }}
        >
          <a href="https://padzilly.com/privacy.html">
            Read our Privacy Policy.
          </a>
        </FormHelperText>
      </Grid>
    </Grid>
  );
}
