import React from "react";
import {
  Button,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

const CommunicationsSetup = ({
  handleBack,
  handleChange,
  handleNext,
  values: {
    allowEmailOffers,
    allowShareApplication,
    allowInviteOthersToProfile,
  },
}) => {
  return (
    <div>
      <Grid container spacing={3} style={{ margin: "5px 0 5px" }}>
        <Grid item xs={12}>
          <Typography style={{ margin: "10px 5px 0 0px" }} variant="h6">
            <strong>Borrower rules</strong>
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="column" style={{ margin: "0 10px 5px 10px" }}>
        <Grid item xs={12} style={{ display: "none" }}>
          <FormControlLabel
            style={{ margin: "0 3px 0 0px" }}
            control={
              <Checkbox
                name="allowEmailOffers"
                onChange={handleChange}
                checked={allowEmailOffers}
                color="primary"
              />
            }
            label="Can email offer letters directly (recommended)"
          />
        </Grid>
        <Grid item xs={12} style={{ paddingTop: "0" }}>
          <FormControlLabel
            style={{ margin: "0 3px 0 0px" }}
            control={
              <Checkbox
                name="allowShareApplication"
                onChange={handleChange}
                checked={allowShareApplication}
                color="primary"
              />
            }
            label="Can delete Realtors from their profile"
          />
        </Grid>
        <Grid item xs={12} style={{ paddingTop: "0" }}>
          <FormControlLabel
            style={{ margin: "0 3px 0 0px" }}
            control={
              <Checkbox
                name="allowInviteOthersToProfile"
                onChange={handleChange}
                checked={allowInviteOthersToProfile}
                color="primary"
              />
            }
            label="Can invite others into their profile (Including Realtors)"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default CommunicationsSetup;
