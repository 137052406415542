import React, { useState, useEffect, useContext } from "react";
import {
  Typography,
  Grid,
  Stack,
  TextField,
  Button,
  Backdrop,
  CircularProgress,
  InputAdornment,
  IconButton,
  Box,
  FormHelperText,
} from "@mui/material";
import { ApiPaths, AppRoutes } from "../core/PathConstants";
import { TextFieldValidation } from "../core/TextFieldValidation";
import { postApiData } from "../core/FetchApiData";
import { Link, useHistory } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { CurrentUserContext } from "../core/hooks/CurrentUserContext";

export default function ChangePassword() {
  const [isValidForm, setIsValidForm] = useState({});
  const [formValues, setFormValues] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const validateForm = () => {
    for (let item in isValidForm) {
      if (!isValidForm[item]) return false;
    }
    return true;
  };

  const onSaveError = (status, msg) => {
    setErrorMessage(msg);
    setIsLoading(false);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onComplete = (u) => {
    setIsLoading(false);
    localStorage.removeItem("fgt-pass-email");
    history.push(AppRoutes.Home);
  };

  const onSave = (evt) => {
    var res = evt.currentTarget.form.reportValidity();
    if (res) {
      if (validateForm()) {
        setErrorMessage("");
        setIsLoading(true);
        postApiData(
          ApiPaths.ChangeResetPwd,
          "POST",
          {
            password: formValues.newPassword,
            email: localStorage.getItem("fgt-pass-email"),
          },
          onComplete,
          onSaveError
        );
      } else setErrorMessage("Please correct the field values before saving");
    }
  };

  return (
    <Box
      component="form"
      sx={{
        mt: 5,
        mx: "auto",
        "& .MuiTextField-root": { m: 1, width: "35ch" },
        "& .MuiButton-root": { m: 1 },
      }}
      autoComplete="off"
    >
      <Grid
        container
        display="flex"
        direction="column"
        m={2}
        justifyContent="center"
      >
        <Stack sx={{ alignItems: "center" }}>
          <Typography variant="h6" component="div">
            <img
              alt="Padzilly"
              src="images/logo-navy.svg"
              width="150"
              height="50"
            />
          </Typography>
          <Typography
            sx={{
              fontSize: "26",
              fontFamily: "American Captain Regular",
              color: "#283C8F",
            }}
            variant="h4"
            textAlign="center"
            component="div"
          >
            For Partners
          </Typography>
        </Stack>
        <Stack
          sx={{
            alignItems: "center",
            marginTop: "2rem",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
            }}
            variant="h5"
            textAlign="center"
            component="div"
          >
            Please change your password
          </Typography>
        </Stack>
      </Grid>

      <Grid
        container
        m={2}
        justifyContent="center"
        style={{ marginTop: "1.8rem" }}
      >
        <Grid>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <TextFieldValidation
                style={{ margin: "10px 10px 10px 5px" }}
                variant="outlined"
                fullWidth
                size="small"
                label="New Password"
                name="newPassword"
                type={showNewPassword ? "text" : "password"}
                setFormValues={setFormValues}
                formValues={formValues}
                isValidForm={isValidForm}
                setIsValidForm={setIsValidForm}
                validate="password"
                minLength={8}
                maxLength={20}
                required
                compare="confirmPassword"
                compareError="New Password should have the same value as Confirm Password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowNewPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldValidation
                style={{ margin: "10px 10px 10px 5px" }}
                variant="outlined"
                fullWidth
                size="small"
                label="Confirm New Password"
                name="confirmPassword"
                type={showConfirmPassword ? "text" : "password"}
                setFormValues={setFormValues}
                formValues={formValues}
                isValidForm={isValidForm}
                setIsValidForm={setIsValidForm}
                required
                minLength={8}
                maxLength={20}
                compare="newPassword"
                compareError="New Password should have the same value as Confirm Password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              container
              justifyContent="flex-end"
              style={{ marginTop: "1.5rem" }}
            >
              <Button
                style={{ marginTop: 10 }}
                variant="contained"
                color="primary"
                onClick={onSave}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {errorMessage && (
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            alignItems="center"
            direction="row"
            style={{ marginTop: "2rem", borderRadius: "6px" }}
          >
            <Grid style={{ backgroundColor: "#f97b7bcc" }}>
              <FormHelperText sx={{ color: "black", m: 2, fontSize: "16px" }}>
                {errorMessage}
              </FormHelperText>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
