import { React, useState } from "react";
import {
  Grid,
  Typography,
  Checkbox,
  TextField,
  List,
  ListItem,
  FormControl,
  InputAdornment,
  Select,
  MenuItem,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  current: {
    fontWeight: 700,
    fontSize: "14px",
    color: theme.palette.grey[700],
  },
  checkboxText: {
    margin: "0px",
    fontFamily: "Roboto, Helvetica, Arial, sans- serif",
    fontWeight: 400,
    fontSsize: "1rem",
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
  },
}));

const percentLabels = [
  { label: "0 %", value: 0 },
  { label: "1/2 %", value: 0.5 },
  { label: "1/4 %", value: 0.25 },
  { label: "1/6 %", value: 0.167 },
  { label: "1/8 %", value: 0.125 },
];

const CalculationRulesSetup = ({
  handleChange,
  values: {
    overridePrepaidIntDays,
    prepaidIntDays,
    allowBuydown,
    buydownReductionRate,
    buydownDiscPoints,
    hasBuydownLimit,
    buydownLimitPerc,
  },
}) => {
  const classes = useStyles();

  return (
    <div>
      <Grid container spacing={3} style={{ margin: "5px 0 5px" }}>
        <Grid item xs={12}>
          <Typography variant="h5">
            <strong>Calculation Rules Setup</strong>
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ margin: "0 0 5px" }}>
        <Grid item xs={12} style={{ paddingTop: "10px" }}>
          <Typography style={{ margin: "0 5px 0 0px" }} variant="h6">
            <strong>Prepaid Interest</strong>
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="column" style={{ margin: "0 10px 5px 10px" }}>
        <Grid item xs={12} md={6}>
          <List>
            <ListItemButton alignItems="center">
              <Checkbox
                name="overridePrepaidIntDays"
                checked={overridePrepaidIntDays}
                onChange={handleChange}
                color="primary"
              />
              <h4 className={classes.checkboxText}>
                Override the days of interest the MLO used for approval to
              </h4>
              <TextField
                style={{ margin: "0 5px 0 5px", width: "50px" }}
                inputProps={{
                  style: {
                    padding: 5,
                  },
                  min: "0",
                }}
                onChange={handleChange}
                name="prepaidIntDays"
                value={prepaidIntDays}
                variant="outlined"
                padding="2px"
                size="small"
                type="number"
                disabled={!overridePrepaidIntDays}
              />
              <h4 className={classes.checkboxText}>days</h4>
            </ListItemButton>
          </List>
        </Grid>
      </Grid>

      <Grid container spacing={3} style={{ margin: "0 0 5px" }}>
        <Grid item xs={12} style={{ paddingTop: "10px" }}>
          <Typography style={{ margin: "0 5px 0 0px" }} variant="h6">
            <strong>Buydown Rules</strong>
          </Typography>
        </Grid>
      </Grid>

      <Grid container direction="column" style={{ margin: "0 10px 5px 10px" }}>
        <List>
          <ListItemButton alignItems="center">
            <Checkbox
              name="allowBuydown"
              checked={allowBuydown}
              onChange={handleChange}
              color="primary"
            />
            <h4 className={classes.checkboxText}>
              Allow buydowns in calculations to lower the payment in
              qualification?
            </h4>
          </ListItemButton>
          <ListItemButton alignItems="center">
            <h4
              style={{ marginLeft: "50px", fontWeight: 600 }}
              className={classes.checkboxText}
            >
              General Buydown Formula
            </h4>
          </ListItemButton>
          <ListItemButton alignItems="center">
            <h4 style={{ marginLeft: "50px" }} className={classes.checkboxText}>
              For a rate reduction of
            </h4>

            <Select
              name="buydownReductionRate"
              onChange={handleChange}
              value={buydownReductionRate}
              style={{ margin: "0 5px 0 5px", width: "100px" }}
              disabled={!allowBuydown}
              SelectDisplayProps={{
                style: {
                  paddingTop: 5,
                  paddingBottom: 5,
                },
              }}
            >
              {percentLabels.map((name) => (
                <MenuItem key={name.value} value={name.value}>
                  {name.label}
                </MenuItem>
              ))}
            </Select>

            <h4 className={classes.checkboxText}>add</h4>
            <TextField
              style={{ margin: "0 5px 0 5px", width: "75px" }}
              inputProps={{
                style: {
                  padding: 5,
                },
                min: "0",
                step: "5",
              }}
              onChange={handleChange}
              variant="outlined"
              padding="2px"
              size="small"
              disabled={!allowBuydown}
              value={buydownDiscPoints}
              name="buydownDiscPoints"
              type="number"
            />

            <h4 className={classes.checkboxText}>bps to discount points</h4>
          </ListItemButton>
          <ListItemButton alignItems="center">
            <Checkbox
              name="hasBuydownLimit"
              checked={hasBuydownLimit}
              onChange={handleChange}
              color="primary"
            />
            <h4 className={classes.checkboxText}>Limit buydowns to</h4>
            <TextField
              style={{ margin: "0 5px 0 5px", width: "50px" }}
              inputProps={{
                style: {
                  padding: 5,
                },
                min: "0",
                max: ".9",
                step: "0.1",
              }}
              variant="outlined"
              padding="2px"
              size="small"
              onChange={handleChange}
              disabled={!hasBuydownLimit}
              value={buydownLimitPerc}
              name="buydownLimitPerc"
              type="number"
            />
            <h4 className={classes.checkboxText}>%</h4>
          </ListItemButton>
        </List>
      </Grid>
    </div>
  );
};

export default CalculationRulesSetup;
