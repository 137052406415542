import React, { useContext } from "react";
import {
  Box,
  Typography,
  Link,
  Grid,
  useTheme,
  Tabs,
  Tab,
} from "@mui/material";
import { Fragment } from "react";
import { AppRoutes } from "./core/PathConstants";
import { currencyFormatter, numberToString } from "./core/Calculations";
import { ElfsightWidget } from "react-elfsight-widget";
import { CurrentUserContext } from "./core/hooks/CurrentUserContext";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function Help() {
  const theme = useTheme();
  const { user } = useContext(CurrentUserContext);

  const [value, setValue] = React.useState("0");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Fragment>
      <Typography variant="h5">Help</Typography>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange} aria-label="help tabs">
            <Tab label="Videos" value="0" />
            <Tab label="FAQ" value="1" />
          </Tabs>
        </Box>
        {value === "0" && user?.role && user?.role === "Realtor" ? (
          <Box>
            <ElfsightWidget widgetID="71c3bc26-a4fb-4f0c-92c0-0f87cc744c48" />
          </Box>
        ) : value === "0" &&
          user?.role &&
          (user?.role === "MLO" ||
            user?.role === "Admin" ||
            user?.role === "MLO-Lender") ? (
          <Box>
            <ElfsightWidget widgetID="988e74f0-c42b-4887-b7ff-2b91f93f6116" />
          </Box>
        ) : (
          ""
        )}

        {value === "1" && user?.role && user?.role === "Realtor" ? (
          <Box>
            <ElfsightWidget widgetID="23e1f244-6af1-48c9-a8da-93304f13ae20" />
          </Box>
        ) : value === "1" &&
          user?.role &&
          (user?.role === "MLO" ||
            user?.role === "Admin" ||
            user?.role === "MLO-Lender") ? (
          <Box>
            <ElfsightWidget widgetID="0ab6e921-277a-4c8c-975c-60c68f126fb4" />
          </Box>
        ) : (
          ""
        )}
      </Box>

      {/*<Typography variant="h5">*/}
      {/*  {keyTerm === "loan-type" && t("help.loan-type").toUpperCase()}*/}
      {/*  {keyTerm === "prop-type" && t("help.property-type").toUpperCase()}*/}
      {/*  {keyTerm === "condo" && t("help.condominiums").toUpperCase()}*/}
      {/*  {keyTerm === "hoa-tax" &&*/}
      {/*    t("help.association-and-property-tax").toUpperCase()}*/}
      {/*  {keyTerm === "est-payment" && t("help.estimated-payment").toUpperCase()}*/}
      {/*  {keyTerm === "est-investment" &&*/}
      {/*    t("help.estimated-investment").toUpperCase()}*/}
      {/*  {keyTerm === "down-payment" && t("help.down-payment").toUpperCase()}*/}
      {/*  {keyTerm === "offer-price" && t("help.offer-price").toUpperCase()}*/}
      {/*  {keyTerm === "seller-rebate" &&*/}
      {/*    t("help.seller-rebate-concessions").toUpperCase()}*/}
      {/*  {keyTerm === "use-dealmaker" &&*/}
      {/*    t("help.how-to-use-the-deal-maker-tool").toUpperCase()}*/}
      {/*</Typography>*/}
      {/*<Box>*/}
      {/*  {keyTerm === "loan-type" && (*/}
      {/*    <Box>*/}
      {/*      <Typography variant="h6" component="div">*/}
      {/*        {loanType}*/}
      {/*      </Typography>*/}
      {/*      <Typography variant="h6" component="div">*/}
      {/*        {t("help.down-payment")}: {loanDown}%*/}
      {/*      </Typography>*/}
      {/*      <Trans t={t} i18nKey="help.loan-type-description">*/}
      {/*        <p>*/}
      {/*          This is the type of loan you qualified for after we considered*/}
      {/*          your home buying goals, as well as your circumstances and*/}
      {/*          available loan types.*/}
      {/*        </p>*/}
      {/*        <p>*/}
      {/*          There are other loan types you may qualify for and you can*/}
      {/*          discuss options with your loan officer.*/}
      {/*        </p>*/}
      {/*        <p>*/}
      {/*          You can increase the down payment, and we will automatically*/}
      {/*          recalculate how much money you will need to buy a particular*/}
      {/*          home. If you have more funds available for a down payment,*/}
      {/*          please update your loan officer so we can increase the approval*/}
      {/*          amount.*/}
      {/*        </p>*/}
      {/*        <p>*/}
      {/*          You cannot decrease the down payment percentage of the approval.*/}
      {/*          If you want to qualify with less down payment, please get in*/}
      {/*          touch with your loan officer.*/}
      {/*        </p>*/}
      {/*      </Trans>*/}
      {/*    </Box>*/}
      {/*  )}*/}
      {/*  {keyTerm === "hoa-tax" && (*/}
      {/*    <Box>*/}
      {/*      <Trans t={t} i18nKey="help.hoax-tax-description">*/}
      {/*        <p>*/}
      {/*          Accurate monthly homeowner association or condo fees are an*/}
      {/*          essential part of the qualification result. Many listings do not*/}
      {/*          have correct fee amounts, and some may show outdated property*/}
      {/*          taxes.*/}
      {/*        </p>*/}
      {/*        <p>*/}
      {/*          Other properties, including single-family homes, have*/}
      {/*          association fees which may not be on the listing either.*/}
      {/*        </p>*/}
      {/*        <p>*/}
      {/*          We highly recommend that you verify the monthly HOA,*/}
      {/*          maintenance, or association amounts, as well as the annual*/}
      {/*          property tax amount before placing an offer.*/}
      {/*        </p>*/}
      {/*        <p>*/}
      {/*          You can also verify the property taxes by checking the county*/}
      {/*          website, asking your realtor, or even by checking another*/}
      {/*          listing site to make sure they match. You can edit the annual*/}
      {/*          tax amount in the field shown.*/}
      {/*        </p>*/}
      {/*        <p>*/}
      {/*          For quarterly association fees, multiply by four and divide by*/}
      {/*          twelve to calculate the monthly amount.*/}
      {/*        </p>*/}
      {/*      </Trans>*/}
      {/*    </Box>*/}
      {/*  )}*/}
      {/*  {keyTerm === "prop-type" && (*/}
      {/*    <Box>*/}
      {/*      <Typography variant="h6" component="div">*/}
      {/*        {t("help.condominiums").toUpperCase()}*/}
      {/*      </Typography>*/}
      {/*      <Trans t={t} i18nKey="help.prop-type-condo">*/}
      {/*        <p>*/}
      {/*          Condominiums are unique when it come to mortgage financing*/}
      {/*          because the condo development must also meet certain standards*/}
      {/*          based on the loan type loan. Your loan approval will not apply*/}
      {/*          to all condo units.*/}
      {/*          <Link*/}
      {/*            style={{ cursor: "pointer" }}*/}
      {/*            onClick={() => {*/}
      {/*              changeKeyTerm("condo");*/}
      {/*            }}*/}
      {/*          >*/}
      {/*            Click here*/}
      {/*          </Link>*/}
      {/*          for more information on condos.*/}
      {/*        </p>*/}
      {/*      </Trans>*/}
      {/*      <Typography variant="h6" component="div">*/}
      {/*        {t("help.planned-unit-developments-townhouses").toUpperCase()}*/}
      {/*      </Typography>*/}
      {/*      <p>{t("help.prop-type-townhouses")}</p>*/}
      {/*      <Typography variant="h6" component="div">*/}
      {/*        {t("help.single-family-unattached-homes").toUpperCase()}*/}
      {/*      </Typography>*/}
      {/*      <p>{t("help.prop-type-single")}</p>*/}
      {/*      <Typography variant="h6" component="div">*/}
      {/*        {t("help.2-4-unit-properties").toUpperCase()}*/}
      {/*      </Typography>*/}
      {/*      <p>{t("help.prop-types-2-4-units")}</p>*/}
      {/*    </Box>*/}
      {/*  )}*/}
      {/*  {keyTerm === "condo" && (*/}
      {/*    <Box>*/}
      {/*      <Trans t={t} i18nKey="help.condo-description">*/}
      {/*        <p>*/}
      {/*          Condominiums are unique in mortgage financing because the condo*/}
      {/*          development must also meet specific standards to qualify for the*/}
      {/*          loan.*/}
      {/*        </p>*/}
      {/*        <p>*/}
      {/*          If the development is listed as approved on the FHA or Fannie*/}
      {/*          Mae approved condo list, it should qualify for the corresponding*/}
      {/*          loan type with the minimum down payment.*/}
      {/*        </p>*/}
      {/*        <p>*/}
      {/*          However, if the development is not on the list for your type of*/}
      {/*          loan, the process to determine if it will qualify requires a few*/}
      {/*          more steps. For FHA, there is a complex approval process. For*/}
      {/*          conventional, the process is faster and easier. However, if the*/}
      {/*          property can't meet the standards, it can't be financed using*/}
      {/*          FHA, and a conventional loan could require a 25% or more down*/}
      {/*          payment.*/}
      {/*        </p>*/}
      {/*        <p>*/}
      {/*          To learn more, your Real Estate agent can call the condo*/}
      {/*          association and try to determine what type of financing the*/}
      {/*          development qualifies for. They may also reach out to other*/}
      {/*          agents who recently sold properties in the development to ask*/}
      {/*          how they were financed.*/}
      {/*        </p>*/}
      {/*        <p>*/}
      {/*          To definitively learn if a condominium development will qualify*/}
      {/*          for your loan type, a process must be followed. The process*/}
      {/*          involves securing documents and a questionnaire from the condo*/}
      {/*          association, which must be submitted to our condo review desk to*/}
      {/*          determine if the development qualifies. Unfortunately,*/}
      {/*          associations usually charge for these forms, so the process is*/}
      {/*          typically taken on only after you've secured a contract.*/}
      {/*        </p>*/}
      {/*        <p>*/}
      {/*          If you want to search to see if the property is on the approval*/}
      {/*          lists, you can use the links below. Please be aware that the*/}
      {/*          approvals are specific, down to the phase or building, so*/}
      {/*          compare the list with the full condo name in the legal*/}
      {/*          description if you want to give it a try.*/}
      {/*        </p>*/}
      {/*        <Typography variant="subtitle2">*/}
      {/*          FHA Condo List:*/}
      {/*          <a*/}
      {/*            href="https://entp.hud.gov/idapp/html/condlook.cfm"*/}
      {/*            target="_blank"*/}
      {/*            rel="noreferrer"*/}
      {/*          >*/}
      {/*            Click Here*/}
      {/*          </a>*/}
      {/*        </Typography>*/}
      {/*        <Typography variant="subtitle2">*/}
      {/*          Fannie Mae Condo List:*/}
      {/*          <a*/}
      {/*            href="https://singlefamily.fanniemae.com/project-eligibility-review-services"*/}
      {/*            target="_blank"*/}
      {/*            rel="noreferrer"*/}
      {/*          >*/}
      {/*            Click Here*/}
      {/*          </a>*/}
      {/*        </Typography>*/}
      {/*      </Trans>*/}
      {/*    </Box>*/}
      {/*  )}*/}
      {/*  {keyTerm === "use-dealmaker" && (*/}
      {/*    <Box>*/}
      {/*      <Trans t={t} i18nKey="help.use-dealmaker-description">*/}
      {/*        <p>*/}
      {/*          Deal Maker sliders let you adjust the Offer Price, Down Payment,*/}
      {/*          and Seller Rebate (contribution) on the fly!*/}
      {/*        </p>*/}
      {/*        <p>*/}
      {/*          These are the tools of seasoned professionals, and now you have*/}
      {/*          them at your disposal!{" "}*/}
      {/*          <Link*/}
      {/*            href="#"*/}
      {/*            onClick={() => {*/}
      {/*              window.open(AppRoutes.Help, "_blank");*/}
      {/*            }}*/}
      {/*          >*/}
      {/*            Watch this short video*/}
      {/*          </Link>*/}
      {/*          to see just how powerful these tools are and how they can help*/}
      {/*          you qualify and structure your offer instantly.*/}
      {/*        </p>*/}
      {/*      </Trans>*/}
      {/*    </Box>*/}
      {/*  )}*/}
      {/*  {keyTerm === "est-payment" && (*/}
      {/*    <Box>*/}
      {/*      <p>{t("help.est-payment-description")}</p>*/}

      {/*      <Grid container sx={{ color: theme.palette.primary.main }}>*/}
      {/*        <Grid item xs={8}>*/}
      {/*          <b>{t("help.payment-we-qualified-you-for")}:</b>*/}
      {/*        </Grid>*/}
      {/*        <Grid item xs={4} sx={{ mb: 1, textAlign: "right" }}>*/}
      {/*          <b>{currencyFormatter(approvedPayment, 2)}</b>*/}
      {/*        </Grid>*/}
      {/*        <Grid item xs={8}>*/}
      {/*          {t("help.p-and-i")}:*/}
      {/*        </Grid>*/}
      {/*        <Grid item xs={4} sx={{ textAlign: "right" }}>*/}
      {/*          {numberToString(pi, 2)}*/}
      {/*        </Grid>*/}
      {/*        <Grid item xs={8}>*/}
      {/*          {t("help.home-insurance")}*/}
      {/*        </Grid>*/}
      {/*        <Grid item xs={4} sx={{ textAlign: "right" }}>*/}
      {/*          {numberToString(homeIns, 2)}*/}
      {/*        </Grid>*/}
      {/*        <Grid item xs={8}>*/}
      {/*          {t("help.propery-tax")}:*/}
      {/*        </Grid>*/}
      {/*        <Grid item xs={4} sx={{ textAlign: "right" }}>*/}
      {/*          {numberToString(tax, 2)}*/}
      {/*        </Grid>*/}
      {/*        <Grid item xs={8}>*/}
      {/*          {t("help.mortgage-insurance")}:*/}
      {/*        </Grid>*/}
      {/*        <Grid*/}
      {/*          item*/}
      {/*          xs={4}*/}
      {/*          sx={{*/}
      {/*            mb: 1,*/}
      {/*            borderBottom: "1px solid black",*/}
      {/*            textAlign: "right",*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          {numberToString(mi, 2)}*/}
      {/*        </Grid>*/}
      {/*        <Grid item xs={8}>*/}
      {/*          {t("help.estimated-payment")}:*/}
      {/*        </Grid>*/}
      {/*        <Grid item xs={4} sx={{ mb: 1, textAlign: "right" }}>*/}
      {/*          {currencyFormatter(pi + homeIns + tax + mi, 2)}*/}
      {/*        </Grid>*/}
      {/*      </Grid>*/}
      {/*      <Typography variant="subtitle2" component="div">*/}
      {/*        <Trans*/}
      {/*          t={t}*/}
      {/*          i18nKey="help.payment-does-not-include-hoa-association-fee"*/}
      {/*        >*/}
      {/*          *Payment does NOT include ${{ hoa }} Association Fee.*/}
      {/*        </Trans>*/}
      {/*      </Typography>*/}
      {/*      <p>{t("help.est-payment-calc-description")}</p>*/}
      {/*    </Box>*/}
      {/*  )}*/}
      {/*  {keyTerm === "est-investment" && (*/}
      {/*    <Box>*/}
      {/*      <Trans t={t} i18nKey="help.est-investment-description">*/}
      {/*        <p>*/}
      {/*          The estimated investment displayed in this tool is a guideline for*/}
      {/*          you to use when considering homes on which to place offers. It is*/}
      {/*          not a loan estimate, loan approval, or lending disclosure. You*/}
      {/*          will receive official disclosures when you have a signed contract*/}
      {/*          for a property and start the application process.*/}
      {/*        </p>*/}
      {/*        <p>*/}
      {/*          The Estimated Investment we calculate here includes the down*/}
      {/*          payment and the settlement expenses (closing costs) associated*/}
      {/*          with buying your home. We include all the expected costs at this*/}
      {/*          time. Other expenses not calculated here may include what you pay*/}
      {/*          to the home inspection company or homeowner associations.*/}
      {/*        </p>*/}
      {/*        <p>*/}
      {/*          Lenders are required by law to be the initial point of information*/}
      {/*          regarding all the settlement expenses attributed to about 6-8*/}
      {/*          providers, including government fees and taxes. Therefore, while*/}
      {/*          we do our best to present a realistic figure, these are some*/}
      {/*          estimates, and more accurate figures will emerge when you contract*/}
      {/*          for and receive actual quotes for things like insurance, title*/}
      {/*          work, and other items or services.*/}
      {/*        </p>*/}
      {/*        <p>*/}
      {/*          Despite all this, we dig deep to bring you as accurate a picture*/}
      {/*          as possible so that you can rely upon these numbers to narrow the*/}
      {/*          choice of available homes and structure your offer.*/}
      {/*        </p>*/}
      {/*        <p>*/}
      {/*          Some of the things we search for and factor into the total*/}
      {/*          estimated investment are property taxes, whether the home is in a*/}
      {/*          flood zone, government taxes, reasonable insurance estimates, and*/}
      {/*          settlement/title agent estimates for your area.*/}
      {/*        </p>*/}
      {/*      </Trans>*/}
      {/*    </Box>*/}
      {/*  )}*/}
      {/*  {keyTerm === "down-payment" && (*/}
      {/*    <Box>*/}
      {/*      <Trans t={t} i18nKey="help.down-payment-description">*/}
      {/*        <p>*/}
      {/*          We start each qualification with the down payment that you were*/}
      {/*          qualified for.*/}
      {/*        </p>*/}
      {/*        <p>*/}
      {/*          You can use the down payment slider and box to increase the down*/}
      {/*          payment to see how it affects your qualification and monthly*/}
      {/*          payment.*/}
      {/*        </p>*/}
      {/*        <p>*/}
      {/*          If you increase the down payment, the Estimated Investment also*/}
      {/*          goes up. If the required investment is more than the funds you*/}
      {/*          showed us for qualification, the Estimated Investment box will be*/}
      {/*          red. If you have more money to invest, please reach out to your*/}
      {/*          loan officer, so we can add the amount to your qualification*/}
      {/*          profile.*/}
      {/*        </p>*/}
      {/*        <p>*/}
      {/*          You cannot use this tool to lower your down payment because loan*/}
      {/*          approvals are strongly tied to the down payment, and reducing it*/}
      {/*          could disqualify you. If you want to lower your down payment,*/}
      {/*          please contact us and we re-qualify you with a lower down.*/}
      {/*        </p>*/}
      {/*        <p>Your approval is based on {{downPayment}}% down payment.</p>*/}
      {/*      </Trans>*/}
      {/*    </Box>*/}
      {/*  )}*/}
      {/*  {keyTerm === "offer-price" && (*/}
      {/*    <Box>*/}
      {/*      <Trans t={t} i18nKey="help.offer-price-description">*/}
      {/*        <p>*/}
      {/*          There are thousands of books written on the art and technique of*/}
      {/*          negotiation, and making an offer for less than the listed asking*/}
      {/*          price is at the heart of this topic.*/}
      {/*        </p>*/}
      {/*        <p>*/}
      {/*          In real estate, the offer price is the sale price that the buyer*/}
      {/*          proposes to pay the seller for the property. It's usually written*/}
      {/*          on an offer to purchase document, which becomes the contract after*/}
      {/*          all parties agree on the terms.*/}
      {/*        </p>*/}
      {/*        <p>*/}
      {/*          How much less than the asking price the seller is willing to*/}
      {/*          accept is the biggest question, all buyers ask. The answer is*/}
      {/*          different for every property. It not only relies on the seller's*/}
      {/*          situation but also on market influences, geographical factors, and*/}
      {/*          whether it is currently a buyer's or seller's market in that area.*/}
      {/*        </p>*/}
      {/*        <p>*/}
      {/*          Some sellers are fixed on their asking price while others price*/}
      {/*          their home expecting they will receive less than full-price*/}
      {/*          offers. In this area, it's best to rely on the knowledge and*/}
      {/*          experience of your Realtor to help you make successful offers.*/}
      {/*        </p>*/}
      {/*      </Trans>*/}
      {/*    </Box>*/}
      {/*  )}*/}
      {/*  {keyTerm === "seller-rebate" && (*/}
      {/*    <Box>*/}
      {/*      <Trans t={t} i18nKey="help.seller-rebate-description">*/}
      {/*        <p>*/}
      {/*          A seller rebate is commonly known as a seller's concession or*/}
      {/*          seller-paid closing costs. You can use this, combined with the*/}
      {/*          offer price tool, to see the effect of a lower price, or seller*/}
      {/*          rebate, in respect to qualifying for a mortgage. Using a*/}
      {/*          combination of these two is a potent tool in the qualification*/}
      {/*          calculation if you need to buy with as little investment as*/}
      {/*          possible.*/}
      {/*        </p>*/}
      {/*        <p>*/}
      {/*          As a general rule of thumb, if the payment is too high for a buyer*/}
      {/*          to qualify, the solution is to offer a lower price or find a less*/}
      {/*          expensive property. On the other hand, if the buyer qualifies for*/}
      {/*          a larger payment but is short on the amount of funds required to*/}
      {/*          buy a home, a more effective tool is the seller rebate.*/}
      {/*        </p>*/}
      {/*        <p>*/}
      {/*          By lowering the sale price, the loan amount is also reduced, which*/}
      {/*          lowers the payment. A rough guideline is about $60 of payment*/}
      {/*          reduction for every $10,000 in lower price. However, obtaining a*/}
      {/*          seller rebate does not lower the payment unless the credit is*/}
      {/*          specifically used to buy down the interest rate.*/}
      {/*        </p>*/}
      {/*        <p>*/}
      {/*          In contrast, lowering the sale price does little to reduce the*/}
      {/*          funds needed to purchase because the investment reduction is*/}
      {/*          mainly on the down payment. For example, if the buyer puts a 5%*/}
      {/*          down payment and lowers the price $10,000, they are essentially*/}
      {/*          reducing the cash needed by only 5% of the price reduction amount.*/}
      {/*          In this example, they would reduce their down payment requirement*/}
      {/*          by only $500 and a small amount on some closing expenses. But to*/}
      {/*          the seller, a $10,000 reduction of the sale price could be a*/}
      {/*          significant discount.*/}
      {/*        </p>*/}
      {/*        <p>*/}
      {/*          However, if the buyer leaves the sale price as-is but instead asks*/}
      {/*          for a $5,000 seller rebate, there is no effect on the monthly*/}
      {/*          payment, but there is a direct impact of $5,000 less that the*/}
      {/*          buyer would have to bring closing. In this scenario, the buyer*/}
      {/*          needs a lot less money to buy than if the sale price was lowered*/}
      {/*          $10,000, and the seller is only reducing the "net price" of the*/}
      {/*          home by $5,000, instead of $10,000 in the price reduction example.*/}
      {/*        </p>*/}
      {/*        <p>*/}
      {/*          <b>*/}
      {/*            There are limits on the amount a seller can rebate based on the*/}
      {/*            loan program and the system will max out at that amount.*/}
      {/*          </b>*/}
      {/*          Try using the price and seller rebate tools to see the impact it*/}
      {/*          has on Estimated Payment vs Estimated Investment.*/}
      {/*        </p>*/}
      {/*      </Trans>*/}
      {/*    </Box>*/}
      {/*  )}*/}
      {/*</Box>*/}
      {/* <DialogActions>
        <Typography component='div' sx={{ flexGrow: 1 }}>
          <Button
            onClick={() => {
              window.open(AppRoutes.Help, "_blank")
              onClose()
            }}
          >
            Other Help Topics
          </Button>
        </Typography>
        <Button onClick={onClose}>Close</Button>
      </DialogActions> */}
    </Fragment>
  );
}
