import React, { useState, useEffect, useContext } from "react";

import UICustomizationSetup from "./UICustomizationSetup";
import { formValidation } from "../../core/validationFunctions";
import makeStyles from "@mui/styles/makeStyles";
import { Grid, Paper, Typography, Button } from "@mui/material";
import { fetchApiData, postApiData } from "../../core/FetchApiData";
import {
  imageValidation,
  noLogoSrc,
  defaultThemeSecondaryColor,
  defaultThemePrimaryColor,
} from "../../core/validationFunctions";
import { ApiPaths } from "../../core/PathConstants";
import AlertMessage from "../../core/AlertMessage";
import _ from "lodash";
import { Loading } from "../../core/Loading";
import { CurrentUserContext } from "../../core/hooks/CurrentUserContext";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  cardroot: {
    minHeight: 200,
  },
  paper: {
    padding: theme.spacing(2),
    //textAlign: 'start',
    color: theme.palette.text.secondary,
  },
  media: {
    height: 140,
  },
}));

// Default company logo image
const defaultPrimaryColor = defaultThemePrimaryColor;
const defaultSecondaryColor = defaultThemeSecondaryColor;

// Initial values
var initialValues = {
  logoImage: "",
  themePrimaryColor: "#1D285B",
  themeSecondaryColor: "#F1592A",
  chromeExtensionHomePagePopupText:
    "Search for a home to verify if you qualify for it",
  chromeExtensionPropertyPagePopupText: "Qualify me for this home",
};

// Validations of some fields
const fieldsValidation = {
  chromeExtensionHomePagePopupText: {
    error: "",
    minLength: 1,
    maxLength: 100,
  },
  chromeExtensionPropertyPagePopupText: {
    error: "",
    minLength: 1,
    maxLength: 100,
  },
};

const UICustomization = () => {
  const { user } = useContext(CurrentUserContext);

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [errorLoading, setErrorLoading] = useState(false);
  const [isValidLogo, setIsValidLogo] = useState(true);
  const classes = useStyles();
  const [formValuesLoaded, setFormValuesLoaded] = useState(false);
  const [alertState, setAlertState] = useState({ show: false });
  const [openAlertOnStatusPending, setOpenAlertOnStatusPending] =
    useState(false);

  const [defaultData, setDefaultData] = useState({});
  const [isModified, setIsModified] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  // Handle input fields changes, updating formValues properties values
  const handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    // Set values
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name in fieldsValidation) {
      // Set errors
      const error = formValidation(name, value, fieldsValidation) || "";

      setFormErrors((prev) => ({
        ...prev,
        [name]: error,
      }));
    }
  };

  const updateThemeColors = (primaryColor, secondaryColor) => {
    setFormValues({
      ...formValues,
      themePrimaryColor: primaryColor,
      themeSecondaryColor: secondaryColor,
    });
  };

  const loadImage = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      let imageFile = e.target.files[0];
      const reader = new FileReader();
      if (!imageValidation(imageFile, 3)) {
        setIsValidLogo(false);
        return;
      }
      reader.onload = (x) => {
        setFormValues({
          ...formValues,
          logoImage: x.target.result,
        });
      };
      reader.readAsDataURL(imageFile);
    } else {
      setFormValues({
        ...formValues,
        logoImage: noLogoSrc,
      });
    }
  };

  const deleteImage = () => {
    if (setFormValues && formValues) {
      setFormValues({
        ...formValues,
        logoImage: "",
      });
    }
  };

  const onFormValues = (data) => {
    if (data.themePrimaryColor == null || data.themePrimaryColor == "") {
      data.themePrimaryColor = defaultPrimaryColor;
    }
    if (data.themeSecondaryColor == null || data.themeSecondaryColor == "") {
      data.themeSecondaryColor = defaultSecondaryColor;
    }
    if (data.logoImage == null || data.logoImage == "") {
      data.logoImage = noLogoSrc;
    }
    setFormValues(data);
    setFormValuesLoaded(true);
    setIsModified(true);
  };

  useEffect(() => {
    //Get Company data
    fetchApiData(
      ApiPaths.CompanyUICustomization,
      onFormValues,
      setIsLoading,
      onError,
      user.token
    );
  }, []);

  useEffect(() => {
    if (formValuesLoaded === true) {
      if (formValues.status === 2) {
        setOpenAlertOnStatusPending(true);
      }
      setDefaultData(formValues);
    }
  }, [formValuesLoaded]);

  useEffect(() => {
    if (formValuesLoaded === true) {
      if (
        _.isEqualWith(defaultData, formValues, (value1, value2, key) => {
          return key === "states" || key === "taxRules" ? true : undefined;
        })
      ) {
        setIsModified(false);
      } else {
        setIsModified(true);
      }
    }
  }, [formValues]);

  const onSaved = () => {
    setIsSaving(false);
    setAlertState({
      show: true,
      message: "Changes successfully saved!",
      type: "success",
    });
    setDefaultData(formValues);
    setIsModified(false);
  };

  const onError = (status, msg) => {
    setErrorLoading(true);
    setAlertState({
      show: true,
      message:
        "Error retrieving retrieving or saving company ui customization data",
    });
  };

  async function handleSubmit() {
    setIsSaving(true);
    postApiData(
      ApiPaths.CompanyUICustomization,
      "PUT",
      formValues,
      onSaved,
      onError,
      user.token
    );
  }

  // Returns component depending of step
  function customizationView() {
    return (
      <UICustomizationSetup
        values={formValues}
        handleChange={handleChange}
        loadImage={loadImage}
        isValidLogo={isValidLogo}
        setIsValidLogo={setIsValidLogo}
        updateThemeColors={updateThemeColors}
        formErrors={formErrors}
        deleteImage={deleteImage}
      />
    );
  }

  const renderStepper = () => {
    return (
      <div className={classes.root}>
        <Loading isLoading={isSaving} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5">UI Customization</Typography>
          </Grid>
          <Grid item xs={12}>
            <Paper variant="outlined" square className={classes.paper}>
              <form>{customizationView()}</form>
              <Grid container style={{ margin: "10px 10px 5px 10px" }}>
                <Grid container item xs={12} justifyContent="flex-end">
                  <Button
                    style={{ margin: "0 20px 10px 10px" }}
                    variant="contained"
                    color="primary"
                    disabled={!isModified}
                    onClick={isModified ? handleSubmit : null}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        {/*<Hidden xsDown>*/}
        {/*    <AlertMessage openAlert={openAlertOnStatusPending} alertMessage='Company Pending Approval' autoHideDuration={null} alertType='warning' anchorOrigin={{ vertical: 'top', horizontal: 'center' }} style={{ width: '100%', position: 'fixed', display: 'block', top: '59px', zIndex: 200 }} styleMessage={{ paddingLeft: '260px' }}></AlertMessage>*/}
        {/*</Hidden>*/}
        {/*<Hidden smUp>*/}
        {/*    <AlertMessage openAlert={openAlertOnStatusPending} alertMessage='Company Pending Approval' autoHideDuration={null} alertType='warning' anchorOrigin={{ vertical: 'top', horizontal: 'center' }} style={{ width: '100%', position: 'fixed', display: 'block', top: '53px', zIndex: 200, left: 0 }}></AlertMessage>*/}
        {/*</Hidden>*/}
        <AlertMessage alert={alertState} />
      </div>
    );
  };

  if (isLoading)
    return (
      <h4>
        <em>Loading...</em>
      </h4>
    );
  else if (errorLoading)
    return (
      <h4>
        <em>
          There was a data error or you don't have permission to view this page
        </em>
      </h4>
    );
  else return renderStepper();
};

export default UICustomization;
