import { TextField } from "@mui/material";
import React from "react";
import { useEffect } from "react";

const isText = RegExp(/^[A-Z ]+$/i);
const isEmail = RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
const isPhone = RegExp(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4,6})$/); // us
const isZip = RegExp(/^[0-9]{5}([- /]?[0-9]{4})?$/); // us
const isNumber = RegExp(/^\d+$/);
const isWebSIte = RegExp(
  /(:?(?:https?:\/\/)?(?:www\.)?)?[-a-z0-9]+\.(?:com|gov|org|net|edu|biz)/g
);
const isPassword = RegExp(
  /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[\W_])[\w\W]{8,20}$/
);

function validateField(value, schema) {
  const { validate, minLength, maxLength } = schema;
  let error = "";

  if (minLength && value.length < minLength)
    error = `Minimum of ${minLength} characters are required.`;
  else if (maxLength && value.length > maxLength)
    error = `Maximum length of ${maxLength} exceeded!`;
  if (!validate) return error;

  switch (validate) {
    case "text":
      if (!isText.test(value)) error = "This field accepts text only.";
      break;

    case "number":
      if (!isNumber.test(value)) error = "This field accepts numbers only.";
      break;

    case "email":
      if (!isEmail.test(value)) error = "Please enter a valid email address.";
      break;

    case "phone":
      if (!isPhone.test(value))
        error = "Please enter a valid phone number. i.e: xxx-xxx-xxxx";
      break;

    case "zip":
      if (!isZip.test(value)) error = "Please enter a valid zip code.";
      break;

    case "checkbox":
      if (!value) error = "Please select a value.";
      break;

    case "website":
      if (!isWebSIte.test(value)) error = "Please enter a valid website URL";
      break;

    case "password":
      if (!isPassword.test(value))
        error =
          "Please enter a valid password with at least one uppercase, one lowercase, one digit and one special character";
      break;

    default:
      break;
  }

  return error;
}
//validations
//compare: other field to compare to
//maxLength
//minLength
//required if minLength > 0
//validate: text, website, zip, phone, email, number, password
//setFormValues to set form values
//formValues the form values
//isValidForm an object with the properties of the data and true is valid
//setIsValidForm ref to callback function
//compareError

export const TextFieldValidation = (props) => {
  const {
    compare,
    maxLength,
    minLength,
    validate,
    setFormValues,
    formValues,
    setIsValidForm,
    isValidForm,
    compareError,
    ...rest
  } = props;
  const [error, setError] = React.useState("");

  const handleChange = (e) => {
    const name = e.target.name;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    // Set values
    setFormValues?.({
      ...formValues,
      [name]: value,
    });

    //if (name in fieldsValidation) {            // Set errors
    let err = validateField(value, { validate, minLength, maxLength }) || "";

    if (compare && (formValues[compare] || formValues[compare].length > 0)) {
      //check compare
      if (value !== formValues?.[compare]) {
        err = compareError ?? name + " and " + compare + " dont match.";
      }
    }

    if (err !== error || (err == "" && error == "")) {
      setError(err);
      setIsValidForm?.({ ...isValidForm, [name]: err === "" });
    }
  };

  useEffect(() => {
    setIsValidForm?.({
      ...isValidForm,
      [props.name]: !(props.required && !formValues?.[props.name]),
    });
  }, []);

  return (
    <React.Fragment>
      <TextField
        {...rest}
        onChange={handleChange}
        value={formValues?.[props.name] || ""}
        error={!!error}
        helperText={error}
        onBlur={handleChange}
      />
    </React.Fragment>
  );
};
