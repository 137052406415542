import React, { useRef } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { postApiPdf } from "./FetchApiData";
import { ApiPaths } from "./PathConstants";

export const RichTextEditor = ({
  onContentChange,
  content,
  onPreview,
  showPreview,
  showMergeFields,
}) => {
  const editor = useRef();

  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
  };

  const handleChange = (contentValue) => {
    onContentChange(contentValue);
  };

  function fetchPdf(contentValue) {
    if (onPreview) onPreview(contentValue);
    // await postApiPdf(ApiPaths.PreviewPdf, 'POST', contentValue);
  }

  const handleFetchPdf = () => {
    var contentValue = editor.current ? editor.current.getContents() : content;
    fetchPdf(contentValue);
  };

  const mergeTag = {
    // @Required
    // plugin name
    name: "merge_tag",

    // @Required
    // data display
    display: "submenu",

    // @Required
    // add function - It is called only once when the plugin is first run.
    // This function generates HTML to append and register the event.
    // arguments - (core : core object, targetElement : clicked button element)
    add: function (core, targetElement) {
      // Generate submenu HTML
      // Always bind "core" when calling a plugin function
      let listDiv = this.setSubmenu.call(core);

      // You must bind "core" object when registering an event.
      /** add event listeners */
      var self = this;
      listDiv.querySelectorAll(".se-btn-list").forEach(function (btn) {
        btn.addEventListener("click", self.onClick.bind(core));
      });

      // @Required
      // You must add the "submenu" element using the "core.initMenuTarget" method.
      /** append target button menu */
      core.initMenuTarget(this.name, targetElement, listDiv);
    },

    //TODO: dinamically get the Merge Fields
    setSubmenu: function () {
      const listDiv = this.util.createElement("DIV");
      // @Required
      // A "se-submenu" class is required for the top level element.
      listDiv.className = "se-submenu se-list-layer";
      listDiv.innerHTML =
        '<div class="se-list-inner se-list-font-size"><ul class="se-list-basic">' +
        '<li><button type="button" class="se-btn-list" value="{CurrentDate}">Current Date</button></li>' +
        '<li><button type="button" class="se-btn-list" value="{Price}">Listing Price</button></li>' +
        '<li><button type="button" class="se-btn-list" value="{OfferPrice}">Offer Price</button></li>' +
        '<li><button type="button" class="se-btn-list" value="{LoanNumber}">Loan Number</button></li>' +
        '<li><button type="button" class="se-btn-list" value="{LoanRate}">Loan Rate</button></li>' +
        '<li><button type="button" class="se-btn-list" value="{Term}">Loan Term</button></li>' +
        '<li><button type="button" class="se-btn-list" value="{LoanAmount}">Loan Amount</button></li>' +
        '<li><button type="button" class="se-btn-list" value="{LoanType}">Loan Type</button></li>' +
        '<li><button type="button" class="se-btn-list" value="{PurchasePrice}">LOS Approval Price</button></li>' +
        '<li><button type="button" class="se-btn-list" value="{BorrowerAddress}">Borrower Address</button></li>' +
        '<li><button type="button" class="se-btn-list" value="{BorrowerFirstName}">Borrower First Name</button></li>' +
        '<li><button type="button" class="se-btn-list" value="{BorrowerLastName}">Borrower Last Name</button></li>' +
        '<li><button type="button" class="se-btn-list" value="{BorrowerFullName}">Borrower Full Name</button></li>' +
        '<li><button type="button" class="se-btn-list" value="{CoBorrowerFirstName}">Co-Borrower First Name</button></li>' +
        '<li><button type="button" class="se-btn-list" value="{CoBorrowerLastName}">Co-Borrower Last Name</button></li>' +
        '<li><button type="button" class="se-btn-list" value="{CoBorrowerFullName}">Co-Borrower Full Name</button></li>' +
        '<li><button type="button" class="se-btn-list" value="{LTVRatio}">LTV Ratio</button></li>' +
        '<li><button type="button" class="se-btn-list" value="{MLOSignature}">MLO Signature</button></li>' +
        '<li><button type="button" class="se-btn-list" value="{MLOName}">MLO Name</button></li>' +
        '<li><button type="button" class="se-btn-list" value="{StreetAddress}">Property Street Address</button></li>' +
        '<li><button type="button" class="se-btn-list" value="{City}">Property City</button></li>' +
        '<li><button type="button" class="se-btn-list" value="{County}">Property County</button></li>' +
        '<li><button type="button" class="se-btn-list" value="{State}">Property State</button></li>' +
        '<li><button type="button" class="se-btn-list" value="{ZipCode}">Property ZipCode</button></li>' +
        '<li><button type="button" class="se-btn-list" value="{PropertyType}">Property Type</button></li>' +
        "</ul></div>";

      return listDiv;
    },

    onClick: function (e) {
      const value = e.target.value;
      const node = this.util.createElement("span");
      this.util.addClass(node, "se-custom-tag");
      node.textContent = value;

      this.insertNode(node);
      const zeroWidthSpace = this.util.createTextNode(this.util.zeroWidthSpace);
      node.parentNode.insertBefore(zeroWidthSpace, node.nextSibling);

      this.submenuOff();
    },
  };

  const previewPdf = {
    name: "preview_pdf",

    // @Required
    // data display
    display: "command",

    // @Required
    add: function (core, targetElement) {
      const context = core.context;
      context.previewPdf = {
        targetButton: targetElement,
      };
    },

    action: function (e) {
      handleFetchPdf();
    },
  };

  const mergeFields = showMergeFields
    ? {
        name: "merge_tag",
        dataCommand: "merge_tag",
        buttonClass: "se-btn-select",
        title: "Merge Fields",
        dataDisplay: "submenu",
        innerHTML:
          '<span style="margin-right:6px;color:#1B4F72">Merge Fields</span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.73 8.67"><g><path d="M18.79,7.52a.8.8,0,0,1,.56-.23.82.82,0,0,1,.79.79.8.8,0,0,1-.23.56l-7.07,7.07a.79.79,0,0,1-.57.25.77.77,0,0,1-.57-.25h0L4.64,8.65a.8.8,0,0,1-.23-.57.82.82,0,0,1,.79-.79.8.8,0,0,1,.56.23L12.28,14l3.26-3.26,3.25-3.26Z" transform="translate(-4.41 -7.29)"></path></g></svg>',
      }
    : {};

  const preview = showPreview
    ? {
        name: "preview_pdf",
        dataCommand: "preview_pdf",
        title: "Preview",
        dataDisplay: "command",
        innerHTML:
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.65 15.66"><g><path d="M16.19,14.43l2.49,2.49a.73.73,0,0,1,.21.52.67.67,0,0,1-.22.51.7.7,0,0,1-.52.22.69.69,0,0,1-.51-.21l-2.49-2.48a5.17,5.17,0,0,1-1.34.69,4.64,4.64,0,0,1-1.48.24,4.78,4.78,0,1,1,0-9.56,4.79,4.79,0,0,1,1.84.36,4.9,4.9,0,0,1,1.56,1,4.77,4.77,0,0,1,.46,6.18ZM10,14a3.3,3.3,0,0,0,2.34.93A3.37,3.37,0,0,0,14.7,14a3.3,3.3,0,0,0-1.08-5.41,3.47,3.47,0,0,0-2.56,0A3,3,0,0,0,10,9.28,3.31,3.31,0,0,0,10,14ZM16,4a3.86,3.86,0,0,1,2.77,1.14A3.9,3.9,0,0,1,20,7.85v4a.77.77,0,0,1-.22.53.7.7,0,0,1-.52.21.72.72,0,0,1-.74-.74v-4a2.46,2.46,0,0,0-.72-1.73A2.37,2.37,0,0,0,16,5.45H8.53A2.42,2.42,0,0,0,6.08,7.89v7.52a2.41,2.41,0,0,0,.71,1.73,2.46,2.46,0,0,0,1.74.72h4.08a.73.73,0,0,1,0,1.46H8.53a3.85,3.85,0,0,1-2.78-1.14A3.93,3.93,0,0,1,4.6,15.4V7.87A3.94,3.94,0,0,1,5.76,5.09,3.88,3.88,0,0,1,8.54,4H16Z" transform="translate(-4.45 -3.8)"></path></g></svg>',
      }
    : {};

  const toolbarButtons = [
    ["undo", "redo"],
    ["font", "fontSize", "formatBlock"],
    ["bold", "underline", "italic", "strike", "superscript"],
    ["removeFormat"],
    ["fontColor", "hiliteColor"],
    ["align", "list"],
    [mergeFields],
    ["table", "horizontalRule", "image"],
    ["outdent", "indent"],
    [preview, "fullScreen"],
  ];

  return (
    <SunEditor
      defaultValue={content}
      getSunEditorInstance={getSunEditorInstance}
      lang="en"
      onChange={handleChange}
      setDefaultStyle="font-family: cursive; font-size: 14px;"
      height="100%"
      setOptions={{
        height: 500,
        plugins: [mergeTag, previewPdf],
        buttonList: toolbarButtons,
      }}
    />
  );
};
