import React, { useState } from "react";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";

const YesNoSwitch = styled(Switch)(({ theme, yestext, notext }) => ({
  width: "70px",
  height: "24px",
  padding: "0px",
  "& .MuiSwitch-switchBase": {
    color: "#818181",
    padding: "1px",
    "&.Mui-checked": {
      transform: "translateX(45px) !important",
      "& + .MuiSwitch-track": {
        backgroundColor: "#23bf58",
        "&:after": {
          content: `'${yestext}'`,
          left: "25px",
        },
      },
    },
    "&:not(.Mui-checked)": {
      "& + .MuiSwitch-track": {
        "&:after": {
          content: `'${notext}'`,
          left: "29px",
        },
      },
    },
  },
  "& .MuiSwitch-track": {
    borderRadius: "20px",
    backgroundColor: "#e13b3b",
    opacity: "1 !important",
    border: "solid 0.2px #a7a7a7",
    "&:after, &:before": {
      color: "white",
      fontSize: "11px",
      position: "absolute",
      top: "6px",
    },
  },
  "& .MuiSwitch-thumb": {
    color: "white",
    width: "20px",
    height: "20px",
    margin: "1px",
  },
}));

export default function BaseYesNoSwitch({
  rowId,
  checked,
  onHandleChange,
  yesText = "YES",
  noText = "NO",
  ...rest
}) {
  const [state, setState] = useState(checked ?? false);

  const handleChange = (event) => {
    const value = event.target.checked;
    setState(value);
    if (onHandleChange) {
      if (rowId) {
        onHandleChange(rowId, value);
      } else {
        onHandleChange(value);
      }
    }
  };

  return (
    <div>
      <YesNoSwitch
        checked={state}
        onChange={handleChange}
        {...rest}
        yestext={yesText}
        notext={noText}
      />
    </div>
  );
}
