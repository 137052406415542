import React, { useState, useEffect, useContext } from "react";
import {
  Typography,
  Grid,
  Stack,
  TextField,
  Button,
  Backdrop,
  CircularProgress,
  InputAdornment,
  IconButton,
  Box,
  FormHelperText,
} from "@mui/material";
import { ApiPaths, AppRoutes } from "../core/PathConstants";
import { TextFieldValidation } from "../core/TextFieldValidation";
import { postApiData } from "../core/FetchApiData";
import { useHistory } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function SignUp() {
  const [isValidForm, setIsValidForm] = useState({});
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
    email: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const history = useHistory();

  const validateForm = () => {
    let invalidValues = 0;
    if (Object.keys(formValues).length === 0) {
      setFormValues({
        firstName: "",
        lastName: "",
        password: "",
        confirmPassword: "",
        email: "",
      });
      return false;
    }
    for (let item in isValidForm) {
      if (!isValidForm[item]) invalidValues++;
    }
    return invalidValues == 0;
  };

  const onSaveError = (status, msg) => {
    setIsLoading(false);
    if (status !== 500) setErrorMessage(msg);
    else setErrorMessage("Error creating account! Please try again later.");
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onComplete = (u) => {
    let currentDate = new Date();
    currentDate.setMinutes(currentDate.getMinutes() + 15);
    const currentDateTimestamp = Math.floor(currentDate.getTime() / 1000);
    history.push(
      `${AppRoutes.VerifyOTP}?id=${u.userId}&date=${currentDateTimestamp}`
    );
  };

  const onLCickBack = () => {
    history.push(`${AppRoutes.Login}`);
  };

  const onSave = (evt) => {
    //var res = evt.currentTarget.form.reportValidity();
    evt.preventDefault();
    const form = evt.currentTarget.form;
    const formControls = form.elements;

    let isValid = true;

    for (let i = 0; i < formControls.length; i++) {
      if (!formControls[i].checkValidity()) {
        formControls[i].reportValidity();
        isValid = false;
      }
    }
    if (isValid) {
      if (validateForm()) {
        setErrorMessage("");
        setIsLoading(true);
        postApiData(
          ApiPaths.SignUp,
          "POST",
          {
            firstName: formValues.firstName,
            lastname: formValues.lastName,
            password: formValues.password,
            confirmPassword: formValues.confirmPassword,
            email: formValues.email,
            role: "MLO-Lender",
          },
          onComplete,
          onSaveError,
          null
        );
      } else setErrorMessage("Please correct the field values before saving");
    }
  };

  return (
    <Box
      component="form"
      sx={{
        mt: 5,
        mx: "auto",
        "& .MuiTextField-root": { m: 1, width: "35ch" },
        "& .MuiButton-root": { m: 1 },
      }}
      autoComplete="off"
    >
      <Grid
        container
        display="flex"
        direction="column"
        m={2}
        justifyContent="center"
      >
        <Stack sx={{ alignItems: "center" }}>
          <Typography variant="h6" component="div">
            <img
              alt="Padzilly"
              src="images/logo-navy.svg"
              width="150"
              height="50"
            />
          </Typography>
          <Typography
            sx={{
              fontSize: "26",
              fontFamily: "American Captain Regular",
              color: "#283C8F",
            }}
            variant="h4"
            textAlign="center"
            component="div"
          >
            For Partners
          </Typography>
        </Stack>
        <Stack
          sx={{
            alignItems: "center",
            marginTop: "2rem",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
            }}
            variant="h5"
            textAlign="center"
            component="div"
          >
            Let's create your account
          </Typography>
          <Typography
            sx={{
              fontWeight: "bold",
            }}
            variant="h6"
            textAlign="center"
            component="div"
          >
            Free for a limited time
          </Typography>
        </Stack>
      </Grid>

      <Grid
        container
        m={2}
        justifyContent="center"
        style={{ marginTop: "1.8rem" }}
      >
        <Grid>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <TextFieldValidation
                style={{ margin: "10px 10px 10px 5px" }}
                variant="outlined"
                fullWidth
                size="small"
                type="email"
                label="Email"
                name="email"
                setFormValues={setFormValues}
                formValues={formValues}
                isValidForm={isValidForm}
                setIsValidForm={setIsValidForm}
                required
                validate="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldValidation
                style={{ margin: "10px 10px 10px 5px" }}
                variant="outlined"
                fullWidth
                size="small"
                minLength={2}
                label="First Name"
                name="firstName"
                setFormValues={setFormValues}
                formValues={formValues}
                required
                isValidForm={isValidForm}
                setIsValidForm={setIsValidForm}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldValidation
                style={{ margin: "10px 10px 10px 5px" }}
                variant="outlined"
                fullWidth
                size="small"
                minLength={2}
                label="Last Name"
                name="lastName"
                setFormValues={setFormValues}
                formValues={formValues}
                required
                isValidForm={isValidForm}
                setIsValidForm={setIsValidForm}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldValidation
                style={{ margin: "10px 10px 10px 5px" }}
                variant="outlined"
                fullWidth
                size="small"
                label="Password"
                name="password"
                type={showNewPassword ? "text" : "password"}
                setFormValues={setFormValues}
                formValues={formValues}
                isValidForm={isValidForm}
                setIsValidForm={setIsValidForm}
                validate="password"
                minLength={8}
                maxLength={20}
                compare="confirmPassword"
                compareError="New Password should have the same value as Confirm Password"
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowNewPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldValidation
                style={{ margin: "10px 10px 10px 5px" }}
                variant="outlined"
                fullWidth
                size="small"
                label="Confirm Password"
                name="confirmPassword"
                type={showConfirmPassword ? "text" : "password"}
                setFormValues={setFormValues}
                formValues={formValues}
                isValidForm={isValidForm}
                setIsValidForm={setIsValidForm}
                required
                validate="password"
                compare="password"
                compareError="New Password should have the same value as Confirm Password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              container
              justifyContent="flex-end"
              style={{ marginTop: "0.75rem" }}
            >
              <Button
                style={{ marginTop: 10 }}
                variant="contained"
                color="primary"
                onClick={onLCickBack}
              >
                BACK
              </Button>
              <Button
                style={{ marginTop: 10 }}
                variant="contained"
                color="primary"
                onClick={onSave}
              >
                Create Account
              </Button>
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              alignItems="center"
            >
              <FormHelperText
                sx={{ color: "black", m: 2, fontSize: "12px", margin: "12px" }}
              >
                By creating an account, I accept&nbsp;
                <a
                  href="https://padzilly.com/terms.html"
                  style={{ marginLeft: "0.1rem" }}
                >
                  Padzilly Terms of Use.
                </a>
              </FormHelperText>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
          direction="row"
          style={{ marginTop: "0.75rem", borderRadius: "6px" }}
        >
          <Grid
            container
            item
            xs={5}
            justifyContent="center"
            alignItems="center"
          >
            <FormHelperText sx={{ color: "black", m: 2, fontSize: "12px" }}>
              You can use your account for free now. Once Subscription tiers are
              setup you will be prompted to select one of the Monthly
              subscriptions.
            </FormHelperText>
          </Grid>
        </Grid>
        {errorMessage && (
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            alignItems="center"
            direction="row"
            style={{ marginTop: "2rem", borderRadius: "6px" }}
          >
            <Grid style={{ backgroundColor: "#f97b7bcc" }}>
              <FormHelperText sx={{ color: "black", m: 2, fontSize: "16px" }}>
                {errorMessage}
              </FormHelperText>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
