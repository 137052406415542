import React, { useState } from "react";
import { Typography, Grid, Paper, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import { NumericFormat } from "react-number-format";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "0.5rem 2rem",
    margin: "0.5rem 0 0.7rem 0",
    backgroundColor: "#ffffff",
    borderColor: "#a5a4a4",
  },
  textfields: {
    margin: "10px 35px",
    width: 300,
  },
  textfieldsInput: {
    fontSize: "16px",
    height: "1.2rem",
    backgroundColor: "white",
    textAlign: "center",
  },
  textFieldLabel: {
    fontSize: "16px",
  },
  realtorTextfields: {
    margin: "10px 35px",
    width: 300,
  },
  realtorPaper: {
    backgroundColor: "#d4e0f3",
    border: "1px solid #9b9b9b",
    borderRadius: "7px",
    padding: "0.25rem",
    height: "fit-content",
  },
}));

export default function BorrowerDataTab({
  loanValues,
  handleTextInputValue,
  handleNumericValues,
  handleInputValue,
  mode,
  formErrors,
}) {
  const classes = useStyles();

  const borrowerMthIncomeProps = {
    label: "Bor Total Monthly Income",
    size: "small",
    variant: "outlined",
    name: "borrowerMthIncome",
    placeholder: "Bor Total Monthly Income",
    className: classes.textfields,
    InputProps: {
      classes: { input: classes.textfieldsInput },
    },
    InputLabelProps: {
      classes: {
        root: classes.textFieldLabel,
      },
    },
  };

  const coBorrowerMthIncomeProps = {
    label: "CoBor Total Monthly Income",
    size: "small",
    variant: "outlined",
    name: "coBorrowerMthIncome",
    placeholder: "CoBor Total Monthly Income",
    className: classes.textfields,
    InputProps: {
      classes: { input: classes.textfieldsInput },
    },
    InputLabelProps: {
      classes: {
        root: classes.textFieldLabel,
      },
    },
  };

  const addtlCoBorrowerIncomeProps = {
    label: "Additional CoBorrower Income",
    size: "small",
    variant: "outlined",
    name: "addtlCoBorrowerIncome",
    placeholder: "Additional CoBorrower Income",
    className: classes.textfields,
    InputProps: {
      classes: { input: classes.textfieldsInput },
    },
    InputLabelProps: {
      classes: {
        root: classes.textFieldLabel,
      },
    },
  };

  const totalCashInBankProps = {
    label: "Total Available Cash",
    size: "small",
    variant: "outlined",
    name: "totalCashInBank",
    placeholder: "Total Available Cash",
    className: classes.textfields,
    InputProps: {
      classes: { input: classes.textfieldsInput },
    },
    InputLabelProps: {
      classes: {
        root: classes.textFieldLabel,
      },
    },
    error: !!formErrors.totalCashInBank,
    helperText: formErrors.totalCashInBank,
  };

  // FORM SECTIONS

  return (
    <div>
      <Paper variant="outlined" className={classes.paper}>
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          Borrower Information
        </Typography>
        <Grid container item direction={"row"} justifyContent={"space-between"}>
          <Grid item lg={4}>
            <TextField
              className={classes.textfields}
              variant="outlined"
              size="small"
              label="Borrower First Name"
              name="borrowerFirstName"
              placeholder="Borrower First Name"
              onChange={handleTextInputValue}
              type="text"
              error={!!formErrors.borrowerFirstName}
              helperText={formErrors.borrowerFirstName}
              disabled={mode != "CREATE-NEW"}
              required
              value={loanValues.borrowerFirstName}
              InputProps={{ classes: { input: classes.textfieldsInput } }}
              InputLabelProps={{
                classes: {
                  root: classes.textFieldLabel,
                },
              }}
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              className={classes.textfields}
              variant="outlined"
              size="small"
              label="Borrower Last Name"
              disabled={mode != "CREATE-NEW"}
              name="borrowerLastName"
              placeholder="Borrower Last Name"
              onChange={handleTextInputValue}
              type="text"
              value={loanValues.borrowerLastName}
              required
              error={!!formErrors.borrowerLastName}
              helperText={formErrors.borrowerLastName}
              InputProps={{ classes: { input: classes.textfieldsInput } }}
              InputLabelProps={{
                classes: {
                  root: classes.textFieldLabel,
                },
              }}
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              className={classes.textfields}
              variant="outlined"
              size="small"
              label="Borrower Email"
              disabled={mode != "CREATE-NEW"}
              name="borrowerEmail"
              type="email"
              placeholder="Borrower Email"
              onChange={handleTextInputValue}
              value={loanValues.borrowerEmail}
              error={!!formErrors.borrowerEmail}
              helperText={formErrors.borrowerEmail}
              required
              InputProps={{ classes: { input: classes.textfieldsInput } }}
              InputLabelProps={{
                classes: {
                  root: classes.textFieldLabel,
                },
              }}
            />
          </Grid>
          <Grid item lg={4}>
            {" "}
            <TextField
              className={classes.textfields}
              variant="outlined"
              size="small"
              label="Borrower Mobile Phone"
              disabled={mode != "CREATE-NEW"}
              name="borrowerMobilePhone"
              placeholder="Borrower Mobile Phone"
              onChange={handleInputValue}
              value={loanValues.borrowerMobilePhone}
              error={!!formErrors.borrowerMobilePhone}
              helperText={formErrors.borrowerMobilePhone}
              InputProps={{ classes: { input: classes.textfieldsInput } }}
              InputLabelProps={{
                classes: {
                  root: classes.textFieldLabel,
                },
              }}
            />
          </Grid>
          <Grid item lg={4}>
            <NumericFormat
              value={loanValues.borrowerMthIncome}
              onValueChange={(values) =>
                handleNumericValues(values, "borrowerMthIncome")
              }
              decimalScale={2}
              fixedDecimalScale
              thousandsGroupStyle="thousand"
              thousandSeparator=","
              prefix={"$"}
              customInput={TextField}
              {...borrowerMthIncomeProps}
            />
            <Grid item className={classes.textfields} lg={4}></Grid>
          </Grid>
        </Grid>
        <Grid container item direction={"row"} justifyContent={"space-between"}>
          <Grid item lg={4}>
            <TextField
              className={classes.textfields}
              variant="outlined"
              size="small"
              label="Street Address"
              name="streetAddress"
              placeholder="Street Address"
              onChange={handleTextInputValue}
              value={loanValues.streetAddress}
              InputProps={{ classes: { input: classes.textfieldsInput } }}
              InputLabelProps={{
                classes: {
                  root: classes.textFieldLabel,
                },
              }}
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              className={classes.textfields}
              variant="outlined"
              size="small"
              label="Unit #"
              name="unitNo"
              placeholder="Unit #"
              onChange={handleTextInputValue}
              value={loanValues.unitNo}
              InputProps={{ classes: { input: classes.textfieldsInput } }}
              InputLabelProps={{
                classes: {
                  root: classes.textFieldLabel,
                },
              }}
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              className={classes.textfields}
              variant="outlined"
              size="small"
              label="City"
              name="city"
              placeholder="City"
              onChange={handleTextInputValue}
              type="text"
              value={loanValues.city}
              InputProps={{ classes: { input: classes.textfieldsInput } }}
              InputLabelProps={{
                classes: {
                  root: classes.textFieldLabel,
                },
              }}
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              className={classes.textfields}
              variant="outlined"
              size="small"
              label="State"
              name="state"
              placeholder="State"
              onChange={handleTextInputValue}
              error={!!formErrors.state}
              helperText={formErrors.state}
              type="text"
              value={loanValues.state}
              InputProps={{ classes: { input: classes.textfieldsInput } }}
              InputLabelProps={{
                classes: {
                  root: classes.textFieldLabel,
                },
              }}
            />
          </Grid>
          <Grid item lg={4}>
            <TextField
              className={classes.textfields}
              variant="outlined"
              size="small"
              label="ZIP"
              name="zipCode"
              placeholder="ZIP"
              onChange={handleTextInputValue}
              error={!!formErrors.zipCode}
              helperText={formErrors.zipCode}
              value={loanValues.zipCode}
              InputProps={{ classes: { input: classes.textfieldsInput } }}
              InputLabelProps={{
                classes: {
                  root: classes.textFieldLabel,
                },
              }}
            />
            <Grid item className={classes.textfields} lg={4}></Grid>
          </Grid>
        </Grid>
        <Grid container item direction={"column"}>
          <strong>CoBorrower Information</strong>
          <Grid
            container
            item
            direction={"row"}
            justifyContent={"space-between"}
          >
            <Grid item lg={4}>
              <TextField
                className={classes.textfields}
                variant="outlined"
                size="small"
                label="CoBorrower First Name"
                name="coBorrowerFirstName"
                placeholder="CoBorrower First Name"
                onChange={handleTextInputValue}
                type="text"
                value={loanValues.coBorrowerFirstName}
                InputProps={{
                  classes: { input: classes.textfieldsInput },
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.textFieldLabel,
                  },
                }}
              />
            </Grid>
            <Grid item lg={4}>
              <TextField
                className={classes.textfields}
                variant="outlined"
                size="small"
                label="CoBorrower Last Name"
                name="coBorrowerLastName"
                placeholder="CoBorrower Last Name"
                onChange={handleTextInputValue}
                type="text"
                value={loanValues.coBorrowerLastName}
                InputProps={{
                  classes: { input: classes.textfieldsInput },
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.textFieldLabel,
                  },
                }}
              />
            </Grid>
            <Grid item lg={4}>
              <NumericFormat
                value={loanValues.coBorrowerMthIncome}
                onValueChange={(values) =>
                  handleNumericValues(values, "coBorrowerMthIncome")
                }
                decimalScale={2}
                fixedDecimalScale
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                prefix={"$"}
                customInput={TextField}
                {...coBorrowerMthIncomeProps}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction={"row"}
            justifyContent={"space-between"}
          >
            <Grid container item direction={"column"} lg={4}>
              <strong>Additional CoBorrowers</strong>
              <NumericFormat
                value={loanValues.addtlCoBorrowerIncome}
                onValueChange={(values) =>
                  handleNumericValues(values, "addtlCoBorrowerIncome")
                }
                decimalScale={2}
                fixedDecimalScale
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                prefix={"$"}
                customInput={TextField}
                {...addtlCoBorrowerIncomeProps}
              />
            </Grid>
            <Grid container item direction={"column"} lg={4}></Grid>

            <Grid container item direction={"column"} lg={4}>
              <strong>Avail Cash All Borrowers</strong>
              <NumericFormat
                value={loanValues.totalCashInBank}
                onValueChange={(values) =>
                  handleNumericValues(values, "totalCashInBank")
                }
                decimalScale={2}
                fixedDecimalScale
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                prefix={"$"}
                customInput={TextField}
                {...totalCashInBankProps}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction={"row"} style={{ margin: "1rem 0" }}>
          <Grid
            container
            item
            className={classes.realtorPaper}
            style={{ padding: "0.45rem" }}
          >
            <strong>Realtor Information</strong>
            <Grid
              container
              item
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Grid item lg={4}>
                <TextField
                  className={classes.realtorTextfields}
                  variant="outlined"
                  size="small"
                  label="Realtor First Name"
                  name="realtorFirstName"
                  placeholder="Realtor First Name"
                  onChange={handleTextInputValue}
                  type="text"
                  value={loanValues.realtorFirstName}
                  InputProps={{
                    classes: { input: classes.textfieldsInput },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.textFieldLabel,
                    },
                  }}
                />
              </Grid>
              <Grid item lg={4}>
                <TextField
                  className={classes.realtorTextfields}
                  variant="outlined"
                  size="small"
                  label="Realtor Last Name"
                  name="realtorLastName"
                  placeholder="Realtor Last Name"
                  onChange={handleTextInputValue}
                  type="text"
                  value={loanValues.realtorLastName}
                  InputProps={{
                    classes: { input: classes.textfieldsInput },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.textFieldLabel,
                    },
                  }}
                />
              </Grid>
              <Grid item lg={4}>
                {" "}
                <TextField
                  className={classes.realtorTextfields}
                  variant="outlined"
                  size="small"
                  label="Realtor Email"
                  name="realtorEmail"
                  placeholder="Realtor Email"
                  onChange={handleTextInputValue}
                  type="email"
                  value={loanValues.realtorEmail}
                  disabled={
                    loanValues.loanId != undefined &&
                    loanValues.loanId.length > 0 &&
                    loanValues.realtorId != null &&
                    loanValues.realtorId.length > 0
                  }
                  InputProps={{
                    classes: { input: classes.textfieldsInput },
                    readOnly:
                      loanValues.loanId != undefined &&
                      loanValues.loanId.length > 0 &&
                      loanValues.realtorId != null &&
                      loanValues.realtorId.length > 0,
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.textFieldLabel,
                    },
                  }}
                  error={!!formErrors.realtorEmail}
                  helperText={formErrors.realtorEmail}
                />
              </Grid>
              <Grid item lg={4}>
                <TextField
                  className={classes.realtorTextfields}
                  variant="outlined"
                  size="small"
                  label="Realtor Mobile Phone"
                  name="realtorMobilePhone"
                  placeholder="Realtor Mobile Phone"
                  onChange={handleInputValue}
                  value={loanValues.realtorMobilePhone}
                  error={!!formErrors.realtorMobilePhone}
                  helperText={formErrors.realtorMobilePhone}
                  InputProps={{
                    classes: { input: classes.textfieldsInput },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.textFieldLabel,
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
