import {
  Box,
  Button,
  Backdrop,
  CircularProgress,
  Typography,
  TextField,
  FormHelperText,
  Grid,
  Stack,
  Link,
  Container,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { postApiData } from "../core/FetchApiData";
import { ApiPaths, AppRoutes } from "../core/PathConstants";
import AlertMessage from "../core/AlertMessage";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const onSuccess = (u) => {
    setIsLoading(false);
    let date = new Date();
    date.setTime(date.getTime() + 15 * 60 * 1000);
    const dateTimestamp = date.getTime() / 1000;
    localStorage.setItem("fgt-pass-email", email);
    history.push(
      `${AppRoutes.VerifyOTPToResetPwd}?id=${u.userId}&date=${dateTimestamp}`
    );
  };

  const onLCickBack = () => {
    history.push(`${AppRoutes.Login}`);
  };

  const onError = (status, msg) => {
    setIsLoading(false);
    if (status !== 500) {
      setErrorMessage(msg);
    } else {
      setErrorMessage("Error sending code! Please try again later.");
    }
  };

  const onChangeEmail = (evt) => {
    let value = evt.target.value;
    setEmail(value);
  };

  const onSendOtp = (evt) => {
    var res = evt.currentTarget.form.reportValidity();
    if (res) {
      setErrorMessage("");
      let emailData = {
        email,
      };
      setIsLoading(true);
      postApiData(
        ApiPaths.SendOTPForPassowrd,
        "post",
        emailData,
        onSuccess,
        onError
      );
    }
  };

  return (
    <Box
      component="form"
      sx={{
        mt: 5,
        mx: "auto",
        "& .MuiTextField-root": { m: 1, width: "35ch" },
        "& .MuiButton-root": { m: 1 },
      }}
      autoComplete="off"
    >
      <Grid
        container
        display="flex"
        direction="column"
        m={2}
        justifyContent="center"
      >
        <Stack sx={{ alignItems: "center" }}>
          <Typography variant="h6" component="div">
            <img
              alt="Padzilly"
              src="images/logo-navy.svg"
              width="150"
              height="50"
            />
          </Typography>
          <Typography
            sx={{
              fontSize: "26",
              fontFamily: "American Captain Regular",
              color: "#283C8F",
            }}
            variant="h4"
            textAlign="center"
            component="div"
          >
            For Partners
          </Typography>
        </Stack>
      </Grid>

      <Grid
        container
        m={2}
        justifyContent="center"
        style={{ marginTop: "1.8rem" }}
      >
        <Grid alignItems="center">
          <Grid container item xs={12} justifyContent={"center"}>
            <TextField
              id="email"
              size="small"
              required
              type="email"
              label="Email"
              variant="outlined"
              value={email}
              onChange={onChangeEmail}
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: "1rem" }}>
            <Container maxWidth="xs">
              <Typography style={{ fontSize: "14px" }}>
                We sent you an One TIme Password (OTP) code to your email.
                Please check your spam or junk folder if it's not in your inbox.
                This can take some time. Please do not retry again to avoid
                farther delays.
              </Typography>
            </Container>
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent="flex-end"
            alignItems="center"
            direction="row"
            style={{ marginTop: "1.5rem" }}
          >
            <Button variant="contained" onClick={onLCickBack}>
              BACK
            </Button>
            <Button variant="contained" onClick={onSendOtp}>
              Send OTP
            </Button>
          </Grid>
        </Grid>
        {errorMessage && (
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            alignItems="center"
            direction="row"
            style={{ marginTop: "2rem", borderRadius: "6px" }}
          >
            <Grid style={{ backgroundColor: "#f97b7bcc" }}>
              <FormHelperText sx={{ color: "black", m: 2, fontSize: "16px" }}>
                {errorMessage}
              </FormHelperText>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
