import React, { useState, useContext } from "react";
import { DropzoneArea } from "react-mui-dropzone";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Backdrop,
  CircularProgress,
  Modal,
} from "@mui/material";
import AlertMessage from "../../../core/AlertMessage";
import { CurrentUserContext } from "../../../core/hooks/CurrentUserContext";
import { ApiPaths } from "../../../core/PathConstants";
import { getApiRootPath } from "../../../core/FetchApiData";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LoadingButton from "@mui/lab/LoadingButton";

const initialValues = {
  noteRate: 0.0,
  loanAmount: 0.0,
  originationFeePerc: 0.0,
  originationFeeAmt: 0.0,
  brokerFeePerc: 0.0,
  brokerFeeAmt: 0.0,
  lenderCreditPerc: 0.0,
  lenderCreditAmt: 0.0,
  discountPointsPerc: 0.0,
  discountPointsAmt: 0.0,
  prepaidInterestDays: 0.0,
  oneTimePMI: 0.0,
  mipOrVAFee: 0.0,
  mtgInsReserveMths: 0.0,
  homeownerInsMo: 0.0,
  hoiReserveMths: 0.0,
  propTaxReserveMths: 0.0,
  floodInsMo: 0.0,
  floodInsReserveMths: 0,
  otherInsMo: 0.0,
  otherInsReserveMths: 0,
  lenderTitleIns: 0.0,
  mtgstamps: 0.0,
  deedstamps: 0.0,
  totalAMTPaidToOthers: 0.0,
  term: 0,
  mgtInsMonthly: 0.0,
  totalHousingExpenses: 0.0,
  purchasePrice: 0.0,
  otherCreditsAmt: 0.0,
  otherCreditsPerc: 0.0,
  loanType: "",
  ltv: 0.0,
  downPaymentPerc: 0.0,
  insMtgInsPeriodFactor1: 0.0,
  allNonVariableFees: 0.0,
  borrowerFirstName: "",
  borrowerLastName: "",
  borrowerFullName: "",
  borrowerEmail: "",
  borrowerMobilePhone: "",
  borrowerId: "",
  borrowerMthIncome: 0.0,
  coBorrowerFirstName: "",
  coBorrowerLastName: "",
  coBorrowerFullName: "",
  coBorrowerMthIncome: 0.0,
  addtlCoBorrowerIncome: 0.0,
  totalCashInBank: 0.0,
  realtorFirstName: "",
  realtorLastName: "",
  realtorFullName: "",
  realtorEmail: "",
  realtorMobilePhone: "",
  realtorId: "",
  vaFirstUse: true,
  prepaidFinanceCharges: 0.0,
  propTaxMo: 0.0,
  streetAddress: "",
  unitNo: "",
  city: "",
  state: "",
  zipCode: "",
};

const theme = createTheme({
  components: {
    MuiDropzoneArea: {
      styleOverrides: {
        root: {
          minHeight: "12rem",
          padding: "0",
        },
        text: {
          marginTop: "0",
          marginBottom: "0",
        },
        icon: {
          display: "none",
        },
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  dropZoneAreaDiv: {
    margin: "0.5rem",
  },
  paper: {
    padding: "1rem",
    margin: "1em 0 0.7rem 0",
    backgroundColor: "#e4e4e4",
    borderColor: "#a5a4a4",
  },
  uploadButton: {
    margin: "1rem",
  },
  accordionDetails: {
    margin: 0,
    padding: 0,
  },
  tableHeader: {
    backgroundColor: "#1D285B",
  },
  tableCell: {
    color: "white",
    fontSize: "1rem",
  },
  tableCellContent: {
    fontSize: "1rem",
  },
  modalHeader: {
    padding: "0.5rem",
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  stepper: {
    margin: "1rem",
  },
}));

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid blue",
  boxShadow: 24,
  p: 4,
};

const errorModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid orange",
  boxShadow: 24,
  p: 3,
};

const imageModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid blue",
  boxShadow: 24,
  padding: 0,
};

const resultsHeadersStyle = {
  padding: "0.5rem 2rem",
  fontSize: "1rem",
  backgroundColor: "#f1f4fb",
  color: "#4d72dd",
  letterSpacing: "0.04rem",
  fontWeight: 600,
  border: "0.15rem solid #cdcdcd",
};

const tableStyle = {
  border: "0.15rem solid #cdcdcd",
  minWidth: 650,
};

const subHeaderStyle = {
  textAlign: "right",
};

const valuesCellStyle = {
  paddingLeft: "5rem",
};

const FINAL_PROPERTIES_TO_IGNORE = ["Borrowers Names", "Email", "Mobile Phone"];

export default function PDFUploader({
  buyerData,
  openUploadLoanModal,
  setOpenUploadLoanModal,
  loadLoanImportedFromPDF,
  mode,
  setMode,
}) {
  const { user } = useContext(CurrentUserContext);
  const classes = useStyles();
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [resultsDicitonary, setResultsDictionary] = useState({});
  const [loan, setLoan] = useState(initialValues);
  const [isParsing, setIsParsing] = useState(false);
  const [alertState, setAlertState] = useState({ show: false });

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openURLASample, setOpenURLASample] = useState(false);
  const [openItemSample, setOpenItemSample] = useState(false);
  const [openLearnMore, setOpenLearnMore] = useState(false);

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openNameAlreadyUsedErrorModal, setOpenNameAlreadyUsedErrorModal] =
    useState(false);

  const steps = ["Upload documents", "Match Fields"];

  const handleFilesUpload = () => {
    setIsParsing(true);
    const apiURL = `${getApiRootPath()}/${ApiPaths.PDFParser}`;

    const formData = new FormData();
    for (let i = 0; i < filesToUpload.length; i++) {
      formData.append("files", filesToUpload[i]);
    }
    axios
      .post(apiURL, formData, {
        headers: !user.token
          ? { "Content-Type": "application/json" }
          : {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
      })
      .then((response) => {
        prepareLoanData(response.data);
      })
      .catch((error) => {
        setIsParsing(false);
        setAlertState({
          show: true,
          message: "Error parsing document",
        });
      });
  };

  const canModifyValue = (key) => {
    return (
      mode == "CREATE-NEW" ||
      ((mode == "ADD-NEW" || mode == "EDIT-LOAN") &&
        !FINAL_PROPERTIES_TO_IGNORE.includes(key))
    );
  };

  const selectNamesValues = (propertyName, value) => {
    if (propertyName == "borrowerFullName") {
      const borrowerNameItems = getNameItems(value);
      if (resultsDicitonary["Borrowers Names"].values.length == 2) {
        const anotherBorrowerValue = resultsDicitonary[
          "Borrowers Names"
        ].values.find((v) => v != value);
        const coBorrowerNameItems = getNameItems(anotherBorrowerValue);
        setLoan({
          ...loan,
          borrowerFirstName: borrowerNameItems.firstName,
          borrowerLastName: borrowerNameItems.lastName,
          borrowerFullName: value,
          coBorrowerFirstName: coBorrowerNameItems.firstName,
          coBorrowerLastName: coBorrowerNameItems.lastName,
          coBorrowerFullName: anotherBorrowerValue,
        });
      } else {
        setLoan({
          ...loan,
          borrowerFirstName: borrowerNameItems.firstName,
          borrowerLastName: borrowerNameItems.lastName,
          borrowerFullName: value,
        });
      }
    } else if (propertyName == "coBorrowerFullName") {
      const coBorrowerNameItems = getNameItems(value);
      if (mode == "CREATE-NEW") {
        if (resultsDicitonary["CoBorrowers Names"].values.length == 2) {
          const anotherBorrowerValue = resultsDicitonary[
            "CoBorrowers Names"
          ].values.find((v) => v != value);
          const borrowerNameItems = getNameItems(anotherBorrowerValue);
          setLoan({
            ...loan,
            borrowerFirstName: borrowerNameItems.firstName,
            borrowerLastName: borrowerNameItems.lastName,
            borrowerFullName: anotherBorrowerValue,
            coBorrowerFirstName: coBorrowerNameItems.firstName,
            coBorrowerLastName: coBorrowerNameItems.lastName,
            coBorrowerFullName: value,
          });
        } else {
          setLoan({
            ...loan,
            coBorrowerFirstName: coBorrowerNameItems.firstName,
            coBorrowerLastName: coBorrowerNameItems.lastName,
            coBorrowerFullName: value,
          });
        }
      } else {
        if (value == loan.borrowerFullName) {
          setOpenNameAlreadyUsedErrorModal(true);
        } else {
          setLoan({
            ...loan,
            coBorrowerFirstName: coBorrowerNameItems.firstName,
            coBorrowerLastName: coBorrowerNameItems.lastName,
            coBorrowerFullName: value,
          });
        }
      }
    }
  };

  const selecIncomeValues = (propertyName, value) => {
    if (propertyName == "borrowerMthIncome") {
      if (resultsDicitonary["Borrower Monthly Income"].values.length == 2) {
        const anotherBorrowerValue = resultsDicitonary[
          "Borrower Monthly Income"
        ].values.find((v) => v != value);
        setLoan({
          ...loan,
          borrowerMthIncome: parseFloat(value.replace(/,/g, "")),
          coBorrowerMthIncome: parseFloat(
            anotherBorrowerValue.replace(/,/g, "")
          ),
        });
      } else {
        setLoan({
          ...loan,
          borrowerMthIncome: parseFloat(value.replace(/,/g, "")),
        });
      }
    } else if (propertyName == "coBorrowerMthIncome") {
      if (resultsDicitonary["CoBorrower Monthly Income"].values.length == 2) {
        const anotherBorrowerValue = resultsDicitonary[
          "CoBorrower Monthly Income"
        ].values.find((v) => v != value);
        setLoan({
          ...loan,
          coBorrowerMthIncome: parseFloat(value.replace(/,/g, "")),
          borrowerMthIncome: parseFloat(anotherBorrowerValue.replace(/,/g, "")),
        });
      } else {
        setLoan({
          ...loan,
          coBorrowerMthIncome: parseFloat(value.replace(/,/g, "")),
        });
      }
    }
  };

  const setLoanDictValues = (propertyName, value, type) => {
    if (
      propertyName == "borrowerFullName" ||
      propertyName == "coBorrowerFullName"
    ) {
      selectNamesValues(propertyName, value);
    } else if (
      propertyName == "borrowerMthIncome" ||
      propertyName == "coBorrowerMthIncome"
    ) {
      selecIncomeValues(propertyName, value);
    } else {
      setLoan({
        ...loan,
        [propertyName]:
          type == "number" ? parseFloat(value.replace(/,/g, "")) : value,
      });
    }
  };

  const getNameItems = (value) => {
    let lastSpaceIndex = value.lastIndexOf(" ");
    let firstName = value.substring(0, lastSpaceIndex);
    let lastName = value.substring(lastSpaceIndex + 1);
    return { firstName: firstName, lastName: lastName };
  };

  const handleClickOnChip = (propertyName, value, type) => {
    if (propertyName)
      if (propertyName != "") {
        setLoanDictValues(propertyName, value, type);
      }
  };

  const handleDeleteOnChip = (propertyName, value) => {
    if (propertyName != "") {
      if (propertyName == "borrowerFullName") {
        setLoan({
          ...loan,
          borrowerFirstName: "",
          borrowerLastName: "",
          borrowerFullName: "",
        });
      } else if (propertyName == "coBorrowerFullName") {
        setLoan({
          ...loan,
          coBorrowerFirstName: "",
          coBorrowerLastName: "",
          coBorrowerFullName: "",
        });
      } else {
        setLoan({
          ...loan,
          [propertyName]: initialValues[propertyName],
        });
      }
    }
  };

  const getPropertyName = (key) => {
    let propertyName = "";
    switch (key) {
      case "Borrowers Names":
        propertyName = "borrowerFullName";
        break;
      case "Loan Amount":
        propertyName = "loanAmount";
        break;
      case "Interest Rate":
        propertyName = "noteRate";
        break;
      case "Loan Origination Fee %":
        propertyName = "originationFeePerc";
        break;
      case "Loan Origination Fee Amt":
        propertyName = "originationFeeAmt";
        break;
      case "Broker Fee %":
        propertyName = "brokerFeePerc";
        break;
      case "Broker Fee Amt":
        propertyName = "brokerFeeAmt";
        break;
      case "Origination Credit %":
        propertyName = "lenderCreditPerc";
        break;
      case "Origination Credit Amt":
        propertyName = "lenderCreditAmt";
        break;
      case "Loan Discount Points %":
        propertyName = "discountPointsPerc";
        break;
      case "Loan Discount Points Amt":
        propertyName = "discountPointsAmt";
        break;
      case "Interest to first payment":
        propertyName = "prepaidInterestDays";
        break;
      case "Hazard Insurance Premium":
        propertyName = "homeownerInsMo";
        break;
      case "Hazard Insurance Reserves":
        propertyName = "hoiReserveMths";
        break;
      case "Flood Insurance Premium":
        propertyName = "floodInsMo";
        break;
      case "Flood Insurance Reserves":
        propertyName = "floodInsReserveMths";
        break;
      case "County Prop Tax Reserves":
        propertyName = "propTaxReserveMths";
        break;
      case "Lender Title Insurrance":
        propertyName = "lenderTitleIns";
        break;
      case "City/ County/ Stamps on Mortage":
        propertyName = "mtgstamps";
        break;
      case "State Tax/ Stamps on Deed":
        propertyName = "deedstamps";
        break;
      case "TOTAL AMT PAID TO OTHERS ON YOUR BEHALF":
        propertyName = "totalAMTPaidToOthers";
        break;
      case "Monthly Mortgage Insurance":
        propertyName = "mgtInsMonthly";
        break;
      case "Mortgage Insurance Premium":
        propertyName = "oneTimePMI";
        break;
      case "Loan Term":
        propertyName = "term";
        break;
      case "Sales Purchase Price":
        propertyName = "purchasePrice";
        break;
      case "Other Credits Amt":
        propertyName = "otherCreditsAmt";
        break;
      case "Email":
        propertyName = "borrowerEmail";
        break;
      case "Mobile Phone":
        propertyName = "borrowerMobilePhone";
        break;
      case "TOTAL PREPAID FINANCE CHARGE":
        propertyName = "prepaidFinanceCharges";
        break;
      case "Total Housing Expenses":
        propertyName = "totalHousingExpenses";
        break;
      case "County Prop Tax":
        propertyName = "propTaxMo";
        break;
      case "Borrower Monthly Income":
        propertyName = "borrowerMthIncome";
        break;
      case "CoBorrowers Names":
        propertyName = "coBorrowerFullName";
        break;
      case "CoBorrower Monthly Income":
        propertyName = "coBorrowerMthIncome";
        break;
      case "Additional CoBorrower Income":
        propertyName = "addtlCoBorrowerIncome";
        break;
      case "Available Cash All Borrowers":
        propertyName = "totalCashInBank";
        break;
      case "StreetAddress":
        propertyName = "streetAddress";
        break;
      case "UnitNo":
        propertyName = "unitNo";
        break;
      case "City":
        propertyName = "city";
        break;
      case "State":
        propertyName = "state";
        break;
      case "ZipCode":
        propertyName = "zipCode";
        break;
    }
    return propertyName;
  };

  const getBorrowerInfo = () => {
    return (
      <>
        <TableRow key={"Borrower Info"}>
          <TableCell
            component="th"
            scope="row"
            colSpan="3"
            sx={resultsHeadersStyle}
          >
            Borrower Info - click on the fields that match the primary borrower
          </TableCell>
        </TableRow>
        {resultsDicitonary.hasOwnProperty("Borrowers Names") && (
          <TableRow key={"Borrowers Names"}>
            <TableCell
              component="th"
              scope="row"
              className={classes.tableCellContent}
              sx={subHeaderStyle}
            >
              Primary borrower name
            </TableCell>
            {getValuesCell("Borrowers Names")}
          </TableRow>
        )}
        {resultsDicitonary.hasOwnProperty("Borrower Monthly Income") && (
          <TableRow key={"Borrower Monthly Income"}>
            <TableCell
              component="th"
              scope="row"
              className={classes.tableCellContent}
              sx={subHeaderStyle}
            >
              Primary borrower income
            </TableCell>
            {getValuesCell("Borrower Monthly Income")}
          </TableRow>
        )}
      </>
    );
  };

  const getBorrowerAddressInfo = () => {
    return (
      <>
        <TableRow key={"Borrower Address Info"}>
          <TableCell
            component="th"
            scope="row"
            colSpan="3"
            sx={resultsHeadersStyle}
          >
            Borrower Address Info - click on the fields that match the primary
            borrower
          </TableCell>
        </TableRow>
        {resultsDicitonary.hasOwnProperty("StreetAddress") && (
          <TableRow key={"StreetAddress"}>
            <TableCell
              component="th"
              scope="row"
              className={classes.tableCellContent}
              sx={subHeaderStyle}
            >
              Street Address
            </TableCell>
            {getValuesCell("StreetAddress")}
          </TableRow>
        )}
        {resultsDicitonary.hasOwnProperty("UnitNo") && (
          <TableRow key={"UnitNo"}>
            <TableCell
              component="th"
              scope="row"
              className={classes.tableCellContent}
              sx={subHeaderStyle}
            >
              Unit #
            </TableCell>
            {getValuesCell("UnitNo")}
          </TableRow>
        )}
        {resultsDicitonary.hasOwnProperty("City") && (
          <TableRow key={"City"}>
            <TableCell
              component="th"
              scope="row"
              className={classes.tableCellContent}
              sx={subHeaderStyle}
            >
              City
            </TableCell>
            {getValuesCell("City")}
          </TableRow>
        )}
        {resultsDicitonary.hasOwnProperty("State") && (
          <TableRow key={"State"}>
            <TableCell
              component="th"
              scope="row"
              className={classes.tableCellContent}
              sx={subHeaderStyle}
            >
              State
            </TableCell>
            {getValuesCell("State")}
          </TableRow>
        )}
        {resultsDicitonary.hasOwnProperty("ZipCode") && (
          <TableRow key={"ZipCode"}>
            <TableCell
              component="th"
              scope="row"
              className={classes.tableCellContent}
              sx={subHeaderStyle}
            >
              ZIP
            </TableCell>
            {getValuesCell("ZipCode")}
          </TableRow>
        )}
      </>
    );
  };

  const getCoBorrowerInfo = () => {
    return (
      <>
        <TableRow key={"CoBorrower Info"}>
          <TableCell
            component="th"
            scope="row"
            colSpan="3"
            sx={resultsHeadersStyle}
          >
            Co-Borrower Info - click on the fields that match the Co-borrower
          </TableCell>
        </TableRow>
        {resultsDicitonary.hasOwnProperty("CoBorrowers Names") && (
          <TableRow key={"CoBorrowers Names"}>
            <TableCell
              component="th"
              scope="row"
              className={classes.tableCellContent}
              sx={subHeaderStyle}
            >
              Co-borrower name
            </TableCell>
            {getValuesCell("CoBorrowers Names")}
          </TableRow>
        )}

        {resultsDicitonary.hasOwnProperty("CoBorrower Monthly Income") && (
          <TableRow key={"CoBorrower Monthly Income"}>
            <TableCell
              component="th"
              scope="row"
              className={classes.tableCellContent}
              sx={subHeaderStyle}
            >
              Co-borrower income
            </TableCell>
            {getValuesCell("CoBorrower Monthly Income")}
          </TableRow>
        )}
      </>
    );
  };

  const getAdditionalCoBorrowerInfo = () => {
    return (
      <>
        <TableRow key={"Additional CoBorrower Info"}>
          <TableCell
            component="th"
            scope="row"
            colSpan="3"
            sx={resultsHeadersStyle}
          >
            Additional Co-Borrower Info - click on the fields that match any
            Additional Co-borrower
          </TableCell>
        </TableRow>
        <TableRow key={"Additional CoBorrower Income"}>
          <TableCell
            component="th"
            scope="row"
            className={classes.tableCellContent}
            sx={subHeaderStyle}
          >
            Additional Co-borrower income
          </TableCell>
          {getValuesCell("Additional CoBorrower Income")}
        </TableRow>
      </>
    );
  };

  const getPrimaryContactInfo = () => {
    return (
      <>
        <TableRow key={"Primary Contact Info"}>
          <TableCell
            component="th"
            scope="row"
            colSpan="3"
            sx={resultsHeadersStyle}
          >
            Select the Primary Contact Info
          </TableCell>
        </TableRow>
        <TableRow key={"Additional CoBorrower Income"}>
          <TableCell
            align="right"
            className={classes.tableCellContent}
            colSpan="1"
          ></TableCell>
          {getValuesCell("Email")}
          {getValuesCell("Mobile Phone")}
        </TableRow>
      </>
    );
  };

  const getValuesCell = (propertyKey) => {
    return (
      <TableCell
        className={classes.tableCellContent}
        colSpan={
          propertyKey != "Email" && propertyKey != "Mobile Phone" ? "2" : "1"
        }
        sx={valuesCellStyle}
      >
        <Stack direction="row" spacing={1} justifyContent={"flex-start"}>
          {resultsDicitonary.hasOwnProperty(propertyKey) &&
            resultsDicitonary[propertyKey].values.map((value, index, array) =>
              (resultsDicitonary[propertyKey].type == "number" &&
                loan[resultsDicitonary[propertyKey].propertyName] ===
                  parseFloat(value.replace(/,/g, ""))) ||
              (resultsDicitonary[propertyKey].type != "number" &&
                loan[resultsDicitonary[propertyKey].propertyName] === value) ? (
                <Chip
                  key={resultsDicitonary[propertyKey].propertyName + value}
                  style={{ fontSize: "0.9rem" }}
                  label={
                    resultsDicitonary[propertyKey].type == "number"
                      ? parseFloat(value).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : value
                  }
                  color={canModifyValue(propertyKey) ? "primary" : "default"}
                  variant={"filled"}
                  onDelete={
                    canModifyValue(propertyKey)
                      ? () =>
                          handleDeleteOnChip(
                            resultsDicitonary[propertyKey].propertyName,
                            value
                          )
                      : () => setOpenErrorModal(true)
                  }
                />
              ) : (
                <Chip
                  key={resultsDicitonary[propertyKey].propertyName + value}
                  style={{ fontSize: "0.9rem", padding: "0.5rem" }}
                  label={
                    resultsDicitonary[propertyKey].type == "number"
                      ? parseFloat(value).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : value
                  }
                  color={canModifyValue(propertyKey) ? "primary" : "default"}
                  variant="outlined"
                  onClick={
                    canModifyValue(propertyKey)
                      ? () => {
                          handleClickOnChip(
                            resultsDicitonary[propertyKey].propertyName,
                            value,
                            resultsDicitonary[propertyKey].type
                          );
                        }
                      : () => setOpenErrorModal(true)
                  }
                />
              )
            )}
        </Stack>
      </TableCell>
    );
  };

  const getFinalResults = () => {
    return (
      <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
        <Table size="small" aria-label="a dense table" sx={tableStyle}>
          <TableBody>
            {(resultsDicitonary.hasOwnProperty("Borrowers Names") ||
              resultsDicitonary.hasOwnProperty("Borrower Monthly Income")) &&
              getBorrowerInfo()}
            {(resultsDicitonary.hasOwnProperty("StreetAddress") ||
              resultsDicitonary.hasOwnProperty("UnitNo") ||
              resultsDicitonary.hasOwnProperty("City") ||
              resultsDicitonary.hasOwnProperty("State") ||
              resultsDicitonary.hasOwnProperty("ZipCode")) &&
              getBorrowerAddressInfo()}
            {(resultsDicitonary.hasOwnProperty("CoBorrowers Names") ||
              resultsDicitonary.hasOwnProperty("CoBorrower Monthly Income")) &&
              getCoBorrowerInfo()}
            {resultsDicitonary.hasOwnProperty("Additional CoBorrower Income") &&
              getAdditionalCoBorrowerInfo()}
            {(resultsDicitonary.hasOwnProperty("Email") ||
              resultsDicitonary.hasOwnProperty("Mobile Phone")) &&
              getPrimaryContactInfo()}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const prepareLoanData = (results) => {
    let propertiesDictionary = {};
    setNewDictionaryKeys(propertiesDictionary, results);
    setNewDictionaryValues(propertiesDictionary, results);
    let tempLoan = { ...initialValues };
    Object.keys(propertiesDictionary).map((key) => {
      if (tempLoan.hasOwnProperty(propertiesDictionary[key].propertyName)) {
        let values = propertiesDictionary[key].values;
        if (typeof values !== "undefined" && values.length > 0) {
          tempLoan[propertiesDictionary[key].propertyName] =
            propertiesDictionary[key].type == "number"
              ? parseFloat(values[0].replace(/,/g, ""))
              : values[0];
        }
      }
    });
    if (
      JSON.stringify(buyerData) == "{}" ||
      typeof propertiesDictionary[`Email`] === "undefined" ||
      propertiesDictionary[`Email`] ||
      propertiesDictionary[`Email`].values.length == 0 ||
      propertiesDictionary[`Email`].values.includes(buyerData.buyerEmail)
    ) {
      if (mode != "CREATE-NEW") {
        tempLoan.borrowerFirstName = buyerData.buyerFirstName;
        tempLoan.borrowerLastName = buyerData.buyerLastName;
        tempLoan.borrowerFullName =
          buyerData.buyerFirstName + " " + buyerData.buyerLastName;
        tempLoan.borrowerEmail = buyerData.buyerEmail;
        tempLoan.borrowerMobilePhone = buyerData.mobileNumber;
        tempLoan.borrowerId = buyerData.id;
      }
      if (
        tempLoan.borrowerFullName != null &&
        (!tempLoan.borrowerFirstName ||
          tempLoan.borrowerFirstName == "" ||
          !tempLoan.borrowerLastName ||
          tempLoan.borrowerLastName == "")
      ) {
        const borrowerNameItems = getNameItems(tempLoan.borrowerFullName);
        tempLoan.borrowerFirstName = borrowerNameItems.firstName;
        tempLoan.borrowerLastName = borrowerNameItems.lastName;
      }
      if (
        tempLoan.coBorrowerFullName != null &&
        (!tempLoan.coBorrowerFirstName ||
          tempLoan.coBorrowerFirstName == "" ||
          !tempLoan.coBorrowerLastName ||
          tempLoan.coBorrowerLastName == "")
      ) {
        const borrowerNameItems = getNameItems(tempLoan.coBorrowerFullName);
        tempLoan.coBorrowerFirstName = borrowerNameItems.firstName;
        tempLoan.coBorrowerLastName = borrowerNameItems.lastName;
      }

      tempLoan.ltv = (tempLoan.loanAmount / tempLoan.purchasePrice) * 100;
      tempLoan.downPaymentPerc = 100 - tempLoan.ltv;

      setLoan(tempLoan);
      setResultsDictionary(propertiesDictionary);
      handleNext();
      setIsParsing(false);
    } else {
      setIsParsing(false);
      setAlertState({
        show: true,
        message: `None of the uploaded pdf documents belongs to buyer with email ${buyerEmail}. Check the documents you are upoading, it seems some of them belongs to buyers with another email.`,
      });
    }
  };

  const setNewDictionaryKeys = (propertiesDictionary, loanDocumentParsed) => {
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "Available Cash All Borrowers",
      "number"
    );
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "Borrowers Names",
      "text"
    );
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "CoBorrowers Names",
      "text"
    );
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "Email",
      "text"
    );
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "Mobile Phone",
      "text"
    );
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "Loan Amount",
      "number"
    );
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "Interest Rate",
      "number"
    );
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "Loan Origination Fee %",
      "number"
    );
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "Loan Origination Fee Amt",
      "number"
    );
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "Broker Fee %",
      "number"
    );
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "Broker Fee Amt",
      "number"
    );
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "Origination Credit %",
      "number"
    );
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "Origination Credit Amt",
      "number"
    );
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "Loan Discount Points %",
      "number"
    );
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "Loan Discount Points Amt",
      "number"
    );
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "Interest to first payment",
      "number"
    );
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "Hazard Insurance Premium",
      "number"
    );
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "Hazard Insurance Reserves",
      "number"
    );
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "Flood Insurance Premium",
      "number"
    );
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "Flood Insurance Reserves",
      "number"
    );
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "County Prop Tax Reserves",
      "number"
    );
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "County Prop Tax",
      "number"
    );
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "Lender Title Insurrance",
      "number"
    );
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "City/ County/ Stamps on Mortage",
      "number"
    );
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "State Tax/ Stamps on Deed",
      "number"
    );
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "TOTAL AMT PAID TO OTHERS ON YOUR BEHALF",
      "number"
    );
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "Mortgage Insurance Premium",
      "number"
    );
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "Monthly Mortgage Insurance",
      "number"
    );
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "Loan Term",
      "number"
    );
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "Sales Purchase Price",
      "number"
    );
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "Other Credits Amt",
      "number"
    );
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "Borrower Monthly Income",
      "number"
    );
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "CoBorrower Monthly Income",
      "number"
    );
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "Additional CoBorrower Income",
      "number"
    );
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "TOTAL PREPAID FINANCE CHARGE",
      "number"
    );
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "Total Housing Expenses",
      "number"
    );
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "StreetAddress",
      "text"
    );
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "UnitNo",
      "text"
    );
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "City",
      "text"
    );
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "State",
      "text"
    );
    setNewDictionaryKey(
      propertiesDictionary,
      loanDocumentParsed,
      "ZipCode",
      "text"
    );
  };

  const setNewDictionaryKey = (
    propertiesDictionary,
    loanDocumentParsed,
    key,
    type
  ) => {
    if (loanDocumentParsed.hasOwnProperty(key)) {
      let propertyName = getPropertyName(key);
      propertiesDictionary[key] = {
        propertyName: propertyName,
        values: [],
        type: type,
      };
    }
  };

  const setNewDictionaryValues = (propertiesDictionary, loanDocumentParsed) => {
    for (let propertyKey in loanDocumentParsed) {
      if (propertiesDictionary.hasOwnProperty(propertyKey)) {
        for (let valueKey in loanDocumentParsed[propertyKey]) {
          if (
            !propertiesDictionary[propertyKey].values.includes(
              loanDocumentParsed[propertyKey][valueKey]
            )
          ) {
            propertiesDictionary[propertyKey].values.push(
              loanDocumentParsed[propertyKey][valueKey]
            );
          }
        }
      }
    }
  };

  const resetData = () => {
    setFilesToUpload([]);
    setActiveStep(0);
    setResultsDictionary({});
    setLoan(initialValues);
  };

  const handleFinish = async () => {
    const loanParsed = { ...loan };
    setOpenConfirmModal(false);
    setOpenUploadLoanModal(false);
    loadLoanImportedFromPDF(loanParsed);
    resetData();
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const getURLASampleModal = () => {
    return (
      <Modal open={openURLASample} onClose={() => setOpenURLASample(false)}>
        <Box sx={imageModalStyle}>
          <img
            src="images/Sample URLA.jpg"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </Box>
      </Modal>
    );
  };

  const getItemSampleModal = () => {
    return (
      <Modal open={openItemSample} onClose={() => setOpenItemSample(false)}>
        <Box sx={imageModalStyle}>
          <img
            src="images/Sample Item.png"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </Box>
      </Modal>
    );
  };

  const getLearnMoreModal = () => {
    return (
      <Modal
        open={openLearnMore}
        onClose={() => setOpenLearnMore(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography variant="h6" component="h2" style={{ fontWeight: "600" }}>
            Types of PDFs
          </Typography>
          <Typography style={{ fontWeight: "600", fontSize: "1rem" }}>
            PDF documents can be categorized into two different types.
          </Typography>
          <Typography sx={{ mt: 2 }}>
            <span style={{ color: "blue" }}>
              <strong>TRUE</strong> or <strong>SEARCHABLE</strong> -
            </span>{" "}
            <span style={{ color: "blue", textDecoration: "underline" }}>
              This is the type of file you need to upload so Padzilly can read
              it
            </span>
            . A searchable PDF is created by choosing the "SAVE TO PDF" option
            from within your loan origination software.
          </Typography>
          <Typography sx={{ mt: 2 }}>
            <span style={{ color: "red" }}>
              <strong>IMAGE-ONLY</strong> -
            </span>{" "}
            <span style={{ color: "red", textDecoration: "underline" }}>
              Padzilly cannot read this type of PDF
            </span>
            . It is not legible by software until it is converted to a
            searchable format. Image-only PDFs are usually created by choosing
            the "PRINT TO PDF" option and are{" "}
            <span style={{ color: "red", textDecoration: "underline" }}>
              not suitable to upload
            </span>
            .
          </Typography>
        </Box>
      </Modal>
    );
  };

  const getChangePrimaryBuyerErrorModal = () => {
    return (
      <Modal
        open={openErrorModal || openNameAlreadyUsedErrorModal}
        onClose={() => {
          setOpenErrorModal(false);
          setOpenNameAlreadyUsedErrorModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={errorModalStyle}>
          <Grid container direction={"row"} style={{ alignItems: "center" }}>
            <ErrorOutlineIcon
              style={{ marginRight: "0.5rem", color: "#e85711" }}
            />
            <Typography
              variant="h6"
              component="h2"
              style={{ fontWeight: "600", color: "#e85711" }}
            >
              Warning
            </Typography>
          </Grid>

          <Typography style={{ fontWeight: "600", fontSize: "1rem" }}>
            {openErrorModal ? (
              <span>
                Primary borrower information such as name and contact details
                cannot be modified once the loan has been created.
              </span>
            ) : (
              <span>
                The name you're trying to select is already associated with the
                primary borrower of this loan. Once a loan is created, the
                primary borrower's information, such as name and contact
                details, cannot be modified. Therefore, this name cannot be
                chosen as a co-borrower.
              </span>
            )}
          </Typography>
        </Box>
      </Modal>
    );
  };

  const handleNextOrFinish = () => {
    if (activeStep === steps.length - 1) setOpenConfirmModal(true);
    else handleNext();
  };

  return (
    <div className={classes.dropZoneAreaDiv}>
      <Dialog
        fullWidth={true}
        open={openUploadLoanModal}
        onClose={() => {
          setOpenUploadLoanModal(false);
          setMode("CREATE-NEW");
          resetData();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"xl"}
        height={"50rem"}
      >
        <DialogTitle className={classes.modalHeader} id="alert-dialog-title">
          UPLOAD LOAN FROM PDF
        </DialogTitle>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};

                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>
        </Box>
        <DialogContent>
          <div>
            <div style={{ display: activeStep == 0 ? "block" : "none" }}>
              <ThemeProvider theme={theme}>
                <DropzoneArea
                  acceptedFiles={["application/pdf"]}
                  showPreviews={true}
                  showPreviewsInDropzone={false}
                  useChipsForPreview
                  Icon={null}
                  dropzoneText={
                    <Box
                      px={16}
                      py={6}
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      style={{ padding: "24px 128px" }}
                    >
                      <Typography variant="h5">
                        CLICK HERE OR DRAG AND DROP LOAN DOCUMENTS
                      </Typography>
                      <Box mt={1} style={{ margin: "2rem" }}>
                        <CloudUploadIcon style={{ transform: "scale(2.8)" }} />
                      </Box>
                      <Grid style={{ fontSize: "20px", color: "#33469d" }}>
                        <ol>
                          <li style={{ textAlign: "left" }}>
                            <Grid
                              container
                              justifyContent={"flex-start"}
                              alignItems={"baseline"}
                            >
                              Full URLA for each borrower -{" "}
                              <Button
                                style={{
                                  fontSize: "20px",
                                  color: "#33469d",
                                  textTransform: "none",
                                  textDecoration: "underline",
                                  border: "0",
                                  backgroundColor: "white",
                                  padding: "0 0.5rem",
                                }}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setOpenURLASample(true);
                                }}
                              >
                                sample
                              </Button>
                            </Grid>
                          </li>
                          <li style={{ textAlign: "left" }}>
                            <Grid
                              container
                              justifyContent={"flex-start"}
                              alignItems={"baseline"}
                            >
                              Itemization of Amount Financed document -{" "}
                              <Button
                                style={{
                                  fontSize: "20px",
                                  color: "#33469d",
                                  textTransform: "none",
                                  textDecoration: "underline",
                                  border: "0",
                                  backgroundColor: "white",
                                  padding: "0 0.5rem",
                                }}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setOpenItemSample(true);
                                }}
                              >
                                sample
                              </Button>
                            </Grid>
                          </li>
                        </ol>
                      </Grid>
                      <Box mt={1}>
                        <Grid
                          container
                          direction={"column"}
                          justifyContent={"flex-start"}
                          alignItems={"center"}
                        >
                          <Typography
                            style={{ color: "red", fontSize: "22px" }}
                          >
                            IMPORTANT: The PDFs must be SEARCHABLE, where the
                            text can be digitally read.
                          </Typography>
                          <Button
                            style={{
                              fontSize: "22px",
                              color: "red",
                              textTransform: "none",
                              textDecoration: "underline",
                              border: "0",
                              backgroundColor: "white",
                              padding: "0 0.5rem",
                            }}
                            onClick={(event) => {
                              event.stopPropagation();
                              setOpenLearnMore(true);
                            }}
                          >
                            Learn more
                          </Button>
                        </Grid>
                      </Box>
                    </Box>
                  }
                  previewGridProps={{
                    container: {
                      spacing: 1,
                      direction: "row",
                    },
                  }}
                  previewChipProps={{ classes: { root: classes.previewChip } }}
                  previewText="Selected files"
                  onChange={(files) => setFilesToUpload(files)}
                  filesLimit={8}
                  maxFileSize={31457280}
                />
              </ThemeProvider>
            </div>

            {/* Final Resuts to verify */}
            <div style={{ display: activeStep == 1 ? "block" : "none" }}>
              {getFinalResults()}
            </div>
          </div>
        </DialogContent>
        <DialogActions style={{ padding: "0", margin: "1rem" }}>
          <Grid container item xs={12} justifyContent="flex-end">
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                variant="contained"
                className={classes.uploadButton}
                onClick={() => {
                  setOpenUploadLoanModal(false);
                  setMode("CREATE-NEW");
                  resetData();
                }}
              >
                Close
              </Button>
              <Button
                variant="contained"
                style={{ display: activeStep == 0 ? "none" : "block" }}
                className={classes.uploadButton}
                onClick={handleBack}
              >
                Back
              </Button>
              <Button
                onClick={() => handleNextOrFinish()}
                style={{ display: activeStep == 0 ? "none" : "block" }}
                className={classes.uploadButton}
                variant="contained"
              >
                {activeStep === steps.length - 1
                  ? "load values in loan form"
                  : "Next"}
              </Button>
              <LoadingButton
                style={{ display: activeStep == 0 ? "block" : "none" }}
                onClick={handleFilesUpload}
                className={classes.uploadButton}
                variant="contained"
                disabled={filesToUpload.length == 0}
                loading={isParsing}
              >
                UPLOAD DOCUMENTS AND VIEW RESULTS
              </LoadingButton>
            </Box>
          </Grid>
        </DialogActions>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isParsing}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>

      <Dialog
        open={openConfirmModal}
        onClose={() => {
          setOpenConfirmModal(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"xl"}
      >
        <DialogTitle className={classes.modalHeader} id="alert-dialog-title">
          CONFIRM USE UPLOADED DATA
        </DialogTitle>
        <DialogContent>
          <div style={{ padding: "2rem 1rem 0 1rem" }}>
            <Typography variant="h6">
              Do you want to load the selected data into "Add Loan" form?
            </Typography>
          </div>
        </DialogContent>
        <DialogActions style={{ padding: "0", margin: "1rem" }}>
          <Grid container item xs={12} justifyContent="flex-end">
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                variant="contained"
                className={classes.uploadButton}
                onClick={() => {
                  setOpenConfirmModal(false);
                }}
              >
                Back
              </Button>
              <Button
                onClick={() => handleFinish()}
                className={classes.uploadButton}
                variant="contained"
              >
                Confirm
              </Button>
            </Box>
          </Grid>
        </DialogActions>
      </Dialog>

      {getURLASampleModal()}
      {getItemSampleModal()}
      {getLearnMoreModal()}
      {getChangePrimaryBuyerErrorModal()}
      <AlertMessage alert={alertState} />
    </div>
  );
}
