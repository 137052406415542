import React, { useState } from "react";
import {
  Button,
  Grid,
  Typography,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  AppBar,
  Toolbar,
  Avatar,
  TextField,
  Checkbox,
  Radio,
  DialogActions,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Alert } from "@mui/material";
import { Snackbar } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { noImageSrc, noLogoSrc } from "../../core/validationFunctions";

const UICustomizationSetup = ({
  handleChange,
  loadImage,
  isValidLogo,
  setIsValidLogo,
  updateThemeColors,
  formErrors,
  deleteImage,
  values: {
    state,
    logoImage,
    themePrimaryColor,
    themeSecondaryColor,
    chromeExtensionPropertyPagePopupText,
    chromeExtensionHomePagePopupText,
  },
}) => {
  const [open, setOpen] = useState(false);

  const [primaryColor, setThemePrimaryColor] = useState(themePrimaryColor);
  const [secondaryColor, setThemeSecondaryColor] =
    useState(themeSecondaryColor);

  const innerTheme = createTheme({
    palette: {
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: secondaryColor,
      },
    },
  });

  const handleChangePC = (e) => {
    const value = e.target.value;
    setThemePrimaryColor(value);
  };

  const handleChangeSC = (e) => {
    const value = e.target.value;
    setThemeSecondaryColor(value);
  };

  const handleSaveChanges = () => {
    updateThemeColors(primaryColor, secondaryColor);
    handleClose();
  };

  const imageFormatTooltipText =
    "Upload an image .jpg, .gif, .png format. Recommended size is 300px x 150px";

  const imageTooltipText = `
Recomendation: Use an image with one of the following aspect ratio 4:1, 3:1, 2:1.
`;
  const themeToolTipText = `
Select primary and secondary colors to customize the company website theme.
`;
  const handleClickOpen = () => {
    setThemePrimaryColor(themePrimaryColor);
    setThemeSecondaryColor(themeSecondaryColor);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ marginBottom: "25px" }}>
      <Grid
        direction="row"
        container
        spacing={3}
        style={{ margin: "5px 0 5px", marginBottom: "25px" }}
      >
        <Grid
          container
          item
          lg={4}
          md={5}
          xs={12}
          direction="column"
          style={{ marginRight: "50px", paddingTop: 0 }}
        >
          <Grid item>
            <Typography variant="h6">
              <strong>Theme</strong>
            </Typography>
          </Grid>
          <Grid direction="column" item container marginTop={"10px"}>
            <Grid item>
              <Paper
                style={{
                  marginTop: "10px",
                  marginBottom: "20px",
                  height: "200px",
                  width: "250px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
                variant="outlined"
                square
              >
                <img
                  alt="Logo"
                  style={{ maxHeight: "60%", width: "100%" }}
                  src={logoImage || noLogoSrc}
                />
              </Paper>
            </Grid>
            <Grid
              container
              alignSelf="center"
              alignItems="center"
              direction="row"
              item
              style={{ marginBottom: "25px" }}
            >
              <Button
                style={{ width: "170px", marginRight: "5px" }}
                variant="contained"
                color="primary"
                component="label"
              >
                Upload Logo
                <input
                  onChange={loadImage}
                  id="image-uploader"
                  type="file"
                  hidden
                  accept="image/x-png,image/gif,image/jpeg"
                />
              </Button>
              <Tooltip title={imageTooltipText} color="primary">
                <HelpOutlineIcon />
              </Tooltip>
              <Button variant="text" color="primary" component="label">
                <DeleteIcon onClick={deleteImage} fontSize="large" />
              </Button>
            </Grid>
            <Grid
              item
              container
              alignSelf="center"
              alignItems="center"
              direction="row"
            >
              <Button
                onClick={() => handleClickOpen(state)}
                style={{ width: "170px", marginRight: "5px" }}
                variant="contained"
                color="primary"
                component="label"
              >
                Customize Theme
              </Button>
              <Tooltip title={themeToolTipText} color="primary">
                <HelpOutlineIcon />
              </Tooltip>
            </Grid>
            <Grid style={{ marginTop: "20px" }}>
              <span>
                <Typography variant={"caption"}>
                  {imageFormatTooltipText}
                </Typography>
              </span>
            </Grid>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={!isValidLogo}
              autoHideDuration={6000}
              onClose={() => setIsValidLogo(true)}
            >
              <Alert severity="error">
                The logo you are trying to upload does not match the supported
                file types (.gif, .png,. jpg, .jpeg, .svg and be smaller than 3
                MB). Please try again.
              </Alert>
            </Snackbar>
          </Grid>
        </Grid>
        <Grid container item lg={1} md={1} xs={1} direction="column"></Grid>
        <Grid
          container
          item
          lg={6}
          md={6}
          xs={12}
          direction="column"
          style={{ display: "none" }}
        >
          <Grid item>
            <Typography variant="h5">
              <strong>Padzilly Chrome Extension</strong>
            </Typography>
          </Grid>
          <Grid
            container
            direction="column"
            item
            marginRight={"10px"}
            marginTop={"10px"}
          >
            <Grid item xs={12} md={12}>
              <div>
                <TextField
                  style={{ width: "80%", margin: "10px 10px 10px 5px" }}
                  variant="outlined"
                  size="small"
                  label="Home Page Popup Text"
                  name="chromeExtensionHomePagePopupText"
                  placeholder="Home Page Popup Text"
                  value={chromeExtensionHomePagePopupText || ""}
                  onChange={handleChange}
                  error={!!formErrors.chromeExtensionHomePagePopupText}
                  helperText={formErrors.chromeExtensionHomePagePopupText}
                />
              </div>

              <div>
                <TextField
                  style={{ width: "80%", margin: "10px 10px 10px 5px" }}
                  variant="outlined"
                  size="small"
                  label="Property Page Popup Text"
                  name="chromeExtensionPropertyPagePopupText"
                  placeholder="Property Page Popup Text"
                  value={chromeExtensionPropertyPagePopupText || ""}
                  onChange={handleChange}
                  error={!!formErrors.chromeExtensionPropertyPagePopupText}
                  helperText={formErrors.chromeExtensionPropertyPagePopupText}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Theme Customization
        </DialogTitle>
        <DialogContent dividers>
          <Grid container direction="row">
            <Grid
              item
              container
              direction="column"
              xs={6}
              md={6}
              justifyContent="center"
              alignContent="center"
            >
              <div>
                <FormControlLabel
                  style={{ margin: "0 3px 0 0px" }}
                  control={
                    <input
                      value={primaryColor}
                      name="themePrimaryColor"
                      onChange={handleChangePC}
                      color="primary"
                      type="color"
                      style={{ margin: ".4rem" }}
                    />
                  }
                  label="Primary color"
                />
              </div>
              <div>
                <FormControlLabel
                  style={{ margin: "0 3px 0 0px" }}
                  control={
                    <input
                      value={secondaryColor}
                      name="themeSecondaryColor"
                      onChange={handleChangeSC}
                      color="primary"
                      type="color"
                      style={{ margin: ".4rem" }}
                    />
                  }
                  label="Secondary color"
                />
              </div>
            </Grid>
            <Grid item container direction="column" xs={6} md={6}>
              <ThemeProvider theme={innerTheme}>
                <Paper variant={"outlined"}>
                  <AppBar position={"static"}>
                    <Toolbar style={{ minHeight: "40px" }}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <img alt="Logo" src={logoImage || ""} height="20px" />
                        <Avatar
                          style={{ height: "30px", width: "30px" }}
                          sx={{ bgcolor: "secondary.main" }}
                        ></Avatar>
                      </Grid>
                    </Toolbar>
                  </AppBar>
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <TextField
                      style={{ margin: "20px 10px 10px 5px" }}
                      variant="outlined"
                      size="small"
                      label="Text Field"
                      name="textField"
                      placeholder="Text Field"
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ margin: "0 10px 5px 5px" }}
                    >
                      Button
                    </Button>
                    <Button
                      size="medium"
                      style={{ margin: 5 }}
                      color="primary"
                      startIcon={<AddCircleOutlineIcon />}
                    >
                      Table Button
                    </Button>

                    <div>
                      <Checkbox
                        checked={true}
                        name="checkedB"
                        color="primary"
                      />
                      <Checkbox
                        checked={false}
                        name="checkedB"
                        color="primary"
                      />
                      <Radio
                        checked={true}
                        color="primary"
                        name="radio-button-demo"
                      />
                      <Radio
                        checked={false}
                        color="primary"
                        name="radio-button-demo"
                      />
                    </div>
                  </Grid>
                </Paper>
              </ThemeProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid style={{ margin: "10px 30px 5px" }}>
            <div>
              <Button
                style={{ margin: "0 10px 10px 10px" }}
                variant="contained"
                color="primary"
                onClick={handleClose}
              >
                Cancel
              </Button>

              <Button
                style={{ margin: "0 10px 10px 10px" }}
                variant="contained"
                color="primary"
                onClick={handleSaveChanges}
              >
                OK
              </Button>
            </div>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default UICustomizationSetup;
