import { Avatar, Box, Typography } from "@mui/material";
import React, { useEffect, useState, useRef, useContext } from "react";
import { CurrentUserContext } from "../../core/hooks/CurrentUserContext";
import { useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import { ApiPaths, AppRoutes } from "../../core/PathConstants";
import { fetchApiData } from "../../core/FetchApiData";
import { propertySrc } from "../../core/validationFunctions";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import { RiHotelBedLine } from "react-icons/ri";
import { MdOutlineBathtub } from "react-icons/md";
import { LiaToiletSolid } from "react-icons/lia";
import { PiHouseBold } from "react-icons/pi";
import DisclaimerButton from "./DisclaimerButton";
import { Loading } from "../../core/Loading";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";

const rootStyle = (theme) => ({
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
  },
  display: "grid",
});

const propertyDiv = {
  border: "0.5rem solid black",
};

const avatarDiv = {
  width: "100%",
  height: "200px",
  marginRight: "10px",
  border: "1px",
};

export default function BuyerSavedProperties() {
  const { user } = useContext(CurrentUserContext);
  const { buyerId } = useParams();
  const buyerName = new URLSearchParams(location.search).get("buyerName");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useState(0);
  const [alertState, setAlertState] = useState({ show: false });
  const pageSize = 30;

  useEffect(() => {
    if (buyerId !== null && buyerId !== "") {
      fetchSavedProperties();
    }
  }, []);

  const fetchSavedProperties = () => {
    setIsLoading(true);
    fetchApiData(
      `${ApiPaths.BuyerSavedProperties}?page=${page}&pageSize=${pageSize}&userId=${buyerId}`,
      setData,
      onSuccessLoadingProperties,
      onErrorLoadingProperties,
      user.token
    );
  };

  const onSuccessLoadingProperties = (isLoading) => {
    setIsLoading(isLoading);
    setIsError(false);
  };

  const onErrorLoadingProperties = (status, msg) => {
    setAlertState({
      show: true,
      message: "Error fetching this buyer saved properties",
    });
    setIsError(true);
  };

  const propertiesList = () => {
    return (
      <Box
        component={"div"}
        sx={(theme) => ({
          marginTop: "2rem",
          display: "grid",
          gap: "1rem",
          gridAutoRows: "1fr",
          [theme.breakpoints.down("xs")]: {
            gridTemplateColumns: "1fr",
          },
          [theme.breakpoints.between("sm", "md")]: {
            gridTemplateColumns: "repeat(2, 1fr)",
          },
          [theme.breakpoints.between("md", "xl")]: {
            gridTemplateColumns: "repeat(3, 1fr)",
          },
          "@media (min-width: 1280px)": {
            gridTemplateColumns: "repeat(3, 1fr)",
          },
        })}
      >
        {data &&
          data.map((property) => (
            <Box
              component={"div"}
              sx={{
                boxShadow: "0px 1px 4px hsl(0deg 9.76% 16.08% / 28%)",
                borderRadius: "0.55rem",
              }}
              key={property.propertyId}
            >
              <div>
                <img
                  style={{
                    width: "100%",
                    aspectRatio: "16/9",
                    objectFit: "cover",
                    borderRadius: "0.55rem 0.55rem 0 0",
                    backgroundColor: "#efefef",
                  }}
                  src={property?.media?.[0]?.mediaUrl ?? propertySrc}
                ></img>
              </div>
              <div>
                <Box
                  component={"div"}
                  sx={{ display: "flex", flexDirection: "row" }}
                >
                  <Box
                    component={"div"}
                    sx={{
                      width: "100%",
                      padding: "0.25rem 0.5rem",
                      fontWeight: "bold",
                      fontSize: "1.2rem",
                    }}
                  >
                    {Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(property.price)}
                  </Box>
                  <Box
                    component={"div"}
                    sx={{ display: "flex", flexDirection: "row" }}
                  >
                    <img
                      style={{ height: "2.5rem", with: "5rem" }}
                      src={property?.mlsLogoURl}
                      loading="lazy"
                    />
                    <DisclaimerButton property={property} />
                  </Box>
                </Box>
                <Box
                  component={"div"}
                  sx={{
                    width: "100%",
                    padding: "0.25rem 0.5rem",
                    fontWeight: "bold",
                    fontSize: "0.9rem",
                  }}
                >
                  {property.propertyType}
                </Box>

                <Box
                  component={"div"}
                  sx={{
                    width: "100%",
                    padding: "0.25rem 0.5rem",
                    fontSize: "0.9rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FmdGoodOutlinedIcon fontSize="small" />
                  {property.fullAddress}, {property.city}, {property.state}{" "}
                  {property.zipCode}{" "}
                </Box>
                <Box
                  component={"div"}
                  sx={{
                    padding: "0.25rem 0.5rem",
                    display: "flex",
                    gap: "0.5rem",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component={"div"}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <Box
                      component={"div"}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "0.8rem",
                        fontWeight: "bold",
                      }}
                    >
                      <RiHotelBedLine size={"2em"} />
                      {property.bedrooms}
                    </Box>
                    <Box
                      component={"div"}
                      sx={{ fontSize: "0.8rem", textAlign: "center" }}
                    >
                      Beds
                    </Box>
                  </Box>
                  <Box
                    component={"div"}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <Box
                      component={"div"}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "0.8rem",
                        fontWeight: "bold",
                      }}
                    >
                      <MdOutlineBathtub size={"2em"} />
                      {property.bathroomsFull}
                    </Box>
                    <Box
                      component={"div"}
                      sx={{ fontSize: "0.8rem", textAlign: "center" }}
                    >
                      Full Baths
                    </Box>
                  </Box>
                  <Box
                    component={"div"}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <Box
                      component={"div"}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "0.8rem",
                        fontWeight: "bold",
                      }}
                    >
                      <LiaToiletSolid size={"2em"} />
                      {property.bathroomsHalf}
                    </Box>
                    <Box
                      component={"div"}
                      sx={{ fontSize: "0.8rem", textAlign: "center" }}
                    >
                      Half Baths
                    </Box>
                  </Box>
                  <Box
                    component={"div"}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <Box
                      component={"div"}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "0.8rem",
                        fontWeight: "bold",
                      }}
                    >
                      <PiHouseBold size={"2em"} />
                      {property.livingArea}
                    </Box>
                    <Box
                      component={"div"}
                      sx={{ fontSize: "0.8rem", textAlign: "center" }}
                    >
                      SQFT
                    </Box>
                  </Box>
                </Box>
              </div>
            </Box>
          ))}
      </Box>
    );
  };

  const noData = () => {
    return (
      <>
        <Box
          component={"div"}
          sx={{ display: "flex", margin: "8rem", flexDirection: "column" }}
        >
          <Box
            component={"div"}
            sx={{
              display: "flex",
              justifyContent: "center",
              margin: "2rem",
              color: "#8e8e8e",
            }}
          >
            <SearchOutlinedIcon
              fontSize="large"
              sx={{ transform: "scale(2)" }}
            />
          </Box>
          <Typography
            variant="h4"
            sx={{ textAlign: "center", color: "#8e8e8e" }}
          >
            {buyerName} doesn't have any saved property
          </Typography>
        </Box>
      </>
    );
  };

  const errorMessage = () => {
    return (
      <>
        <Box
          component={"div"}
          sx={{ display: "flex", margin: "8rem", flexDirection: "column" }}
        >
          <Box
            component={"div"}
            sx={{
              display: "flex",
              justifyContent: "center",
              margin: "2rem",
              color: "#8e8e8e",
            }}
          >
            <ErrorOutlinedIcon
              fontSize="large"
              sx={{ transform: "scale(2)" }}
            />
          </Box>
          <Typography
            variant="h4"
            sx={{ textAlign: "center", color: "#8e8e8e" }}
          >
            There was a problem loading {buyerName}'s saved properties . Please
            check your connection and try again
          </Typography>
        </Box>
      </>
    );
  };

  return (
    <Box component={"div"} sx={rootStyle}>
      <Loading isLoading={isLoading} />
      <Box component={"div"}>
        <Link
          to={AppRoutes.MLOLoansList}
          style={{ color: "black", textDecoration: "none" }}
        >
          <Box component={"div"} sx={{ display: "flex", flexDirection: "row" }}>
            <ArrowBackIcon sx={{ color: "black" }} />
            <Typography
              variant="subtitle1"
              sx={{ color: "black", textDecoration: "none" }}
            >
              Back
            </Typography>
          </Box>
        </Link>
      </Box>
      <Box component={"div"} sx={{ marginTop: "1rem" }}>
        <Typography variant="h5">{buyerName}'s Saved Properties</Typography>
      </Box>
      {isError
        ? errorMessage()
        : isLoading
        ? noData()
        : data && data.length > 0
        ? propertiesList()
        : noData()}
    </Box>
  );
}
