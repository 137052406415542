import React, { useState } from "react";
import {
  Button,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
} from "@mui/material";

const MloSetup = ({
  handleBack,
  handleNext,
  handleChange,
  values: {
    canMloUploadImage,
    canMloChangeMobilePhone,
    canMloChangeOfficePhone,
    canMloChangeTitle,
    canMloChangePersonalWebsite,
    canMloChangeSocialMediaLinks,
    allowBuydownOption,
    allowSMSCommuncation,
    canEditDefaultApprovalLetter,
  },
}) => {
  return (
    <div>
      <Grid
        container
        spacing={3}
        style={{ margin: "5px 0 5px", marginBottom: "5px" }}
      >
        <Grid item xs={12}>
          <Typography variant="h6">
            <strong>MLO Setup</strong>
          </Typography>
        </Grid>
      </Grid>

      <Grid container direction="column" style={{ margin: "0 10px 5px 10px" }}>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            style={{ margin: "0 3px 0 0px" }}
            control={
              <Checkbox
                name="canMloUploadImage"
                checked={canMloUploadImage}
                onChange={handleChange}
                color="primary"
              />
            }
            label="Can upload their image (recommended)"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            style={{ margin: "0 3px 0 0px" }}
            control={
              <Checkbox
                name="canMloChangeMobilePhone"
                checked={canMloChangeMobilePhone}
                onChange={handleChange}
                color="primary"
              />
            }
            label="Add and modify their mobile phone number"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            style={{ margin: "0 3px 0 0px" }}
            control={
              <Checkbox
                name="canMloChangeOfficePhone"
                checked={canMloChangeOfficePhone}
                onChange={handleChange}
                color="primary"
              />
            }
            label="Add and modify their office phone number"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            style={{ margin: "0 3px 0 0px" }}
            control={
              <Checkbox
                name="canMloChangeTitle"
                checked={canMloChangeTitle}
                onChange={handleChange}
                color="primary"
              />
            }
            label="Add and modify their title"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            style={{ margin: "0 3px 0 0px" }}
            control={
              <Checkbox
                name="canMloChangePersonalWebsite"
                checked={canMloChangePersonalWebsite}
                onChange={handleChange}
                color="primary"
              />
            }
            label="Add and modify their personal website"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            style={{ margin: "0 3px 0 0px" }}
            control={
              <Checkbox
                name="canMloChangeSocialMediaLinks"
                checked={canMloChangeSocialMediaLinks}
                onChange={handleChange}
                color="primary"
              />
            }
            label="Add and modify their social media links"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            style={{ margin: "0 3px 0 0px" }}
            control={
              <Checkbox
                name="canEditDefaultApprovalLetter"
                checked={canEditDefaultApprovalLetter}
                onChange={handleChange}
                color="primary"
              />
            }
            label="Can edit and customize the default approval letter"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            style={{ margin: "0 3px 0 0px", display: "none" }}
            control={
              <Checkbox
                name="allowBuydownOption"
                checked={allowBuydownOption}
                onChange={handleChange}
                color="primary"
              />
            }
            label="Selectively permit buydown option to borrowers"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            style={{ margin: "0 3px 10px 0px", display: "none" }}
            control={
              <Checkbox
                name="allowSMSCommuncation"
                checked={allowSMSCommuncation}
                onChange={handleChange}
                color="primary"
              />
            }
            label="Allow SMS communication from borrowers through app"
          />
        </Grid>
      </Grid>
    </div>
  );
};
export default MloSetup;
