import { imageValidation, noImageSrc } from "./validationFunctions";
import React, { useState } from "react";
import { Button, Grid, Paper, Snackbar } from "@mui/material";
import { Alert } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

/**
 *
 * @boxHeight {number} boxHeight Height of the picture box - default 200
 * @boxWidth {number} boxWidth Width of the picture box - default 200
 * @buttonLabel {string} Label for the Button - default 'Upload'
 * @maxSize {number} Max size allowd for the picture in MBs - default 2
 * @setData {any} function ref to set data object state
 * @data {any} data object
 * @fieldName {string} fieldName name field in data object  - default 'picture'
 *
 */
export const PictureBox = ({
  boxHeight,
  boxWidth,
  buttonLabel,
  maxSize,
  setData,
  data,
  fieldName,
  deleteOption = false,
  originalSize = false,
}) => {
  const [isValidPic, setIsValidPic] = useState(true);

  const loadImage = (e) => {
    const f = fieldName ?? "picture";
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      let imageFile = e.target.files[0];
      const reader = new FileReader();
      if (!imageValidation(imageFile, maxSize ?? 2)) {
        setIsValidPic(false);
        return;
      }

      reader.onload = (x) => {
        if (setData && data) {
          setData({
            ...data,
            [f]: x.target.result,
          });
        }
      };

      reader.readAsDataURL(imageFile);
    } else {
      if (setData && data) {
        setData({
          ...data,
          [f]: noImageSrc,
        });
      }
    }
  };

  const deleteImage = () => {
    const f = fieldName ?? "picture";
    if (setData && data) {
      setData({
        ...data,
        [f]: "",
      });
    }
  };

  return (
    <Grid container alignItems="center" direction="column">
      <Grid item>
        <Paper
          style={{ height: boxHeight ?? 200, width: boxWidth ?? 200 }}
          variant="outlined"
          square
        >
          <img
            style={{
              height: "100%",
              width: "100%",
              objectFit: originalSize ? "contain" : "fill",
            }}
            src={data?.[fieldName ?? "picture"] || noImageSrc}
          />
        </Paper>
      </Grid>
      <Grid item>
        <Button
          style={{ marginTop: "10px" }}
          variant="contained"
          color="primary"
          component="label"
        >
          {buttonLabel || "Upload"}
          <input
            onChange={loadImage}
            id="image-uploader"
            type="file"
            hidden
            accept="image/x-png,image/gif,image/jpeg"
          />
        </Button>
        {deleteOption && (
          <Button
            style={{ marginTop: "10px" }}
            variant="text"
            color="primary"
            component="label"
          >
            <DeleteIcon onClick={deleteImage} fontSize="large" />
          </Button>
        )}
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!isValidPic}
        autoHideDuration={6000}
        onClose={() => setIsValidPic(true)}
      >
        <Alert severity="error">
          The Picture you are trying to upload does not match the supported file
          types (.gif, .png, .jpg, .jpeg, .svg) and/or is not smaller than{" "}
          {maxSize} MBs. Please try again.
        </Alert>
      </Snackbar>
    </Grid>
  );
};
