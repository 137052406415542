const apiProjectPrefix = "api";

export const ApiPaths = {
  Login: `${apiProjectPrefix}/Auth/login`,
  LogOut: `${apiProjectPrefix}/Auth/logout`,
  Refresh: `${apiProjectPrefix}/Auth/refresh`,
  ForgotPwd: `${apiProjectPrefix}/Auth/forgotpwd`,
  ChangePwd: `${apiProjectPrefix}/Auth/changepwd`,
  ChangeResetPwd: `${apiProjectPrefix}/Auth/change-reset-pwd`,
  VerifyOTP: `${apiProjectPrefix}/Auth/verify-user`,
  ResendOTP: `${apiProjectPrefix}/Auth/resend-otp`,
  SignUp: `${apiProjectPrefix}/Auth/sign-up`,
  SendOTPForPassowrd: `${apiProjectPrefix}/Auth/sendpwdotp`,

  States: `${apiProjectPrefix}/states`,
  Company: `${apiProjectPrefix}/company`,
  CompanyUICustomization: `${apiProjectPrefix}/company/customization/ui`,
  UserProfile: `${apiProjectPrefix}/user`,

  MLOriginatorDetails: `${apiProjectPrefix}/mlo/details`,

  CheckBuyerByEmail: `${apiProjectPrefix}/buyer/check-by-email`,
  MLOBuyers: `${apiProjectPrefix}/buyer/mlo-buyers`,
  MLOPendingBuyers: `${apiProjectPrefix}/buyer/mlo-pending-buyers`,

  PDFParser: `${apiProjectPrefix}/loans/pdf-parser`,
  Loans: `${apiProjectPrefix}/loans`,
  BuyerSavedProperties: `${apiProjectPrefix}/listedProperties/get-favorite-properties`,
  ToggleDisclosureRate: `${apiProjectPrefix}/investmentProfile/update-disclosure-rate-option`,
};

export const AppRoutes = {
  Home: "/",
  Help: "/help",

  Login: "/auth/login",
  CheckEmail: "/auth/check-email",
  VerifyOTP: "/auth/verify-code",
  VerifyOTPToResetPwd: "/auth/verify-code-to-reset-password",
  ForgotPasswprd: "/auth/forgotPassword",
  ChangeFirstPassword: "/auth/changefirstpassword",
  ChangeResetPassword: "/auth/change-reset-password",
  SignUp: "/auth/sign-up",
  UserProfile: "/user/profile",
  UserChangePassword: "/user/changepassword",

  MLOHome: "/mlo/home",
  MLODetails: "/mlo/profile",
  MLOLoansList: "/mlo/buyers",
  CompanyConfig: "/company/config",
  UICustomization: "/company/uiConfig",
  MLOBuyeProperties: "/mlo/buyers/:buyerId",

  // MLONotifications: "/mlo/config/notifications",
  // MLOAppImages: "/mlo/config/appImages",
  // MLOBuyersList: "/mlo/config/buyerslist",

  // MLOInvite: "/mlo/config/invite/:tabValue",
  // MLOCommunications: "/mlo/config/communications",

  // RealtorDetails: "/realtor/config/details",
  // RealtorNotifications: "/realtor/config/notifications",
  // RealtorHome: "/realtor/home",
  // RealtorAppImages: "/realtor/config/appImages",
  // RealtorInvite: "/realtor/config/invite/:tabValue",
  // BuyerProperties: "/homes/:buyerId",

  // LenderHome: "/lender/home",
  // UsersSetup: "/lender/users/:tabValue",
};

export const ExternalUrls = {
  LearnMoreLoadingPage:
    "https://app.groove.cm/groovevideo/videopage/183332/mskha2624e56ef67d4cbbdb1cd7ed9f2c74",
  DealMakerTool:
    "https://app.groove.cm/groovevideo/videopage/183107/mskha2624e56ef67d4cbbdb1cd7ed9f2c747c",
  LearnMoreCalcList:
    "https://app.groove.cm/groovevideo/videopage/183107/mskha2624e56ef67d4cbbdb1cd7ed9f2c747c",
};
