import React, { useState, useEffect } from "react";
import CountyTaxRulesSetup from "./CountyTaxRulesSetup";
import { Button, Grid, Typography, TextField, Divider } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  current: {
    fontWeight: 700,
    fontSize: "14px",
    color: "white",
  },
  currentButton: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const TaxRulesSetup = ({
  lendingStates,
  updateAllTaxRules,
  updateStateTaxRules,
  values,
}) => {
  const [defaultMinReportedTax, setDefaultMinReportedTax] = useState(
    values.reportedTaxPercent
  );
  const [defaultOfferPricePercent, setDefaultOfferPricePercent] = useState(
    values.offerPricePercent
  );

  const [defaultReportedTaxes, setDefaultReportedTaxes] = useState(
    values.actualReportedTaxes
  );
  const [defaultCustomTaxes, setDefaultCustomTaxes] = useState(
    !values.actualReportedTaxes
  );

  const [disableDefault, setDisableDefault] = useState(
    !values.actualReportedTaxes
  );
  const [disableActualDefault, setDisableActualDefault] = useState(
    values.actualReportedTaxes
  );

  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenDefaultCustom = () => {
    setOpen(true);
    setDefaultReportedTaxes(false);
    setDefaultCustomTaxes(true);
  };

  const handleDefaultReportedTaxes = () => {
    setOpen(true);
    setDefaultReportedTaxes(true);
    setDefaultCustomTaxes(false);
  };

  const handleOverwrite = () => {
    setOpen(false);
    if (defaultCustomTaxes) {
      updateAllTaxRules(false, defaultMinReportedTax, defaultOfferPricePercent);
      setDisableDefault(true);
      setDisableActualDefault(false);
    } else {
      updateAllTaxRules(true, 0, 0);
      setDisableActualDefault(true);
      setDisableDefault(false);
    }
  };

  const handleEditTExtFields = (e) => {
    let field = e.target.name;
    let valueMinReportedTax =
      field == "defaultMinReportedTax" ? e.target.value : defaultMinReportedTax;
    let valueDOfferPricePercent =
      field == "defaultOfferPricePercent"
        ? e.target.value
        : defaultOfferPricePercent;

    if (defaultCustomTaxes) {
      updateAllTaxRules(false, valueMinReportedTax, valueDOfferPricePercent);
      setDisableDefault(true);
      setDisableActualDefault(false);
    }
  };

  return (
    <div>
      <Grid container spacing={3} style={{ margin: "5px 0 0 5px" }}>
        <Grid item xs={12}>
          <Typography variant="h6">
            <strong>Property Tax Rules</strong>
          </Typography>
          <Typography style={{ margin: "10px 30px 0 0px" }} variant="subtitle1">
            <strong>
              These rules determine how to calculate property taxes for the
              qualification payment and allow a calculation rule to be applied
              in cases where the actual reported tax is unrealistically low.
            </strong>
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ margin: "5px 0 0 5px" }}>
        <Grid
          style={{
            padding: "30px",
            backgroundColor: "#ececec",
            marginRight: "50px",
            marginLeft: "20px",
          }}
        >
          <Grid item xs={12} style={{ marginBottom: "20px" }}>
            <Typography
              style={{ margin: "0", fontWeight: 700 }}
              variant="subtitle1"
            >
              <strong>
                The below default will apply to ALL STATES and Counties. Once a
                default is set, custom rules can then be modified down to County
                level.
              </strong>
            </Typography>
            <Grid style={{ fontWeight: 700, color: "#dc1125" }}>
              <strong>
                WARNING: Setting a new default below will overwrite all custom
                tax calculation settings.
              </strong>
            </Grid>
          </Grid>
          <Divider />

          <Grid
            item
            xs={12}
            style={{
              margin: "0 5px 0 0px",
              fontWeight: 700,
              padding: "10px 0",
            }}
            variant="subtitle1"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <Button
                disabled={disableDefault}
                className={disableDefault ? classes.currentButton : ""}
                style={{
                  height: "25px",
                  width: "250px",
                  margin: "10px 5px 5px 5px",
                }}
                variant="outlined"
                onClick={handleOpenDefaultCustom}
              >
                <div className={disableDefault ? classes.current : ""}>
                  {" "}
                  {disableDefault ? "CURRENT DEFAULT" : "MAKE THIS THE DEFAULT"}
                </div>
              </Button>

              <h4 style={{ margin: "0", fontWeight: "700" }}>
                If the reported tax is less than{" "}
              </h4>
              <TextField
                style={{ margin: "0 3px 0 3px", width: "60px" }}
                inputProps={{
                  style: {
                    padding: 5,
                  },
                  min: 0.0,
                  max: 100,
                  step: 0.01,
                }}
                onChange={(e) => {
                  const { value } = e.target;
                  setDefaultMinReportedTax(value);
                  handleEditTExtFields(e);
                }}
                onKeyPress={(e) => {
                  e.preventDefault();
                  return false;
                }}
                type="number"
                variant="outlined"
                padding="2px"
                size="small"
                name="defaultMinReportedTax"
                value={defaultMinReportedTax}
              />
              <h4 style={{ margin: "0" }}>% of the listed price, use </h4>
              <TextField
                style={{ margin: "0 3px 0 3px", width: "60px" }}
                inputProps={{
                  style: {
                    padding: 5,
                  },
                  min: 0.0,
                  max: 100,
                  step: 0.01,
                }}
                onChange={(e) => {
                  const { value } = e.target;
                  setDefaultOfferPricePercent(value);
                  handleEditTExtFields(e);
                }}
                onKeyPress={(e) => {
                  e.preventDefault();
                  return false;
                }}
                type="number"
                variant="outlined"
                size="small"
                name="defaultOfferPricePercent"
                value={defaultOfferPricePercent}
              />
              <h4 style={{ margin: "0", fontWeight: "700" }}>
                <strong>% of the offer price instead of</strong>
              </h4>
              <h4 style={{ margin: "0" }}>
                <strong> the actual reported property tax. </strong>
              </h4>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <Button
                disabled={disableActualDefault}
                className={disableActualDefault ? classes.currentButton : ""}
                style={{
                  height: "25px",
                  width: "250px",
                  margin: "10px 5px 5px 5px",
                }}
                variant="outlined"
                onClick={handleDefaultReportedTaxes}
              >
                <div className={disableActualDefault ? classes.current : ""}>
                  {" "}
                  {disableActualDefault
                    ? "CURRENT DEFAULT"
                    : "MAKE THIS THE DEFAULT"}
                </div>
              </Button>
              <h4 style={{ margin: "0", fontWeight: "700" }}>
                Use actual reported taxes to calculate payment.
              </h4>
            </div>
          </Grid>
          <Grid item xs={12} style={{ margin: "0 5px 0 5px", padding: "5px" }}>
            <h4 style={{ margin: "0 0 5px 0", padding: "0 0 10px 0" }}>
              The system will prompt to configure a property tax rule to new
              states added after initial setup
            </h4>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <CountyTaxRulesSetup
          lendingStates={lendingStates}
          values={values}
          updateStateTaxRules={updateStateTaxRules}
        />
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">WARNING</DialogTitle>
        <DialogContent style={{ borderTop: "1px solid rgba(0, 0, 0, 0.12)" }}>
          <DialogContentText id="alert-dialog-description">
            <div style={{ padding: "7px", fonSize: "18px" }}>
              Changing This System Wide Default Will Overwrite All Current
              Custom Tax Calculation Settings!
            </div>

            <div style={{ padding: "7px", fontWeight: 700 }}>
              Are You Sure You Want to proceed And Overwrite All state tax
              setting ? This Cannot Be Undone
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid style={{ margin: "10px 10px 5px 10px" }}>
            <Button
              style={{ margin: "0 10px 10px 10px" }}
              onClick={handleClose}
              variant="contained"
              autoFocus
            >
              GO BACK
            </Button>
            <Button
              style={{ margin: "0 10px 10px 10px" }}
              color="primary"
              onClick={handleOverwrite}
              variant="contained"
            >
              OVERWRITE
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TaxRulesSetup;
