import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@mui/styles";
import AlertMessage from "../../core/AlertMessage";
import { fetchApiData, postApiData } from "../../core/FetchApiData";
import { ApiPaths, AppRoutes } from "../../core/PathConstants";
import _ from "lodash";
import { currencyFormatter } from "../../core/Calculations";
import { formValidation } from "../../core/validationFunctions";
import { Loading } from "../../core/Loading";
import PropTypes from "prop-types";
import BorrowerDataTab from "./manual-loan-tab-panel/BorrowerDataTab";
import LoanItemizationDataTab from "./manual-loan-tab-panel/LoanItemizationDataTab";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PDFUploader from "./upload-documents/PDFUploader";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { CurrentUserContext } from "../../core/hooks/CurrentUserContext";
import { Link } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import BaseYesNoSwitch from "../../core/base-components/BaseYesNoSwitch";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
    },
  },
  modalHeader: {
    padding: "0.5rem",
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
}));

const initialValues = {
  loanId: "",
  noteRate: 0.0,
  loanAmount: 0.0,
  originationFeePerc: 0.0,
  originationFeeAmt: 0.0,
  brokerFeePerc: 0.0,
  brokerFeeAmt: 0.0,
  lenderCreditPerc: 0.0,
  lenderCreditAmt: 0.0,
  discountPointsPerc: 0.0,
  discountPointsAmt: 0.0,
  prepaidInterestDays: 0.0,
  oneTimePMI: 0.0,
  mipOrVAFee: 0.0,
  mtgInsReserveMths: 0.0,
  homeownerInsMo: 0.0,
  hoiReserveMths: 0.0,
  propTaxReserveMths: 0.0,
  floodInsMo: 0.0,
  floodInsReserveMths: 0,
  otherInsMo: 0.0,
  otherInsReserveMths: 0,
  lenderTitleIns: 0.0,
  mtgstamps: 0.0,
  deedstamps: 0.0,
  totalAMTPaidToOthers: 0.0,
  term: 0,
  mgtInsMonthly: 0.0,
  totalHousingExpenses: 0.0,
  purchasePrice: 0.0,
  otherCreditsAmt: 0.0,
  otherCreditsPerc: 0.0,
  loanType: "",
  ltv: 0.0,
  downPaymentPerc: 0.0,
  insMtgInsPeriodFactor1: 0.0,
  allNonVariableFees: 0.0,
  borrowerFirstName: "",
  borrowerLastName: "",
  borrowerFullName: "",
  borrowerEmail: "",
  borrowerMobilePhone: "",
  borrowerId: "",
  borrowerMthIncome: 0.0,
  coBorrowerFirstName: "",
  coBorrowerLastName: "",
  coBorrowerFullName: "",
  coBorrowerMthIncome: 0.0,
  addtlCoBorrowerIncome: 0.0,
  totalCashInBank: 0.0,
  realtorFirstName: "",
  realtorLastName: "",
  realtorFullName: "",
  realtorEmail: "",
  realtorMobilePhone: "",
  realtorId: "",
  vaFirstUse: true,
  prepaidFinanceCharges: 0.0,
  propTaxMo: 0.0,
  streetAddress: "",
  unitNo: "",
  city: "",
  state: "",
  zipCode: "",
};

const fieldsValidation = {
  borrowerFirstName: {
    error: "",
    validate: "text",
    minLength: 1,
    maxLength: 150,
  },
  borrowerLastName: {
    error: "",
    validate: "text",
    minLength: 1,
    maxLength: 150,
  },
  state: {
    error: "",
    validate: "text",
    minLength: 0,
    maxLength: 2,
  },
  zipCode: {
    error: "",
    validate: "number",
    minLength: 0,
    maxLength: 5,
  },
  borrowerEmail: {
    error: "",
    validate: "email",
    minLength: 1,
    maxLength: 150,
  },
  realtorEmail: {
    error: "",
    validate: "email",
    minLength: 0,
    maxLength: 150,
  },
  borrowerMobilePhone: {
    error: "",
    validate: "phone",
    minLength: 0,
    maxLength: 20,
  },
  realtorMobilePhone: {
    error: "",
    validate: "phone",
    minLength: 0,
    maxLength: 20,
  },
  purchasePrice: {
    error: "",
    validate: "biggerThanZero",
  },
  loanAmount: {
    error: "",
    validate: "biggerThanZero",
  },
  noteRate: {
    error: "",
    validate: "biggerThanZero",
  },
  term: {
    error: "",
    validate: "biggerThanZero",
  },
  totalHousingExpenses: {
    error: "",
    validate: "biggerThanZero",
  },
  totalCashInBank: {
    error: "",
    validate: "biggerThanZero",
  },
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function Loans() {
  const { user } = useContext(CurrentUserContext);
  const classes = useStyles();

  const [buyersData, setBuyersData] = useState([]);
  const [pendingBuyersData, setPendingBuyersData] = useState([]);

  const [loanValues, setLoanValues] = useState(initialValues);

  const [isLoading, setIsLoading] = useState(false);

  const [alertState, setAlertState] = useState({ show: false });

  const [openAddLoanModal, setOpenAddLoanModal] = useState(false);
  const [openUploadLoanModal, setOpenUploadLoanModal] = useState(false);

  const [isSaving, setIsSaving] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const [buyerData, setBuyerData] = useState({});

  const addEL = useRef(null);

  const [value, setValue] = React.useState(0);
  const [mode, setMode] = useState("CREATE-NEW");

  const onError = (status, msg) => {
    let errorMessage = "Error retrieving data from Padzilly";
    if (status !== 500) {
      errorMessage = msg;
    }
    setAlertState({
      show: true,
      message: errorMessage,
    });
    setIsSaving(false);
  };

  const onErrorSaving = (status, msg) => {
    let errorMessage = "Error saving data";
    if (status !== 500) {
      errorMessage = msg;
    }
    setAlertState({
      show: true,
      message: errorMessage,
    });
    setIsSaving(false);
  };

  const hiddenSubmitButtonRef = useRef(null);

  const fetchBuyers = () => {
    fetchApiData(
      ApiPaths.MLOBuyers,
      setBuyersData,
      setIsLoading,
      onError,
      user.token
    );
  };

  const fetchPendingBuyers = () => {
    fetchApiData(
      ApiPaths.MLOPendingBuyers,
      setPendingBuyersData,
      setIsLoading,
      onError,
      user.token
    );
  };

  const fetchLoan = (loanId) => {
    fetchApiData(
      `${ApiPaths.Loans}?id=${loanId}`,
      setLoanValues,
      setIsLoading,
      onError,
      user.token
    );
  };

  useEffect(() => {
    fetchBuyers();
    fetchPendingBuyers();
  }, []);

  const buyersCols = [
    {
      field: "buyerName",
      headerName: "Name",
      width: 250,
      renderCell: (params) => {
        return (
          <Link
            to={`${AppRoutes.MLOBuyeProperties.replace(
              ":buyerId",
              params.row.id
            )}?buyerName=${params.row.buyerName}`}
            key={params.row.id}
            style={{ color: "black" }}
          >
            {params.row.buyerName}
          </Link>
        );
      },
    },
    { field: "mobileNumber", headerName: "Mobile Number", width: 200 },
    { field: "buyerEmail", headerName: "Email", width: 200 },
    {
      field: "loanAmount",
      headerName: "Loan Amount",
      width: 170,
      renderCell: (params) => {
        return currencyFormatter(params.value);
      },
    },
    { field: "loanType", headerName: "Loan Type", width: 150 },
    {
      field: "loanId",
      headerName: "",
      width: 170,
      renderCell: (params) => {
        const onClickUpload = (e) => {
          e.stopPropagation();
          if (params.row.loanId.includes("-NO_LOAN")) {
            setMode("ADD-NEW");
          } else {
            setMode("EDIT-LOAN");
            setLoanValues({ loanId: params.row.loanId });
          }
          setBuyerData({
            buyerFirstName: params.row.buyerFirstName,
            buyerLastName: params.row.buyerLastName,
            buyerEmail: params.row.buyerEmail,
            mobileNumber: params.row.mobileNumber,
            id: params.row.id,
          });
          setOpenUploadLoanModal(true);
        };

        const onClickEdit = (e) => {
          e.stopPropagation();
          setMode("EDIT-LOAN");
          fetchLoan(params.row.loanId);
          onOpenAddLoanModal(true, false, null, "EDIT-LOAN");
        };

        const onClickAdd = (e) => {
          e.stopPropagation();
          setMode("ADD-NEW");
          setBuyerData({
            buyerFirstName: params.row.buyerFirstName,
            buyerLastName: params.row.buyerLastName,
            buyerEmail: params.row.buyerEmail,
            mobileNumber: params.row.mobileNumber,
            id: params.row.id,
          });
          onOpenAddLoanModal(false, false, params.row, "ADD-NEW");
        };

        return (
          <div>
            <IconButton
              ref={addEL}
              aria-label="Edit"
              style={{
                color: "#6c6868",
              }}
              onClick={(event) => {
                event.ignore = true;
                onClickUpload(event);
              }}
              size="large"
              title="Upload PDF"
            >
              <UploadFileIcon />
            </IconButton>

            {/* <IconButton
              aria-label="Add Manual Loan"
              style={{
                color: !params.row.loanId.includes("-NO_LOAN")
                  ? "#bebebe"
                  : "#6c6868",
              }}
              disabled={!params.row.loanId.includes("-NO_LOAN")}
              onClick={(event) => {
                event.ignore = true;
                onClickAdd(event);
              }}
              size="large"
              title="Add Manual Loan"
            >
              <AddIcon />
            </IconButton> */}

            <IconButton
              aria-label="Edit"
              style={{
                color: params.row.loanId.includes("-NO_LOAN")
                  ? "#bebebe"
                  : "#6c6868",
              }}
              disabled={params.row.loanId.includes("-NO_LOAN")}
              onClick={(event) => {
                event.ignore = true;
                onClickEdit(event);
              }}
              size="large"
              title="Edit Loan"
            >
              <EditIcon />
            </IconButton>
          </div>
        );
      },
    },
    {
      field: "shouldShowDisclosureRate",
      headerName: "Disclosure Rate?",
      width: 150,
      renderCell: (params) => {
        return (
          <BaseYesNoSwitch
            rowId={params.row.id}
            checked={params.row.shouldShowDisclosureRate}
            onHandleChange={onChangeDisclosureRateSwitch}
          />
        );
      },
    },
  ];

  const onChangeDisclosureRateSwitch = (userId, value) => {
    postApiData(
      `${ApiPaths.ToggleDisclosureRate}?userId=${userId}&showDisclosureRate=${value}`, //change
      "PUT",
      null,
      null,
      onErrorUpdatingDisclosureRateOption,
      user.token
    );
  };

  const onErrorUpdatingDisclosureRateOption = () => {
    setAlertState({
      show: true,
      message: "Error updating disclosure rate option",
    });
  };

  const pendingBuyersCols = [
    {
      field: "buyerName",
      headerName: "Name",
      width: 250,
    },
    { field: "mobileNumber", headerName: "Mobile Number", width: 200 },
    { field: "buyerEmail", headerName: "Email", width: 200 },
    {
      field: "loanAmount",
      headerName: "Loan Amount",
      width: 170,
      renderCell: (params) => {
        return currencyFormatter(params.value);
      },
    },
    { field: "loanType", headerName: "Loan Type", width: 150 },
    {
      field: "loanId",
      headerName: "",
      width: 170,
      renderCell: (params) => {
        const onClickUpload = (e) => {
          e.stopPropagation();
          setMode("EDIT-LOAN");
          setBuyerData({
            buyerFirstName: params.row.buyerFirstName,
            buyerLastName: params.row.buyerLastName,
            buyerEmail: params.row.buyerEmail,
            mobileNumber: params.row.mobileNumber,
            id: params.row.id,
          });
          setLoanValues({ loanId: params.row.loanId });
          setOpenUploadLoanModal(true);
        };

        const onClickEditPending = (e) => {
          e.stopPropagation();
          setMode("EDIT-LOAN");
          fetchLoan(params.row.loanId);
          onOpenAddLoanModal(true, false, null, "EDIT-LOAN");
        };

        return (
          <div>
            <IconButton
              ref={addEL}
              aria-label="Edit"
              style={{ color: "#6c6868" }}
              onClick={(event) => {
                event.ignore = true;
                onClickUpload(event);
              }}
              size="large"
              title="Upload PDF"
            >
              <UploadFileIcon />
            </IconButton>
            <IconButton
              aria-label="Edit"
              style={{
                color: params.row.loanId.includes("-NO_LOAN")
                  ? "#bebebe"
                  : "#6c6868",
              }}
              disabled={params.row.loanId.includes("-NO_LOAN")}
              onClick={(event) => {
                event.ignore = true;
                onClickEditPending(event);
              }}
              size="large"
              title="Edit Loan"
            >
              <EditIcon />
            </IconButton>
          </div>
        );
      },
    },
  ];

  const showBuyers = () => {
    return (
      <div style={{ height: "25rem", width: "100%" }}>
        <DataGrid
          className={classes.root}
          rows={buyersData}
          disableSelectionOnClick
          columns={buyersCols}
          getRowId={(row) => row.id}
        />
      </div>
    );
  };

  const showPendingBuyers = () => {
    return (
      <div style={{ height: "20rem", width: "100%" }}>
        <DataGrid
          className={classes.root}
          rows={pendingBuyersData}
          disableSelectionOnClick
          columns={pendingBuyersCols}
          getRowId={(row) => row.loanId}
        />
      </div>
    );
  };

  const onOpenAddLoanModal = (editMode, importPDF, buyer, modeToCheck) => {
    if (!editMode && !importPDF) {
      if (modeToCheck == "CREATE-NEW") {
        setLoanValues(initialValues);
      } else {
        let values = { ...initialValues };
        values.borrowerFirstName = buyer.buyerFirstName;
        values.borrowerLastName = buyer.buyerLastName;
        values.borrowerFullName =
          buyer.buyerFirstName + " " + buyer.buyerLastName;
        values.borrowerEmail = buyer.buyerEmail;
        values.borrowerMobilePhone = buyer.mobileNumber;
        values.borrowerId = buyer.id;
        setLoanValues(values);
      }
    }
    setOpenAddLoanModal(true);
    setFormErrors({});
  };

  const [isValidType, setIsValidType] = useState(true);
  const [areValidValues, setAreValidValues] = useState(true);

  const onOpenLoanTypeError = (isValidLoanType, areValidNumericValues, ltv) => {
    setIsValidType(isValidLoanType);
    setAreValidValues(areValidNumericValues);
    if (!areValidNumericValues && isValidLoanType && ltv) {
      setAlertState({
        show: true,
        message: "The highlighted fields values should be bigger than zero",
      });
      setIsSaving(false);
    }
    if (areValidNumericValues && !isValidLoanType && ltv) {
      setAlertState({
        show: true,
        message: "Must select loan type",
      });
      setIsSaving(false);
    }
    if (!areValidNumericValues && !isValidLoanType && ltv) {
      setAlertState({
        show: true,
        message:
          "Must select loan type and the highlighted fields values should be bigger than zero",
      });
      setIsSaving(false);
    }
    if (areValidNumericValues && isValidLoanType && !ltv) {
      setAlertState({
        show: true,
        message: "Please check the values related to the calculation of LTV",
      });
      setIsSaving(false);
    }
  };

  const onIsValidError = () => {
    setAlertState({
      show: true,
      message: "Please check the required fields and the ones with errors",
    });
  };

  const onOpenEmailPhoneError = () => {
    setAlertState({
      show: true,
      message: "Must check the emails and phone numbers format",
    });
    setIsSaving(false);
  };

  const onAddLoan = () => {
    let loanToSave = { ...loanValues };
    loanToSave.ltv = getLoanLTV();
    loanToSave.downPaymentPerc = 100 - loanToSave.ltv;
    loanToSave.insMtgInsPeriodFactor1 = getValidNumericValue(
      loanValues.mgtInsMonthly / loanValues.loanAmount
    );
    loanToSave.mipOrVAFee = mipOrVaFee();
    loanToSave.oneTimePMI =
      loanToSave.loanType == "Conventional" && loanToSave.oneTimePMI > 1
        ? 0
        : loanToSave.oneTimePMI;
    postApiData(
      ApiPaths.Loans, //change
      "POST",
      loanToSave,
      onAddLoanComplete,
      onErrorSaving,
      user.token
    );
  };

  const onEditLoan = () => {
    let loanToSave = { ...loanValues };
    loanToSave.ltv = getLoanLTV();
    loanToSave.downPaymentPerc = 100 - loanToSave.ltv;
    loanToSave.insMtgInsPeriodFactor1 = getValidNumericValue(
      loanValues.mgtInsMonthly / loanValues.loanAmount
    );
    loanToSave.mipOrVAFee = mipOrVaFee();
    loanToSave.oneTimePMI =
      loanToSave.loanType == "Conventional" && loanToSave.oneTimePMI > 1
        ? 0
        : loanToSave.oneTimePMI;
    postApiData(
      ApiPaths.Loans, //change
      "PUT",
      loanToSave,
      onAddLoanComplete,
      onErrorSaving,
      user.token
    );
  };

  const getLoanLTV = () => {
    return loanValues.loanType == "FHA" || loanValues.loanType == "VA"
      ? ((loanValues.loanAmount - loanValues.oneTimePMI) /
          loanValues.purchasePrice) *
          100
      : (loanValues.loanAmount / loanValues.purchasePrice) * 100;
  };

  const mipOrVaFee = () => {
    if (loanValues.loanType === "FHA" || loanValues.loanType === "VA") {
      return (
        loanValues.oneTimePMI / (loanValues.loanAmount - loanValues.oneTimePMI)
      );
    } else return 0;
  };

  const onAddLoanComplete = async (data) => {
    setAlertState({
      show: true,
      message: "Loan successfully saved!",
      type: "success",
    });
    setLoanValues(initialValues);
    setOpenAddLoanModal(false);
    setBuyerData({});
    setMode("CREATE-NEW");
    setValue(0);
    fetchBuyers();
    fetchPendingBuyers();
    await delay(1000);
    setIsSaving(false);
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  // HANDLE INPUTS

  const handleTextInputValue = (e) => {
    let { name, value, type } = e.target;

    if (type == "text" && name != "zipCode") {
      value = value.replace(/[^a-zA-Z\s]/g, "");
    }

    setLoanValues({
      ...loanValues,
      [name]: value,
    });
    if (name in fieldsValidation) {
      const error = formValidation(name, value, fieldsValidation) || "";

      setFormErrors((prev) => ({
        ...prev,
        [name]: error,
      }));
    }
  };

  const handleNumericValues = (values, name) => {
    let value = values.value == "" || values.value == null ? 0 : values.value;

    setLoanValues({
      ...loanValues,
      [name]: parseFloat(value),
    });
    if (name in fieldsValidation) {
      const error = formValidation(name, value, fieldsValidation) || "";

      setFormErrors((prev) => ({
        ...prev,
        [name]: error,
      }));
    }
  };

  const getValidNumericValue = (value) => {
    return isNaN(value) ||
      value === null ||
      value === Infinity ||
      value === -Infinity
      ? 0.0
      : value;
  };

  const handleInputValue = (e) => {
    let { name, value } = e.target;
    setLoanValues({
      ...loanValues,
      [name]: value,
    });
    if (name in fieldsValidation) {
      const error = formValidation(name, value, fieldsValidation) || "";
      setFormErrors((prev) => ({
        ...prev,
        [name]: error,
      }));
    }
  };

  const handleCheckLoanType = (e) => {
    let { value, checked } = e.target;

    const loanType = checked ? value : "";
    if (loanType != "") {
      setIsValidType(true);
    }

    setLoanValues({
      ...loanValues,
      loanType: loanType,
      vaFirstUse: true,
    });
  };

  const handleCheckHasSinglePMI = (e) => {
    let { value, checked } = e.target;
    setLoanValues({
      ...loanValues,
      oneTimePMI: checked ? 1.0 : 0.0,
    });
  };

  const handleCheckHasUsedVABefore = (e) => {
    let { value, checked } = e.target;

    setLoanValues({
      ...loanValues,
      vaFirstUse: !checked,
    });
  };

  //ADD LOAN TABS

  const handleChange = (event, newValue) => {
    if (value == 0) {
      checkRequiredBuyerData();
    } else {
      setValue(newValue);
    }
  };

  const steps = [
    { label: "Borrower Information" },
    { label: "Loan Qualification Parameters" },
  ];

  const totalSteps = steps.length;

  const handleNext = () => setValue((newValue) => newValue + 1);
  const handleBack = () => setValue((newValue) => newValue - 1);

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const clickSubmitHiddenButton = () => {
    if (hiddenSubmitButtonRef.current) {
      hiddenSubmitButtonRef.current.click();
    }
  };

  const isValidLoanType = () => {
    const validLoanType = loanValues.loanType !== "";
    return validLoanType;
  };

  const areValiedNumericValues = () => {
    let error = {};
    if (!loanValues.noteRate || loanValues.noteRate <= 0) {
      const errorValidation = formValidation("noteRate", 0, fieldsValidation);
      error["noteRate"] = errorValidation;
    }
    if (!loanValues.purchasePrice || loanValues.purchasePrice <= 0) {
      const errorValidation = formValidation(
        "purchasePrice",
        0,
        fieldsValidation
      );
      error["purchasePrice"] = errorValidation;
    }
    if (!loanValues.loanAmount || loanValues.loanAmount <= 0) {
      const errorValidation = formValidation("loanAmount", 0, fieldsValidation);
      error["loanAmount"] = errorValidation;
    }
    if (!loanValues.term || loanValues.term <= 0) {
      const errorValidation = formValidation("term", 0, fieldsValidation);
      error["term"] = errorValidation;
    }
    if (
      !loanValues.totalHousingExpenses ||
      loanValues.totalHousingExpenses <= 0
    ) {
      const errorValidation = formValidation(
        "totalHousingExpenses",
        0,
        fieldsValidation
      );
      error["totalHousingExpenses"] = errorValidation;
    }
    setFormErrors((prev) => ({
      ...prev,
      ["noteRate"]: error["noteRate"] ?? "",
      ["purchasePrice"]: error["purchasePrice"] ?? "",
      ["loanAmount"]: error["loanAmount"] ?? "",
      ["term"]: error["term"] ?? "",
      ["totalHousingExpenses"]: error["totalHousingExpenses"] ?? "",
    }));
    return (
      !error["noteRate"] &&
      !error["purchasePrice"] &&
      !error["loanAmount"] &&
      !error["term"] &&
      !error["totalHousingExpenses"]
    );
  };

  const loadLoanImportedFromPDF = async (loanImported) => {
    if (mode != "CREATE-NEW") {
      setLoanValues((prev) => {
        return { ...prev, ...loanImported };
      });
    } else {
      setLoanValues(loanImported);
    }
    await delay(50);
    onOpenAddLoanModal(false, true, null, mode);
  };

  const isValidBuyerData = () => {
    return (
      ((formErrors["borrowerFirstName"] === undefined ||
        formErrors["borrowerFirstName"] === "") &&
        (formErrors["borrowerLastName"] === undefined ||
          formErrors["borrowerLastName"] === "") &&
        (formErrors["borrowerEmail"] === undefined ||
          formErrors["borrowerEmail"] === "") &&
        (formErrors["realtorEmail"] === undefined ||
          formErrors["realtorEmail"] === "") &&
        (formErrors["borrowerMobilePhone"] === undefined ||
          formErrors["borrowerMobilePhone"] === "") &&
        (formErrors["realtorMobilePhone"] === undefined ||
          formErrors["realtorMobilePhone"] === "") &&
        (formErrors["totalCashInBank"] === undefined ||
          formErrors["totalCashInBank"] === "")) ||
      (formErrors["zipCode"] === undefined || formErrors["zipCode"] === "")(
        formErrors["state"] === undefined || formErrors["state"] === ""
      )
    );
  };

  const isValid = () => {
    return isValidBuyerData() && isValidLoanType();
  };

  const handleNextSaveClick = () => {
    if (value + 1 >= totalSteps) {
      if (mode != "CREATE-NEW") {
        clickSubmitHiddenButton();
      } else {
        checkNewBuyerEmail();
      }
    } else {
      setIsSaving(false);
      checkRequiredBuyerData();
    }
  };

  const checkRequiredBuyerData = () => {
    let error = {};
    if (!loanValues.borrowerFirstName || loanValues.borrowerFirstName === "") {
      const errorFirstName = formValidation(
        "borrowerFirstName",
        "",
        fieldsValidation
      );
      error["borrowerFirstName"] = errorFirstName;
    }
    if (!loanValues.borrowerLastName || loanValues.borrowerLastName === "") {
      const errorLastName = formValidation(
        "borrowerLastName",
        "",
        fieldsValidation
      );
      error["borrowerLastName"] = errorLastName;
    }
    if (!loanValues.borrowerEmail || loanValues.borrowerEmail === "") {
      const errorEmail = formValidation("borrowerEmail", "", fieldsValidation);
      error["borrowerEmail"] = errorEmail;
    }
    if (!loanValues.totalCashInBank || loanValues.totalCashInBank <= 0) {
      const errorCash = formValidation("totalCashInBank", 0, fieldsValidation);
      error["totalCashInBank"] = errorCash;
    }
    if (loanValues.zipCode && loanValues.zipCode.length != 5) {
      const errorCash = formValidation(
        "zipCode",
        loanValues.zipCode,
        fieldsValidation
      );
      error["zipCode"] = errorCash;
    }
    if (loanValues.state && loanValues.state.length != 2) {
      const errorCash = formValidation(
        "state",
        loanValues.state,
        fieldsValidation
      );
      error["state"] = errorCash;
    }
    setFormErrors((prev) => ({
      ...prev,
      ["borrowerFirstName"]: error["borrowerFirstName"] ?? "",
      ["borrowerLastName"]: error["borrowerLastName"] ?? "",
      ["borrowerEmail"]: error["borrowerEmail"] ?? "",
      ["totalCashInBank"]: error["totalCashInBank"] ?? "",
      ["zipCode"]: error["zipCode"] ?? "",
      ["state"]: error["state"] ?? "",
    }));
    if (
      error["borrowerFirstName"] ||
      error["borrowerLastName"] ||
      error["borrowerEmail"] ||
      error["totalCashInBank"] ||
      error["zipCode"] ||
      error["state"]
    ) {
      onIsValidError();
      setIsSaving(false);
    } else {
      handleNext();
    }
  };

  const checkNewBuyerEmail = () => {
    const buyerEmail = encodeURIComponent(loanValues.borrowerEmail);
    fetchApiData(
      ApiPaths.CheckBuyerByEmail + "?email=" + buyerEmail,
      onCheckResult,
      setIsLoading,
      onError,
      user.token
    );
  };

  const onCheckResult = (buyerResult) => {
    if (buyerResult === null) {
      clickSubmitHiddenButton();
    } else {
      if (
        !buyerResult ||
        (buyerResult && !buyerResult.LoanOriginatorId && !buyerResult.LoanId)
      ) {
        clickSubmitHiddenButton();
      } else if (
        buyerResult &&
        buyerResult.LoanOriginatorId &&
        buyerResult.LoanId
      ) {
        setAlertState({
          show: true,
          message: `There is already an existing buyer with email ${buyerResult.BuyerEmail} and has already a loan officer and a loan qualification linked to his profile`,
        });
        setIsSaving(false);
      } else if (
        buyerResult &&
        buyerResult.LoanOriginatorId &&
        buyerResult.LoanOriginatorId != user.Id &&
        !buyerResult.LoanId
      ) {
        setAlertState({
          show: true,
          message: `This buyer already has a loan officer linked to his profile.  Please ask the buyer to remove the current Loan officer from his/her profile so you can add their Loan.`,
        });
        setIsSaving(false);
      }
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Loading isLoading={isSaving || isLoading} />
        <AlertMessage alert={alertState} />

        <Grid container item xs={12} justifyContent={"space-between"}>
          <Typography variant="h5">Unregistered Buyers</Typography>
          <div>
            <Button
              ref={addEL}
              size="small"
              style={{ margin: "5px" }}
              color="primary"
              onClick={() => setOpenUploadLoanModal(true)}
              startIcon={<UploadFileIcon />}
            >
              Upload a new buyer loan
            </Button>
            {/* <Button
              ref={addEL}
              size="small"
              style={{ margin: "5px" }}
              color="primary"
              onClick={() =>
                onOpenAddLoanModal(false, false, null, "CREATE-NEW")
              }
              startIcon={<AddIcon />}
            >
              Manually add a loan
            </Button> */}
          </div>
        </Grid>
        <div
          style={{
            width: "100%",
            paddingLeft: "24px",
            paddingTop: "15px",
          }}
        >
          <div>
            <Grid container spacing={1}>
              <Grid
                item
                sm={4}
                style={{ textAlign: "bottom", padding: 0 }}
              ></Grid>

              <Grid container item xs={12} sm={12}>
                <Grid item xs={12}>
                  {showPendingBuyers()}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>

        <Grid item xs={12}>
          <Typography variant="h5">Registered Buyers</Typography>
        </Grid>
        <div
          style={{
            width: "100%",
            paddingLeft: "24px",
            paddingTop: "15px",
          }}
        >
          <div>
            <Grid container spacing={1}>
              <Grid container item xs={12} sm={12}>
                <Grid item xs={12}>
                  {showBuyers()}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>

        <div>
          <Dialog
            fullWidth={true}
            open={openAddLoanModal}
            onClose={() => {
              setValue(0);
              setOpenAddLoanModal(false);
              setLoanValues(initialValues);
              setMode("CREATE-NEW");
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"xl"}
            height={"50rem"}
          >
            <DialogTitle
              className={classes.modalHeader}
              id="alert-dialog-title"
            >
              {loanValues.loanId === undefined ||
              (loanValues.loanId == loanValues.loanId.length) === 0
                ? "ADD LOAN"
                : "EDIT LOAN"}
            </DialogTitle>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  {steps.map((step, index) => (
                    <Tab
                      key={step.label}
                      label={step.label}
                      {...a11yProps(index)}
                    />
                  ))}
                </Tabs>
              </Box>
            </Box>
            <DialogContent
              style={{
                borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                padding: "0 1rem",
              }}
            >
              <Box style={{ height: "60rem" }}>
                <form
                  action="/"
                  method="POST"
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (!isValidBuyerData()) {
                      onOpenEmailPhoneError();
                    } else if (
                      !isValidLoanType() &&
                      !areValiedNumericValues()
                    ) {
                      onOpenLoanTypeError(false, false, true);
                    } else if (isValidLoanType() && !areValiedNumericValues()) {
                      onOpenLoanTypeError(true, false, true);
                    } else if (!isValidLoanType() && areValiedNumericValues()) {
                      onOpenLoanTypeError(false, true, true);
                    } else if (
                      ((loanValues.loanType == "FHA" ||
                        loanValues.loanType == "VA") &&
                        ((loanValues.loanAmount - loanValues.oneTimePMI) /
                          loanValues.purchasePrice) *
                          100 >
                          96.5) ||
                      (loanValues.loanType == "Conventional" &&
                        (loanValues.loanAmount / loanValues.purchasePrice) *
                          100 >
                          97)
                    ) {
                      onOpenLoanTypeError(true, true, false);
                    } else {
                      if (
                        loanValues.loanId === undefined ||
                        loanValues.loanId.length === 0 ||
                        loanValues.loanId.includes("-NO_LOAN")
                      ) {
                        onAddLoan();
                      } else {
                        onEditLoan();
                      }
                    }
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <CustomTabPanel value={value} index={0}>
                      <BorrowerDataTab
                        loanValues={loanValues}
                        handleTextInputValue={handleTextInputValue}
                        handleNumericValues={handleNumericValues}
                        handleInputValue={handleInputValue}
                        mode={mode}
                        formErrors={formErrors}
                      />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                      <LoanItemizationDataTab
                        loanValues={loanValues}
                        handleNumericValues={handleNumericValues}
                        handleCheckLoanType={handleCheckLoanType}
                        handleCheckHasSinglePMI={handleCheckHasSinglePMI}
                        handleCheckHasUsedVABefore={handleCheckHasUsedVABefore}
                        isValidType={isValidType}
                        formErrors={formErrors}
                        areValidValues={areValidValues}
                      />
                    </CustomTabPanel>
                  </Box>
                  <button
                    type="submit"
                    style={{ display: "none" }}
                    ref={hiddenSubmitButtonRef}
                  ></button>
                </form>
              </Box>
            </DialogContent>
            <DialogActions style={{ padding: "0", margin: "1rem" }}>
              <Grid container item xs={12} justifyContent="flex-end">
                <Button
                  style={{ margin: "0 10px 0 10px" }}
                  onClick={() => {
                    setValue(0);
                    setLoanValues(initialValues);
                    setMode("CREATE-NEW");
                    setOpenAddLoanModal(false);
                  }}
                  variant="contained"
                  autoFocus
                >
                  Close
                </Button>
                <Button
                  style={{ margin: "0 10px 0 10px" }}
                  variant="contained"
                  color="primary"
                  disabled={value - 1 < 0}
                  onClick={handleBack}
                >
                  Back
                </Button>
                <LoadingButton
                  style={{ margin: "0 0 0 10px" }}
                  variant="contained"
                  color="primary"
                  loading={isSaving}
                  onClick={() => {
                    setIsSaving(true);
                    handleNextSaveClick();
                  }}
                >
                  {value + 1 >= totalSteps ? "Save" : "Next"}
                </LoadingButton>
              </Grid>
            </DialogActions>
          </Dialog>

          <PDFUploader
            buyerData={buyerData}
            openUploadLoanModal={openUploadLoanModal}
            setOpenUploadLoanModal={setOpenUploadLoanModal}
            loadLoanImportedFromPDF={loadLoanImportedFromPDF}
            mode={mode}
            setMode={setMode}
          />
        </div>
      </Grid>
    </div>
  );
}
