import React, { useState, useEffect, useContext } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  ButtonBase,
  Container,
  Box,
  Menu,
  MenuItem,
  Grid,
} from "@mui/material";
import { Link } from "react-router-dom";
//import LoginMenu from './api-authorization/LoginMenu';
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import PeopleIcon from "@mui/icons-material/People";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";
import "./Layout.css";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CropOriginalIcon from "@mui/icons-material/CropOriginal";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ForumIcon from "@mui/icons-material/Forum";
import { Fragment } from "react";
import LoginMenu from "./auth/LoginMenu";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import { AppRoutes } from "./core/PathConstants";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ArticleIcon from "@mui/icons-material/Article";

import { CurrentUserContext } from "../components/core/hooks/CurrentUserContext";

const drawerWidth = 240;

export default function Layout({ children }) {
  const { user, onChangeUser } = useContext(CurrentUserContext);

  const theme = useTheme();

  const [mobileOpen, setMobileOpen] = useState(false);

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const [path, setPath] = useState(window.location.pathname);

  const handleCloseNavMenu = (newPath) => {
    handleListItemClick(null, newPath);
    setAnchorElNav(null);
  };

  const handleListItemClick = (event, newPath) => setPath(newPath);

  //const changeUserData = (fname, lname, picture) => {
  //    setUser({ ...user, firstName: fname ?? user.firstName, lastName: lname ?? user.lastName, picture: picture ?? user.picture });
  //}

  const labels = [
    {
      title: "HOME",
      link: AppRoutes.Home,
      auth: false,
      index: 0,
    },
    {
      title: "HELP",
      link: AppRoutes.Help,
      auth: false,
      index: -1,
    },
  ];

  useEffect(() => {}, []);

  // let adminSteps = [
  //   {
  //     title: "Welcome",
  //     element: ".lender-intro-step",
  //     intro: `👋 Welcome to Padzilly ${user?.firstName} ${user?.lastName}!!`,
  //   },
  //   {
  //     title: "Dashboard",
  //     element: ".lender-first-step",
  //     intro: "Click here to see your company Dashboard",
  //     position: "right",
  //   },
  //   {
  //     title: "Company Settings",
  //     element: ".lender-second-step",
  //     intro: "Click here to configure company settings",
  //     position: "right",
  //   },
  //   {
  //     title: "UI Customization",
  //     element: ".lender-third-step",
  //     intro:
  //       "Click here to customize the logo and theme for the UI of your clients ",
  //     position: "right",
  //   },
  //   {
  //     title: "Users",
  //     element: ".lender-fourth-step",
  //     intro:
  //       "Click here for the list of users. Here you can also edit the existing ones and create new users",
  //     position: "right",
  //   },
  // ];

  // let mloSteps = [
  //   {
  //     title: "Welcome",
  //     element: ".mlo-intro-step",
  //     intro: `👋 Welcome to Padzilly ${user?.firstName} ${user?.lastName}!!`,
  //   },
  //   {
  //     element: ".mlo-first-step",
  //     intro: "Ckick here to see you Dashboard",
  //     position: "right",
  //   },
  //   {
  //     element: ".mlo-second-step",
  //     intro: "Click here to see the list of your clients",
  //     position: "right",
  //   },
  //   {
  //     element: ".mlo-third-step",
  //     intro: "Click here to modify your info",
  //     position: "right",
  //   },
  //   {
  //     element: ".mlo-fourth-step",
  //     intro: "Click here to modify your headshot and logo",
  //     position: "right",
  //   },
  // ];

  // let realtorSteps = [
  //   {
  //     title: "Welcome",
  //     element: ".realtor-intro-step",
  //     intro: `👋 Welcome to Padzilly ${user?.firstName} ${user?.lastName}!!`,
  //   },
  //   {
  //     title: "Dashboard",
  //     element: ".realtor-first-step",
  //     intro: "Click here to see the list of your clients",
  //     position: "right",
  //   },
  // ];

  // let profileStep = [
  //   {
  //     title: "Profile",
  //     element: ".profile-step",
  //     intro: "Click here for your profile",
  //   },
  // ];
  //const onLoadPicture = (data) => {
  //    setUser({ ...user, picture: data.picture });
  //}

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const adminView = () => {
    // return (
    //   // <Fragment>
    //   //   <Divider />
    //   //   <List>
    //   //     <ListItemButton
    //   //       className="lender-first-step"
    //   //       component={Link}
    //   //       to={AppRoutes.Home}
    //   //       onClick={(event) => handleListItemClick(event, 0)}
    //   //       selected={selectedIndex === 0}
    //   //     >
    //   //       <ListItemIcon>
    //   //         <DashboardIcon />
    //   //       </ListItemIcon>
    //   //       <ListItemText primary="Dashboard" />
    //   //     </ListItemButton>
    //   //     <ListItemButton
    //   //       className="lender-second-step"
    //   //       component={Link}
    //   //       to={AppRoutes.CompanyConfig}
    //   //       onClick={(event) => handleListItemClick(event, 1)}
    //   //       selected={selectedIndex === 1}
    //   //     >
    //   //       <ListItemIcon>
    //   //         <SettingsIcon />
    //   //       </ListItemIcon>
    //   //       <ListItemText primary="Company Settings" />
    //   //     </ListItemButton>
    //   //     {/*onClick={handleUserMenuClick}*/}
    //   //     <ListItemButton
    //   //       className="lender-third-step"
    //   //       component={Link}
    //   //       to={AppRoutes.UICustomization}
    //   //       onClick={(event) => handleListItemClick(event, 2)}
    //   //       selected={selectedIndex === 2}
    //   //     >
    //   //       <ListItemIcon>
    //   //         <ColorLensIcon />
    //   //       </ListItemIcon>
    //   //       <ListItemText primary="UI Customization" />
    //   //     </ListItemButton>
    //   //     <ListItemButton
    //   //       className="lender-fourth-step"
    //   //       component={Link}
    //   //       to={AppRoutes.UsersSetup.replace(":tabValue", "users")}
    //   //       onClick={(event) => handleListItemClick(event, 3)}
    //   //       selected={selectedIndex === 3}
    //   //     >
    //   //       <ListItemIcon>
    //   //         <PeopleIcon />
    //   //       </ListItemIcon>
    //   //       <ListItemText primary="Users" />
    //   //       {/* {openUserMenu ? <ExpandLess /> : <ExpandMore />}*/}
    //   //     </ListItemButton>
    //   //     {/*<Collapse in={openUserMenu} timeout="auto" unmountOnExit>*/}
    //   //     {/*    <List component="div" disablePadding>*/}
    //   //     {/*        <ListItemButton button component={Link} to='/lender/users/admin' className={classes.nested}>*/}
    //   //     {/*            <ListItemIcon>*/}
    //   //     {/*                <SupervisorAccountIcon />*/}
    //   //     {/*            </ListItemIcon>*/}
    //   //     {/*            <ListItemText primary="Administrators" />*/}
    //   //     {/*        </ListItemButton>*/}
    //   //     {/*        <ListItemButton button component={Link} to='/lender/users/mlo' className={classes.nested}>*/}
    //   //     {/*            <ListItemIcon>*/}
    //   //     {/*                <PeopleOutlinedIcon />*/}
    //   //     {/*            </ListItemIcon>*/}
    //   //     {/*            <ListItemText primary="MLOs" />*/}
    //   //     {/*        </ListItemButton>*/}
    //   //     {/*    </List>*/}
    //   //     {/*</Collapse>*/}
    //   //   </List>
    //   //   <Divider />
    //   // </Fragment>
    // );
  };

  const mloView = () => {
    // return (
    //   <Fragment>
    //     {/* {isFirstLogin && (
    //       <Steps
    //         enabled={stepsEnabled}
    //         steps={mloSteps}
    //         initialStep={initialStep}
    //         onExit={onExitLayoutSteps}
    //         options={{ hideNext: false, hidePrev: true, doneLabel: "Got It" }}
    //       />
    //     )} */}
    //     <Divider />
    //     <List>
    //       <ListItemButton
    //         className="mlo-first-step"
    //         component={Link}
    //         to={AppRoutes.Home}
    //       >
    //         <ListItemIcon>
    //           <DashboardIcon />
    //         </ListItemIcon>
    //         <ListItemText primary="Dashboard" />
    //       </ListItemButton>
    //       <ListItemButton
    //         component={Link}
    //         to={AppRoutes.MLOInvite.replace(":tabValue", "qualificationInvite")}
    //         onClick={(event) => handleListItemClick(event, 0)}
    //         selected={selectedIndex === 0}
    //         style={{ display: "none" }}
    //       >
    //         <ListItemIcon>
    //           <MailOutlineIcon />
    //         </ListItemIcon>
    //         <ListItemText primary="Invite" />
    //       </ListItemButton>
    //       <ListItemButton
    //         className="mlo-second-step"
    //         component={Link}
    //         to={AppRoutes.MLOBuyers}
    //         onClick={(event) => handleListItemClick(event, 1)}
    //         selected={selectedIndex === 1}
    //       >
    //         <ListItemIcon>
    //           <PeopleAltIcon />
    //         </ListItemIcon>
    //         <ListItemText primary="Buyers" />
    //       </ListItemButton>
    //       <Divider />
    //       <ListItemButton
    //         className="mlo-third-step"
    //         component={Link}
    //         to={AppRoutes.MLODetails}
    //         onClick={(event) => handleListItemClick(event, 2)}
    //         selected={selectedIndex === 2}
    //       >
    //         <ListItemIcon>
    //           <AccountCircleIcon />
    //         </ListItemIcon>
    //         <ListItemText primary="My Profile" />
    //       </ListItemButton>
    //       <ListItemButton
    //         className="mlo-fourth-step"
    //         component={Link}
    //         to={AppRoutes.MLOAppImages}
    //         onClick={(event) => handleListItemClick(event, 3)}
    //         selected={selectedIndex === 3}
    //         style={{ display: "none" }}
    //       >
    //         <ListItemIcon>
    //           <CropOriginalIcon />
    //         </ListItemIcon>
    //         <ListItemText primary="App Images" />
    //       </ListItemButton>
    //       <ListItemButton
    //         component={Link}
    //         to={AppRoutes.MLONotifications}
    //         onClick={(event) => handleListItemClick(event, 4)}
    //         selected={selectedIndex === 4}
    //         style={{ display: "none" }}
    //       >
    //         <ListItemIcon>
    //           <NotificationsIcon />
    //         </ListItemIcon>
    //         <ListItemText primary="Notifications" />
    //       </ListItemButton>
    //       <ListItemButton
    //         component={Link}
    //         to={AppRoutes.MLOCommunications}
    //         onClick={(event) => handleListItemClick(event, 5)}
    //         selected={selectedIndex === 5}
    //         style={{ display: "none" }}
    //       >
    //         <ListItemIcon>
    //           <ForumIcon />
    //         </ListItemIcon>
    //         <ListItemText primary="Communications" />
    //       </ListItemButton>
    //     </List>
    //   </Fragment>
    // );
  };

  const mloLenderView = () => {
    return (
      <Fragment>
        <Divider />
        <List>
          {/* <ListItemButton
            className="lender-first-step"
            component={Link}
            to={AppRoutes.Home}
            onClick={(event) => handleListItemClick(event, 0)}
            selected={selectedIndex === 0}
            style={{ display: "none" }}
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton> */}
          <ListItemButton
            className="mlo-second-step"
            component={Link}
            to={AppRoutes.MLOLoansList}
            onClick={(event) =>
              handleListItemClick(event, AppRoutes.MLOLoansList)
            }
            selected={path === AppRoutes.MLOLoansList}
            // style={{ display: "none" }}
          >
            <ListItemIcon>
              <PeopleAltIcon />
            </ListItemIcon>
            <ListItemText primary="Buyers" />
          </ListItemButton>
          <ListItemButton
            className="lender-second-step"
            component={Link}
            to={AppRoutes.CompanyConfig}
            onClick={(event) =>
              handleListItemClick(event, AppRoutes.CompanyConfig)
            }
            selected={path === AppRoutes.CompanyConfig}
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Company Settings" />
          </ListItemButton>
          <ListItemButton
            className="lender-third-step"
            component={Link}
            to={AppRoutes.UICustomization}
            selected={path === AppRoutes.UICustomization}
            style={{ display: "none" }}
          >
            <ListItemIcon>
              <ColorLensIcon />
            </ListItemIcon>
            <ListItemText primary="UI Customization" />
          </ListItemButton>
          <Divider />
          <ListItemButton
            className="mlo-third-step"
            component={Link}
            to={AppRoutes.MLODetails}
            onClick={(event) =>
              handleListItemClick(event, AppRoutes.MLODetails)
            }
            selected={path === AppRoutes.MLODetails}
            //style={{ display: "none" }}
          >
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText primary="My Profile" />
          </ListItemButton>
          {/* <ListItemButton
            className="mlo-fourth-step"
            component={Link}
            to={AppRoutes.MLOAppImages}
            onClick={(event) => handleListItemClick(event, 6)}
            style={{ display: "none" }}
          >
            <ListItemIcon>
              <CropOriginalIcon />
            </ListItemIcon>
            <ListItemText primary="App Images" />
          </ListItemButton> */}
          {/* <ListItemButton
            component={Link}
            to={AppRoutes.MLONotifications}
            onClick={(event) => handleListItemClick(event, 7)}
            selected={selectedIndex === 7}
            style={{ display: "none" }}
          >
            <ListItemIcon>
              <NotificationsIcon />
            </ListItemIcon>
            <ListItemText primary="Notifications" />
          </ListItemButton> */}
          {/* <ListItemButton
            component={Link}
            to={AppRoutes.MLOCommunications}
            onClick={(event) => handleListItemClick(event, 8)}
            selected={selectedIndex === 8}
            style={{ display: "none" }}
          >
            <ListItemIcon>
              <ForumIcon />
            </ListItemIcon>
            <ListItemText primary="Communications" />
          </ListItemButton> */}
        </List>
      </Fragment>
    );
  };

  const realtorView = () => {
    // return (
    //   <Fragment>
    //     {/* {isFirstLogin && (
    //       <Steps
    //         enabled={stepsEnabled}
    //         steps={realtorSteps}
    //         initialStep={initialStep}
    //         onExit={onExitLayoutSteps}
    //         options={{ hideNext: false, hidePrev: true, doneLabel: "Got It" }}
    //       />
    //     )} */}
    //     <Divider />
    //     <List>
    //       <ListItemButton
    //         className="realtor-first-step"
    //         component={Link}
    //         to={AppRoutes.Home}
    //         onClick={(event) => handleListItemClick(event, 0)}
    //         selected={selectedIndex === 0}
    //       >
    //         <ListItemIcon>
    //           <PeopleAltIcon />
    //         </ListItemIcon>
    //         <ListItemText primary="Dashboard" />
    //       </ListItemButton>
    //       {/* <ListItemButton
    //         component={Link}
    //         to={AppRoutes.RealtorInvite.replace(
    //           ":tabValue",
    //           "qualificationInvite"
    //         )}
    //         onClick={(event) => handleListItemClick(event, 1)}
    //         selected={selectedIndex === 1}
    //       >
    //         <ListItemIcon>
    //           <MailOutlineIcon />
    //         </ListItemIcon>
    //         <ListItemText primary="Pre-qual Invite" />
    //       </ListItemButton>
    //       <Divider />
    //       <ListItemButton
    //         component={Link}
    //         to={AppRoutes.RealtorDetails}
    //         onClick={(event) => handleListItemClick(event, 2)}
    //         selected={selectedIndex === 2}
    //       >
    //         <ListItemIcon>
    //           <AccountCircleIcon />
    //         </ListItemIcon>
    //         <ListItemText primary="My Details" />
    //       </ListItemButton>
    //       <ListItemButton
    //         component={Link}
    //         to={AppRoutes.RealtorAppImages}
    //         onClick={(event) => handleListItemClick(event, 3)}
    //         selected={selectedIndex === 3}
    //       >
    //         <ListItemIcon>
    //           <CropOriginalIcon />
    //         </ListItemIcon>
    //         <ListItemText primary="App Images" />
    //       </ListItemButton>
    //       <ListItemButton
    //         component={Link}
    //         to={AppRoutes.RealtorNotifications}
    //         onClick={(event) => handleListItemClick(event, 4)}
    //         selected={selectedIndex === 4}
    //       >
    //         <ListItemIcon>
    //           <NotificationsIcon />
    //         </ListItemIcon>
    //         <ListItemText primary="Notifications" />
    //       </ListItemButton> */}
    //     </List>
    //   </Fragment>
    // );
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <Hidden smUp implementation="js">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              size="large"
            >
              <MenuIcon />
            </IconButton>
          </Hidden>

          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
          >
            <ButtonBase
              component={Link}
              to={
                user?.success && !user?.mustChangePassword
                  ? "/"
                  : AppRoutes.ChangeFirstPassword
              }
            >
              <img
                alt="Padzilly"
                src="images/logo-white.svg"
                width="150"
                height="35"
              />
            </ButtonBase>
          </Typography>

          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
          >
            <ButtonBase
              component={Link}
              to={
                user?.success && !user?.mustChangePassword
                  ? "/"
                  : AppRoutes.ChangeFirstPassword
              }
            >
              <img
                alt="Padzilly"
                src="images/logo-white.svg"
                width="150"
                height="35"
              />
            </ButtonBase>
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}>
            {/* {labels.map((page) =>
              user?.success && !user.mustChangePassword ? (
                <ButtonBase
                  component={Link}
                  to={page.link}
                  style={{ margin: "0 10px 0 10px" }}
                  key={page.title}
                  onClick={() => handleCloseNavMenu(page.index)}
                  sx={{
                    my: 2,
                    color: "white",
                    display:
                      page.index != 2 ? "block" : !showShare ? "none" : "block",
                  }}
                >
                  {page.title}
                </ButtonBase>
              ) : (
                <span key={page.title}></span>
              )
            )} */}
          </Box>

          <Grid className="profile-step">
            <LoginMenu user={user} onChangeUser={onChangeUser} />
          </Grid>
        </Toolbar>
      </AppBar>
      {user?.success && !user?.mustChangePassword && (
        <Fragment>
          <Hidden smUp implementation="js">
            <Drawer
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                  width: drawerWidth,
                  boxSizing: "border-box",
                },
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <Toolbar />
              {user?.role &&
              (user?.role === "Admin" || user?.role === "SuperAdmin")
                ? adminView()
                : user?.role && user?.role === "MLO"
                ? mloView()
                : user?.role && user?.role === "Realtor"
                ? realtorView()
                : user?.role && user?.role === "MLO-Lender"
                ? mloLenderView()
                : ""}
            </Drawer>
          </Hidden>
          <Hidden smDown implementation="js">
            <Drawer
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                  width: drawerWidth,
                  boxSizing: "border-box",
                },
              }}
              variant="permanent"
              open
            >
              <Toolbar />
              <Box sx={{ overflow: "auto" }}>
                {user?.role &&
                (user?.role === "Admin" || user?.role === "SuperAdmin")
                  ? adminView()
                  : user?.role && user?.role === "MLO"
                  ? mloView()
                  : user?.role && user?.role === "Realtor"
                  ? realtorView()
                  : user?.role && user?.role === "MLO-Lender"
                  ? mloLenderView()
                  : ""}
              </Box>
            </Drawer>
          </Hidden>
        </Fragment>
      )}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Container maxWidth={"xl"} disableGutters>
          {children}
        </Container>
      </Box>
    </Box>
  );
}
