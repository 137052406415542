import { React, useState } from "react";
import { TextField, List, Paper, Checkbox, ListItem } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  current: {
    fontWeight: 700,
    fontSize: "14px",
    color: theme.palette.grey[700],
  },
  checkboxText: {
    margin: "0px",
    fontFamily: "Roboto, Helvetica, Arial, sans- serif",
    fontWeight: 400,
    fontSsize: "1rem",
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
  },
  paper: {
    padding: "0.5rem",
    color: theme.palette.text.secondary,
  },
  statuses: {
    margin: "0px",
    fontFamily: "Roboto, Helvetica, Arial, sans- serif",
    fontWeight: 400,
    fontSsize: "1rem",
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
    textAlign: "end",
  },
  notFoundMessage: {
    margin: "0px",
    fontFamily: "Roboto, Helvetica, Arial, sans- serif",
    fontWeight: 400,
    fontSsize: "1rem",
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
    textAlign: "start",
  },
  checkBoxListItem: {
    alignItems: "flex-end",
    padding: 0,
  },
  checkBoxListItemForMultiline: {
    alignItems: "flex-end",
    padding: 0,
    ["@media (max-width: 1155px)"]: {
      marginBottom: "1.5rem",
    },
    // [theme.breakpoints.down("md")]: {
    //   marginBottom: "1.5rem",
    // },
  },
  listItemDiv: {
    alignItems: "center",
    display: "flex",
  },
  statusesListItem: {
    flexFlow: "column wrap",
    alignItems: "flex-end",
    marginBottom: "0.11rem",
  },
  textFields: {
    marginTop: "1rem",
  },
}));

const FHACondoLenderSetup = ({}) => {
  const classes = useStyles();

  return (
    <div style={{ padding: 0 }}>
      <Paper style={{ padding: 0 }} variant="outlined">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              borderRight: "1px solid rgba(0, 0, 0, 0.12)",
              padding: "0 0.5rem",
            }}
          >
            <List style={{ marginTop: "1.8rem" }}>
              <ListItemButton className={classes.statusesListItem}>
                <h4 className={classes.statuses}>Approved</h4>
              </ListItemButton>
              <ListItemButton className={classes.statusesListItem}>
                <h4 className={classes.statuses}>Approved Expired</h4>
              </ListItemButton>
              <ListItemButton className={classes.statusesListItem}>
                <h4 className={classes.statuses}>
                  Rejected - Register Single Unit
                </h4>
              </ListItemButton>
              <ListItemButton className={classes.statusesListItem}>
                <h4 className={classes.statuses}>Rejected Other</h4>
              </ListItemButton>
              <ListItemButton className={classes.statusesListItem}>
                <h4 className={classes.statuses}>Withdrawn</h4>
              </ListItemButton>
              <ListItemButton className={classes.statusesListItem}>
                <h4 className={classes.statuses}>Not found on List</h4>
              </ListItemButton>
            </List>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "0 0.5rem",
            }}
          >
            <h3 style={{ margin: 0 }}>RESULT MESSAGE TO BUYER</h3>
            <div style={{ display: "flex", direction: "row" }}>
              <List>
                <ListItemButton className={classes.checkBoxListItem}>
                  <div className={classes.listItemDiv}>
                    <Checkbox name="overridePrepaidIntDays" color="primary" />
                    <h4 className={classes.statuses}>Approved</h4>
                  </div>
                </ListItemButton>
                <ListItemButton className={classes.checkBoxListItem}>
                  <div className={classes.listItemDiv}>
                    <Checkbox name="overridePrepaidIntDays" color="primary" />
                    <h4 className={classes.statuses}>Possibly Approved</h4>
                  </div>
                </ListItemButton>
                <ListItemButton
                  className={classes.checkBoxListItemForMultiline}
                >
                  <div className={classes.listItemDiv}>
                    <Checkbox name="overridePrepaidIntDays" color="primary" />
                    <h4 className={classes.statuses}>Possibly Approved</h4>
                  </div>
                </ListItemButton>
                <ListItemButton className={classes.checkBoxListItem}>
                  <div className={classes.listItemDiv}>
                    <Checkbox name="overridePrepaidIntDays" color="primary" />
                    <h4 className={classes.statuses}>Possibly Approved</h4>
                  </div>
                </ListItemButton>
                <ListItemButton className={classes.checkBoxListItem}>
                  <div className={classes.listItemDiv}>
                    <Checkbox name="overridePrepaidIntDays" color="primary" />
                    <h4 className={classes.statuses}>Possibly Approved</h4>
                  </div>
                </ListItemButton>
              </List>
              <List>
                <ListItemButton className={classes.checkBoxListItem}>
                  <div className={classes.listItemDiv}>
                    <Checkbox name="overridePrepaidIntDays" color="primary" />
                    <h4 className={classes.statuses}>Possibly Approved</h4>
                  </div>
                </ListItemButton>
                <ListItemButton className={classes.checkBoxListItem}>
                  <div className={classes.listItemDiv}>
                    <Checkbox name="overridePrepaidIntDays" color="primary" />
                    <h4 className={classes.statuses}>Not Approved</h4>
                  </div>
                </ListItemButton>
                <ListItemButton
                  className={classes.checkBoxListItemForMultiline}
                >
                  <div className={classes.listItemDiv}>
                    <Checkbox name="overridePrepaidIntDays" color="primary" />
                    <h4 className={classes.statuses}>Not Approved</h4>
                  </div>
                </ListItemButton>
                <ListItemButton className={classes.checkBoxListItem}>
                  <div className={classes.listItemDiv}>
                    <Checkbox name="overridePrepaidIntDays" color="primary" />
                    <h4 className={classes.statuses}>Not Approved</h4>
                  </div>
                </ListItemButton>
                <ListItemButton className={classes.checkBoxListItem}>
                  <div className={classes.listItemDiv}>
                    <Checkbox name="overridePrepaidIntDays" color="primary" />
                    <h4 className={classes.statuses}>Not Approved</h4>
                  </div>
                </ListItemButton>
              </List>
            </div>
            <div>
              <h4 className={classes.notFoundMessage}>
                Enter message to buyer below if the condo is not found in the
                approved List
              </h4>
            </div>
          </div>
        </div>
      </Paper>
      <div
        style={{
          marginTop: "1rem",
          padding: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <h3 style={{ margin: 0 }}>STATUS MESSAGES</h3>

        <TextField
          className={classes.textFields}
          fullWidth
          multiline
          maxRows={3}
          label="Approved Message"
        />
        <TextField
          className={classes.textFields}
          fullWidth
          multiline
          maxRows={3}
          label="Possibly Approved Message"
        />
        <TextField
          className={classes.textFields}
          fullWidth
          multiline
          maxRows={3}
          label="Property Not Found on List Message"
        />
      </div>
    </div>
  );
};

export default FHACondoLenderSetup;
