import React from "react";
import { Grid, TextField, Typography } from "@mui/material";

import LendingStates from "./LendingStates";

const CompanySetup = ({
  handleChange,
  statesList,
  formErrors,
  handleCheckBox,
  values: {
    companyName,
    street1,
    street2,
    city,
    state,
    zipCode,
    phoneNumber,
    nmls,
    webURL,
    states,
  },
}) => {
  // Validate fields to can go to next step
  const isValid = !formErrors.companyName && companyName.length > 0;

  return (
    <div>
      <Grid container spacing={3} style={{ margin: "0 0 5px" }}>
        <Grid item xs={12}>
          <Typography variant="h6">
            <strong>Company Info</strong>
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        style={{ marginLeft: "10px", marginRight: "10px" }}
      >
        <Grid item xs={12} md={12}>
          <div>
            <TextField
              style={{ width: "50%", margin: "10px 10px 10px 5px" }}
              variant="outlined"
              size="small"
              label="Company Name"
              name="companyName"
              placeholder="Company Name"
              value={companyName || ""}
              onChange={handleChange}
              error={!!formErrors.companyName}
              helperText={formErrors.companyName}
              required
            />
          </div>

          <div>
            <TextField
              style={{ width: "30%", margin: "0 10px 10px 5px" }}
              variant="outlined"
              size="small"
              label="Street Address 1"
              name="street1"
              placeholder="Address"
              value={street1 || ""}
              onChange={handleChange}
              error={!!formErrors.street1}
              helperText={formErrors.street1}
              //required
            />
            <TextField
              style={{ width: "30%", margin: "0 10px 10px 5px" }}
              variant="outlined"
              size="small"
              label="Street Address 2"
              name="street2"
              placeholder="Address 2"
              value={street2 || ""}
              onChange={handleChange}
              error={!!formErrors.street2}
              helperText={formErrors.street2}
            />
          </div>
          <div>
            <TextField
              style={{ width: "40%", margin: "0 10px 10px 5px" }}
              variant="outlined"
              size="small"
              label="City"
              name="city"
              placeholder="City"
              value={city || ""}
              onChange={handleChange}
              error={!!formErrors.city}
              helperText={formErrors.city}
              //required
            />
            <TextField
              style={{ width: "20%", margin: "0 10px 10px 5px" }}
              variant="outlined"
              size="small"
              label="State"
              name="state"
              placeholder="State"
              value={state || ""}
              onChange={handleChange}
              error={!!formErrors.state}
              helperText={formErrors.state}
              //required
            />
            <TextField
              style={{ width: "30%", margin: "0 10px 10px 5px" }}
              variant="outlined"
              size="small"
              label="ZIP"
              name="zipCode"
              placeholder="ZIP"
              value={zipCode || ""}
              onChange={handleChange}
              error={!!formErrors.zipCode}
              helperText={formErrors.zipCode}
              //required
            />
          </div>
          <div>
            <TextField
              style={{ margin: "0 10px 10px 5px" }}
              variant="outlined"
              size="small"
              label="Main Phone"
              name="phoneNumber"
              placeholder="Main Phone"
              value={phoneNumber || ""}
              onChange={handleChange}
              error={!!formErrors.phoneNumber}
              helperText={formErrors.phoneNumber}
              //required
            />
            <TextField
              style={{ margin: "0 10px 10px 5px" }}
              variant="outlined"
              size="small"
              label="NMLS"
              name="nmls"
              placeholder="NMLS"
              value={nmls || ""}
              onChange={handleChange}
              error={!!formErrors.nmls}
              helperText={formErrors.nmls}
              //required
            />
          </div>
          <div>
            <TextField
              style={{ width: "65%", margin: "0 10px 0 5px" }}
              variant="outlined"
              size="small"
              label="Website"
              name="webURL"
              placeholder="Website"
              value={webURL || ""}
              onChange={handleChange}
              error={!!formErrors.webURL}
              helperText={formErrors.webURL}
              //required
            />
          </div>
        </Grid>

        <Grid item xs={12} style={{ marginTop: "10px" }}>
          <LendingStates
            statesList={statesList}
            selected={states}
            handleCheckBox={handleCheckBox}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default CompanySetup;
