import React from 'react';
import { Snackbar } from '@mui/material';
import { Alert } from '@mui/material';
export default function AlertMessage({ alert }) {
    const [openAlert, setOpenAlert] = React.useState(false);


    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAlert(false);
    };

    React.useEffect(() => {

        if (alert?.show === true) {
            setOpenAlert(true);
        }
        else if (openAlert === true) {
            setOpenAlert(false);
        }

    }, [alert]);

    return (
        <Snackbar open={openAlert} autoHideDuration={alert?.autoHideDuration ?? 5000} onClose={handleAlertClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
            <Alert severity={alert?.type ?? 'error'} variant="filled" onClose={handleAlertClose} >
                {alert?.message ?? ''}
            </Alert>
        </Snackbar>
    );
}


 