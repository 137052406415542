import { React, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Tab,
  Paper,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import makeStyles from "@mui/styles/makeStyles";
import FHACondoLenderSetup from "./LenderCondoListTabs/FHACondoLenderSetup";
import VACondoLenderSetup from "./LenderCondoListTabs/VACondoLenderSetup";
import CONVCondoLenderSetup from "./LenderCondoListTabs/CONVCondoLenderSetup";
import PublishOutlinedIcon from "@mui/icons-material/PublishOutlined";
import AlertMessage from "../../../core/AlertMessage";
import { postApiData } from "../../../core/FetchApiData";
import { ApiPaths } from "../../../core/PathConstants";

const useStyles = makeStyles((theme) => ({
  current: {
    fontWeight: 700,
    fontSize: "14px",
    color: theme.palette.grey[700],
  },
  checkboxText: {
    margin: "0px",
    fontFamily: "Roboto, Helvetica, Arial, sans- serif",
    fontWeight: 400,
    fontSsize: "1rem",
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
  },
  paper: {
    padding: "0.5rem",
    marginTop: "1rem",
    color: theme.palette.text.secondary,
  },
}));

const LenderCondoListSetup = ({}) => {
  const classes = useStyles();
  const [value, setValue] = useState("1");
  const [alertState, setAlertState] = useState({ show: false });

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleFile = (e) => {
    e.preventDefault();
    var regex = /^([a-zA-Z0-9\s_\\.\-:])+(.csv|.txt)$/;
    if (!regex.test(e.target.files[0].name)) {
      setAlertState({ show: true, message: "File Unsupported" });
    } else if (e.target.files && e.target.files[0]) {
      if (window.FileReader) {
        getAsText(e.target.files[0]);
      }
    }
  };

  function getAsText(fileToRead) {
    var reader = new FileReader();
    reader.readAsText(fileToRead);
    reader.onload = fileReadingFinished;
    reader.onerror = errorHandler;
  }

  function fileReadingFinished(event) {
    var csv = event.target.result;
    processData(csv);
  }

  function processData(csv) {
    var allTextLines = csv.split(/\r\n|\n/);
    var fileRows = allTextLines
      .filter((data) => data !== "")
      .map((data) => data.split(";"));
    addUsers(fileRows);
  }

  const addUsers = (fileRows) => {
    let users = new Array();
    var validEmailPattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (
      !validEmailPattern.test(fileRows[0].map((data) => data.split(","))[0][0])
    ) {
      fileRows.splice(0, 1);
    }

    fileRows.forEach((item) => {
      var usersData = item.map((data) =>
        data.split(/,(?=(?:(?:[^'"]*(?:'|")){2})*[^'"]*$)/)
      )[0];
      users.push({
        email: usersData[0],
        firstName: usersData[1],
        lastName: usersData[2],
        role: usersData[3],
        branch: usersData[4],
        team: usersData[5],
      });
    });

    //  postApiData(
    //      ApiPaths.CompanyUsers,
    //  "POST",
    //  users,
    //  onAddUsersComplete,
    //  onError,
    //  user.token
    //);
  };

  function errorHandler(event) {
    setAlertState({ show: true, message: "File read unsupported" });
  }

  return (
    <div style={{ marginTop: "1rem" }}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          style={{ marginBottom: "1rem" }}
          size="small"
          color="primary"
          component="label"
          startIcon={<PublishOutlinedIcon />}
        >
          Upload Lender Condo List CSV
          <input
            onChange={handleFile}
            id="file-uploader"
            type="file"
            hidden
            accept=".csv"
          />
        </Button>
      </div>
      <TabContext value={value}>
        <Paper variant="outlined">
          <TabList
            onChange={handleTabChange}
            aria-label="lab API tabs example"
            variant="scrollable"
          >
            <Tab label="FHA CONDO - LENDER" value="1" />
            <Tab label="VA CONDO - LENDER" value="2" />
            <Tab label="CONVENTIONAL CONDO - LENDER" value="3" />
          </TabList>
        </Paper>
        <TabPanel value="1" style={{ padding: 0, fontWeight: 700 }}>
          <FHACondoLenderSetup />
        </TabPanel>
        <TabPanel value="2" style={{ padding: 0, fontWeight: 700 }}>
          <VACondoLenderSetup />
        </TabPanel>
        <TabPanel value="3" style={{ padding: 0, fontWeight: 700 }}>
          <CONVCondoLenderSetup />
        </TabPanel>
      </TabContext>
      <AlertMessage alert={alertState} />
    </div>
  );
};

export default LenderCondoListSetup;
