import React, { useEffect, useState } from "react";
import { createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material";
import { Route } from "react-router";
import "./custom.css";
import Layout from "./components/Layout";
import Login from "./components/auth/Login";
import VerifyCode from "./components/auth/VerifyCode";
import AuthRoute from "./components/auth/AuthRoute";
import AnonymousRoute from "./components/auth/AnonymousRoute";
import ForgotPassword from "./components/auth/ForgotPassword";
import ChangeFirstPassword from "./components/auth/ChangeFirstPassword";
import SignUp from "./components/auth/SignUp";
import Help from "./components/Help";
import Company from "./components/lender/company-setup/Company";
import UICustomization from "./components/lender/ui-customization-setup/UICustomization";
import { postApiData } from "./components/core/FetchApiData";
import { ApiPaths, AppRoutes } from "./components/core/PathConstants";
import { CurrentUserContext } from "./components/core/hooks/CurrentUserContext";
import MLODetails from "./components/mlo/mlo-profile/MLODetails";
import Profile from "./components/user/Profile";
import ChangePassword from "./components/auth/ChangePassword";
import Loans from "./components/mlo/loans-setup/Loans";
import BuyerSavedProperties from "./components/lender/buyers/BuyerSavedProperties";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1D285B",
    },
    secondary: {
      main: "#F1592A",
    },
  },
});

export default function App() {
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const data = {
      email: localStorage.getItem("user"),
      refreshToken: localStorage.getItem("token"),
    };
    postApiData(ApiPaths.Refresh, "PUT", data, onChangeUser, onError, null);
  }, []);

  const onChangeUser = (u) => {
    setUser(u);
    if (u?.success) {
      setIsLoading(false);
      localStorage.setItem("user", u.email);
      localStorage.setItem("token", u.refreshToken);
    } else {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    }
  };

  const onError = () => {
    setIsLoading(false);
  };

  const onUpdateUserData = () => {
    const data = {
      email: localStorage.getItem("user"),
      refreshToken: localStorage.getItem("token"),
    };
    postApiData(ApiPaths.Refresh, "PUT", data, onChangeUser, onError, null);
  };

  const render = () => {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <CurrentUserContext.Provider value={{ user, onChangeUser }}>
          <Layout>
            {user?.mustChangePassword ? (
              <AuthRoute exact path={AppRoutes.Home}>
                <ChangeFirstPassword />
              </AuthRoute>
            ) : (
              <AuthRoute exact path={AppRoutes.Home}>
                <Loans />
              </AuthRoute>
            )}

            <AnonymousRoute exact path={AppRoutes.Login}>
              <Login />
            </AnonymousRoute>

            <AnonymousRoute exact path={AppRoutes.ForgotPasswprd}>
              <ForgotPassword />
            </AnonymousRoute>

            <AnonymousRoute exact path={AppRoutes.ChangeResetPassword}>
              <ChangePassword />
            </AnonymousRoute>

            <AnonymousRoute exact path={AppRoutes.VerifyOTP}>
              <VerifyCode isForChangePassword={false} />
            </AnonymousRoute>

            <AnonymousRoute exact path={AppRoutes.VerifyOTPToResetPwd}>
              <VerifyCode isForChangePassword={true} />
            </AnonymousRoute>

            <AnonymousRoute exact path={AppRoutes.SignUp}>
              <SignUp />
            </AnonymousRoute>

            <Route exact path={AppRoutes.Help}>
              <Help />
            </Route>

            <AuthRoute exact path={AppRoutes.ChangeFirstPassword}>
              <ChangeFirstPassword />
            </AuthRoute>

            <AuthRoute exact user={user} path={AppRoutes.CompanyConfig}>
              <Company />
            </AuthRoute>

            <AuthRoute exact user={user} path={AppRoutes.UICustomization}>
              <UICustomization />
            </AuthRoute>

            <AuthRoute exact user={user} path={AppRoutes.UserProfile}>
              <Profile onUpdateUserData={onUpdateUserData} />
            </AuthRoute>

            <AuthRoute exact user={user} path={AppRoutes.MLODetails}>
              <MLODetails onUpdateUserData={onUpdateUserData} />
            </AuthRoute>

            <AuthRoute exact user={user} path={AppRoutes.UserChangePassword}>
              <ChangePassword />
            </AuthRoute>

            <AuthRoute exact user={user} path={AppRoutes.MLOLoansList}>
              <Loans />
            </AuthRoute>

            <AuthRoute exact user={user} path={AppRoutes.MLOBuyeProperties}>
              <BuyerSavedProperties />
            </AuthRoute>

            {/* <AuthRoute exact user={user} path={AppRoutes.MLOInvite}>
              <MLOInvite user={user} />
            </AuthRoute>   

            <AuthRoute exact user={user} path={AppRoutes.MLOCommunications}>
              <MLOCommunications user={user} />
            </AuthRoute>

            <AuthRoute exact user={user} path={AppRoutes.MLONotifications}>
              <MLONotifications user={user} />
            </AuthRoute>

            <AuthRoute exact user={user} path={AppRoutes.MLODetails}>
              <MLODetails user={user} onUpdateUserData={onUpdateUserData} />
            </AuthRoute>

            <AuthRoute exact user={user} path={AppRoutes.MLOAppImages}>
              <MLOAppImages user={user} />
            </AuthRoute>

            <AuthRoute exact user={user} path={AppRoutes.RealtorAppImages}>
              <RealtorAppImages user={user} />
            </AuthRoute>

            <AuthRoute exact user={user} path={AppRoutes.RealtorNotifications}>
              <RealtorNotifications user={user} />
            </AuthRoute>

            <AuthRoute exact user={user} path={AppRoutes.RealtorInvite}>
              <RealtorInvite user={user} />
            </AuthRoute>

            <AuthRoute exact user={user} path={AppRoutes.RealtorDetails}>
              <RealtorDetails user={user} />
            </AuthRoute>

            <AuthRoute exact user={user} path={AppRoutes.BuyerProperties}>
              <Homes user={user} />
            </AuthRoute>

       

   

            <AuthRoute exact user={user} path={AppRoutes.UsersSetup}>
              <UsersSetup user={user} />
            </AuthRoute>

   

   
         */}
          </Layout>
        </CurrentUserContext.Provider>
      </ThemeProvider>
    );
  };

  if (isLoading)
    return (
      <h4>
        <em></em>
      </h4>
    );
  else return render();
}
