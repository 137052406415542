import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { replacePatterns } from "../../../lib/utils";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function DisclaimerButton({ property }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const patternMatchDisclaimer = property?.disclaimer
    ? replacePatterns(property.disclaimer)
    : "";

  return (
    <>
      <IconButton color="primary" aria-label="Disclaimer" onClick={handleOpen}>
        <InfoOutlinedIcon />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="subtitle1" component="h3">
            MLS Data provided under licensing by the{" "}
            <a href="https://www.homeservices.com/licensing">
              National Brokerage Network
            </a>
          </Typography>
          <Typography
            id="modal-modal-description"
            style={{ marginTop: "1.5rem" }}
          >
            {patternMatchDisclaimer}
          </Typography>
        </Box>
      </Modal>
    </>
  );
}
