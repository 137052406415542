import { React, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Tab,
  Paper,
  Tabs,
  Select,
  MenuItem,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import makeStyles from "@mui/styles/makeStyles";
import VACondosSetup from "./VACondosSetup";
import FHACondosSetup from "./FHACondosSetup";
import LenderCondoListSetup from "./LenderCondoListSetup";

const useStyles = makeStyles((theme) => ({
  current: {
    fontWeight: 700,
    fontSize: "14px",
    color: theme.palette.grey[700],
  },
  checkboxText: {
    margin: "0px",
    fontFamily: "Roboto, Helvetica, Arial, sans- serif",
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
  },
  paper: {
    padding: "0.5rem",
    color: theme.palette.text.secondary,
  },
  tab: {
    fontSize: "20px",
  },
}));

const CondosSetup = ({
  handleChange,
  values: {
    overridePrepaidIntDays,
    prepaidIntDays,
    allowBuydown,
    buydownReductionRate,
    buydownDiscPoints,
    hasBuydownLimit,
    buydownLimitPerc,
  },
}) => {
  const classes = useStyles();
  const [value, setValue] = useState("1");

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Grid container spacing={3} style={{ margin: "5px 0 5px" }}>
        <Grid item xs={12} style={{ paddingRight: "24px" }}>
          <Typography variant="h5">
            <strong>CONDO Approval Parameters</strong>
          </Typography>
          <Typography style={{ margin: "5px 10px 0 0px" }} variant="subtitle1">
            We search the FHA or VA condo to verify if a listed property is on
            the list. We can also check an uploaded list of condos that you know
            you can lend in. Bellow are the reported statuses from both the FHA
            and VA lists and the corresponding messages we show home buyers if
            the listing matches either list. You can designate the messages
            shown to the buyer for each status.
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        sx={{ margin: "0 0 5px", padding: "1rem 2rem 1rem 0.5rem" }}
      >
        <Grid item xs={12} style={{ padding: 0 }}>
          <TabContext value={value}>
            <Paper className={classes.paper} variant="outlined">
              <TabList
                onChange={handleTabChange}
                aria-label="lab API tabs example"
              >
                <Tab label="FHA CONDOS" value="1" className={classes.tab} />
                <Tab label="VA CONDOS" value="2" className={classes.tab} />
                <Tab
                  label="LENDER CONDO LIST"
                  value="3"
                  className={classes.tab}
                />
              </TabList>
            </Paper>
            <TabPanel value="1" style={{ padding: 0 }}>
              <FHACondosSetup />
            </TabPanel>
            <TabPanel value="2" style={{ padding: 0 }}>
              <VACondosSetup />
            </TabPanel>
            <TabPanel value="3" style={{ padding: 0 }}>
              <LenderCondoListSetup />
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
    </div>
  );
};

export default CondosSetup;
