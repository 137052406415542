import React, { useContext } from "react";
import { Fragment } from "react";
import { Redirect, Route } from "react-router";
import { AppRoutes } from "../core/PathConstants";
import { CurrentUserContext } from "../core/hooks/CurrentUserContext";

export default function AnonymousRoute({ children, ...rest }) {
  const { user } = useContext(CurrentUserContext);
  return (
    <Fragment>
      {!user?.success || !user.hasConfirmedOTP ? (
        <Route {...rest}>{children}</Route>
      ) : (
        <Route {...rest}>
          <Redirect to={AppRoutes.Home} />
        </Route>
      )}
    </Fragment>
  );
}
