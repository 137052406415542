import React from "react";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { useState, useEffect } from "react";

const LendingStates = ({ statesList, handleCheckBox, selected }) => {
  const [checkedStates, setCheckedStates] = useState(selected);
  const [checkedAll, setcheckedAll] = useState(false);

  useEffect(() => {
    if (statesList.length > 0) {
      setcheckedAll(selected.length === statesList.length);
    }
  }, [checkedStates]);

  const checkHandler = (e) => {
    const value = e.target.value;
    if (value === "-1") {
      if (e.target.checked) {
        setcheckedAll(true);
        handleCheckBox("-1");
        const selectedStates = statesList.map((s) => s.stateCode);
        setCheckedStates(selectedStates);
      } else {
        setcheckedAll(false);
        handleCheckBox("-2");
        setCheckedStates([]);
      }
    } else {
      handleCheckBox(value);
      if (e.target.checked) {
        setCheckedStates([...checkedStates.concat(value)]);
      } else {
        const afterDelete = checkedStates.filter((s) => s != value);
        setCheckedStates(afterDelete);
      }
    }
  };

  return (
    <div>
      <h3 style={{ margin: "0 10px 10px 10px" }} variant="h6">
        <strong>Lending States</strong>
      </h3>
      {statesList.length > 0 ? (
        <FormControlLabel
          style={{ margin: "0 20px 0 0px" }}
          control={
            <Checkbox
              value={-1}
              onClick={checkHandler}
              color="primary"
              checked={checkedAll}
            />
          }
          label="Select All - If adding new state, make sure to review tax rules for new additions."
        />
      ) : (
        <p></p>
      )}
      <Grid container spacing={1} style={{ margin: "10px 0 0 10px" }}>
        {statesList.length > 0 ? (
          statesList.map((state) => (
            <Grid
              xs={6}
              md={3}
              item
              key={state.stateCode}
              style={{ padding: "0px", margin: "0 0 0 0px" }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selected.includes(state.stateCode)}
                    value={state.stateCode}
                    color="primary"
                    onClick={checkHandler}
                  />
                }
                label={state.stateName}
              />
            </Grid>
          ))
        ) : (
          <p></p>
        )}
      </Grid>
    </div>
  );
};
export default LendingStates;
