import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { RichTextEditor } from "../../core/RichTextEditor";

const OfferLetterSetup = ({
  handleOnEditorChange,
  fetchPdf,
  values: { letterContent },
}) => {
  return (
    <div>
      <Grid
        container
        spacing={3}
        style={{ margin: "5px 0 5px", marginBottom: "5px" }}
      >
        <Grid item xs={12}>
          <Typography variant="h6">
            <strong>Offer/Qualification Letter</strong>
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="column">
        <Grid item xs={12} style={{ margin: "5px 10px 25px 10px" }}>
          <RichTextEditor
            onContentChange={handleOnEditorChange}
            content={letterContent}
            onPreview={fetchPdf}
            showMergeFields={true}
            showPreview={true}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default OfferLetterSetup;
