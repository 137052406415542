import React, { Fragment, useEffect, useState } from "react";
import { Avatar, Button, Menu, MenuItem } from "@mui/material";
import { Link, useLocation, useHistory } from "react-router-dom";
import { AppRoutes, ApiPaths } from "../core/PathConstants";
import { postApiData } from "../core/FetchApiData";

export default function LoginMenu({ user, onChangeUser }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    postApiData(ApiPaths.LogOut, "put", null, onChangeUser, null, user.token);
    onChangeUser({});
    setAnchorEl(null);
    history.push(AppRoutes.Home);
  };

  const authenticatedView = (profilePath) => {
    return (
      <Fragment>
        <Fragment>
          <Button
            aria-controls="account-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <Avatar src={user.picture} sx={{ bgcolor: "secondary.main" }}>
              {(
                (user?.firstName || "").substring(0, 1) +
                (user?.lastName || "").substring(0, 1)
              ).toUpperCase()}
            </Avatar>
          </Button>
          <Menu
            id="account-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            elevation={0}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            {user?.role &&
              user?.role !== "MLO" &&
              user?.role !== "MLO-Lender" && (
                <MenuItem
                  onClick={handleClose}
                  component={Link}
                  to={profilePath}
                >
                  My Profile
                </MenuItem>
              )}{" "}
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Fragment>
      </Fragment>
    );
  };

  if (user?.success) {
    const profilePath = `${AppRoutes.UserProfile}`;
    return authenticatedView(profilePath);
  } else {
    return <></>;
  }
}
